@import "../../assests/scss/variables.scss";


.career_Page {
  .banner-section {
    background-image: url("../../assests/img/career.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 420px;

    .content-Top {
      top: 35%;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 660px;

      .top-Head {
        font-size: 64px;
        color: $TXT_WHITE;
        padding-bottom: 24px;
      }

      .top-Inner {
        color: $TXT_WHITE;
        font-size: $FONT_16;
        font-family: $BARLOW_SEMIBOLD;
        margin: 0 !important;
        padding: 0;
        text-align: center;
        line-height: 25px;
      }
    }

    &.subscribe-section {
      margin-bottom: 60px;

      .content-Top {
        top: 20%;
        width: 845px;

        .top-Head {
          font-size: 44px;
          padding-bottom: 16px;
          line-height: 66px;
        }

        .top-Inner {
          width: 600px;
          line-height: 24px;
          padding-bottom: 46px;
        }

        .mail-section {
          .mailInput {
            .textField {
              width: 366px;

              .inputBox {
                input {
                  height: 48px;
                }
              }
            }
          }

          .subs_Btn {
            margin-left: 10px;

            button {
              height: 48px;
              background-color: $PRIMARY !important;
              color: $WHITE !important;
              width: 154px !important;
              font-family: $BARLOW_BOLD;
            }

          }
        }
      }
    }
  }

  .about-section {
    padding: 100px 120px;
    padding-bottom: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .left-section {
      width: 40%;

      .top {
        font-size: $FONT_32;
        font-family: $BARLOW_SEMIBOLD;
        color: $PRIMARY;
      }

      .inner {
        font-size: $FONT_16;
        font-family: $BARLOW_SEMIBOLD;
        color: $TXT_PLACEHOLDER;
        line-height: 24px;
      }
    }

    .know_btn {
      width: 154px;
      position: relative;
      float: left;

      .submitBtn {
        background: $SECONDARY !important;
        height: 48px;
        color: $TXT_WHITE !important;
        font-size: $FONT_16;
        font-family: $BARLOW_BOLD;
      }
    }
  }

  .why-section {
    padding: 25px 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .center-section {
      border: 1px solid #D6FAE8;
      display: flex;
      justify-content: space-around;
      padding: 24px;
      box-shadow: 2px 2px 2px #D6FBE9;
      border-radius: 16px;
      align-items: center;

      .left-section {
        width: 40%;

        .top {
          font-size: $FONT_32;
          font-family: $BARLOW_SEMIBOLD;
          color: $PRIMARY;
        }

        .inner {
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_PLACEHOLDER;
          line-height: 24px;
        }

        .skills {
          padding-bottom: 24px;

          ul {
            li {
              font-size: $FONT_14;
              font-family: $BARLOW_SEMIBOLD;
              color: $TXT_PLACEHOLDER;
              line-height: 21px;
              justify-content: center;
              align-items: center;

              img {
                padding-right: 5px;
                width: 16px;
              }
            }
          }
        }
      }
    }

    .know_btn {
      width: 154px;
      position: relative;
      float: left;

      .submitBtn {
        background: $SECONDARY !important;
        height: 48px;
        color: $TXT_WHITE !important;
        font-size: $FONT_16;
        font-family: $BARLOW_BOLD;
      }
    }
  }

  .career-position {
    padding: 100px 120px;

    .top-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 48px;

      .left {
        font-size: $FONT_32;
        padding-bottom: 0;
        font-family: $BARLOW_BOLD;

      }

      .right {
        background-color: #D6FBE9 !important;
        padding: 14px 34px !important;
        border-radius: 50px;
        font-size: $FONT_14;
        font-family: $BARLOW_SEMIBOLD;
        color: $SECONDARY;
      }
    }

    .accordion-item {
      border: 1px solid $PRIMARY;
      margin-bottom: 48px;
      border-radius: 10px;

      .accordion-header {
        height: 132px;

        button {
          height: 100%;
          background: transparent;
          border-bottom: 1px solid $PRIMARY !important;
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          padding-top: 0 !important;

          &:focus {
            box-shadow: none;
          }

          &.collapsed {
            border-bottom: none !important;
          }

          .exp {
            left: 18px;
            bottom: 31px;
            font-family: $BARLOW_MEDIUM;
            color: $PRIMARY !important;

          }
        }
      }

      .show {
        .accordion-body {
          box-shadow: 2px 2px 6px 1px rgba(249, 71, 71, 0.25);

        }
      }

      .accordion-body {
        .job-title {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          padding-bottom: 14px;
        }

        .descp {
          font-size: $FONT_16;
          font-family: $BARLOW_MEDIUM;
          padding-bottom: 24px;
          color: $TXT_BLACKISH;
          line-height: 24px;
        }

        ul {
          padding-left: 30px !important;

          .descp {
            list-style: disc;
          }
        }

        .paddTop {
          padding-top: 24px;
        }
      }
    }

    .accordion-button:not(.collapsed)::after {
      background-image: url('../../assests/img/up.svg');
      transform: none;
    }

    .accordion-button::after {
      background-image: url('../../assests/img/down.svg');
      transform: none;
    }

    .apply_btn {
      margin-bottom: 20px;

      .submitBtn {
        background: $SECONDARY !important;
        height: 48px;
        color: $TXT_WHITE !important;
        font-size: $FONT_16;
        font-family: $BARLOW_BOLD;
        width: 154px;
      }
    }
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .career_Page {
    .banner-section {
      // height: 330px;

      .content-Top {
        top: 30%;
      }
    }

    .about-section {
      padding: 80px;

      .right-section {
        img {
          width: 300px;
        }
      }
    }

    .why-section {
      padding: 25px 80px;

      img {
        width: 300px;
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .career_Page {
    .banner-section {
      // height: 240px;

      .content-Top {
        top: 30%;
      }

      &.subscribe-section {
        .content-Top {
          top: 30%;
          width: 700px;

          .top-Head {
            font-size: $FONT_40;
            line-height: 40px;
          }

          .top-Inner {
            padding-bottom: 30px;
            font-size: $FONT_14;

          }
        }
      }
    }

    .about-section {
      padding: 30px;

      .left-section {
        width: 50%;
      }

      .right-section {
        img {
          width: 300px;
        }
      }
    }

    .why-section {
      padding: 25px 30px;

      img {
        width: 300px;
      }

      .left-section {
        width: 50% !important;
      }
    }

    .career-position {
      padding: 30px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .career_Page {
    .banner-section {
      // height: 240px;

      .content-Top {
        top: 23%;
      }

      &.subscribe-section {
        .content-Top {
          top: 7%;
          width: 400px;

          .top-Head {
            font-size: $FONT_40;
            line-height: 40px;
          }

          .top-Inner {
            padding-bottom: 30px;
            font-size: $FONT_14;

          }
        }
      }
    }

    .about-section {
      padding: 30px;

      .left-section {
        width: 50%;
      }

      .right-section {
        img {
          width: 300px;
        }
      }
    }

    .why-section {
      padding: 25px 30px;

      img {
        width: 300px;
      }

      .left-section {
        width: 50% !important;
      }
    }

    .career-position {
      padding: 30px;
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .career_Page {
    .banner-section {
      height: 240px;
      width: 100%;

      .content-Top {
        top: 22%;
        width: 350px;

        .top-Head {
          font-size: 37px;
          padding-bottom: 18px;
        }
      }

      &.subscribe-section {
        .content-Top {
          top: 7%;
          width: 400px;

          .top-Head {
            font-size: $FONT_40;
            line-height: 40px;
          }

          .top-Inner {
            padding-bottom: 30px;
            font-size: $FONT_14;
            width: 400px;

          }
        }
      }
    }

    .about-section {
      padding: 30px;

      .left-section {
        width: 50%;
      }

      .right-section {
        img {
          width: 300px;
        }
      }
    }

    .why-section {
      padding: 25px 30px;

      img {
        width: 300px;
      }

      .left-section {
        width: 50% !important;
      }
    }

    .career-position {
      padding: 30px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .career_Page {
    .banner-section {
      height: 240px;

      .content-Top {
        top: 22%;
        width: 350px;

        .top-Head {
          font-size: 37px;
          padding-bottom: 18px;
        }
      }

      &.subscribe-section {
        .content-Top {
          top: 7%;
          width: 370px;

          .top-Head {
            font-size: $FONT_24;
            line-height: 40px;
          }

          .top-Inner {
            padding-bottom: 10px;
            font-size: $FONT_14;
            width: 400px;

          }
          .mail-section {
            .mailInput{
              .textField {
                width: 250px;

              }
            }
            .subs_Btn {
              button{
                width: 100px !important;
              }
            }
          }
        }
      }
    }

    .about-section {
      padding: 20px;
      flex-direction: column;

      .left-section {
        width: 100%;
        padding-bottom: 20px;
      }

      .right-section {
        img {
          width: 300px;
        }
      }
    }

    .why-section {
      padding: 20px;

      .center-section {
        flex-direction: column-reverse;
      }

      img {
        width: 300px;
      }

      .left-section {
        width: 100% !important;
      }
    }

    .career-position {
      padding: 20px;

      .top-header {
        padding-bottom: 28px;

        .left {
          font-size: $FONT_24;
          margin: 0 !important;
        }
      }
    }
  }
}