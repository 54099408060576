// Background Color

$SECONDARY: #141414;
$PRIMARY:#FF001D;
$WHITE:#FFFFFF;
$OFFWHITE:#FAFAFA;
$BLUE:#1976D2;
$OFFLIGHT:#5696FA;
$RED:#D32F2F;
$LIGHTPINK:#FFF0F0;
$LIGHTGREEN:#E5FFF2;
$LIGHTORANGE:#FFF7E8;
$LIGHTBLUE:#E5E7FF;
$LIGHTBG:#F7F7FC;
$LIGHTBLUEBG:#FCFCFC;
$OFFLIGHTGREY:#848586;
$TABLE_BG:#EFF0F6;
$LIGHTNEON:#E4FDFF;
$LIGHTYELLOW:#FFFEE4;
$LIGHTBGBLUE:#E5ECFF;
$LIGHTPINK:#FFE4F8;
$SILVER_BG: #D2D2D2;
$GOLD_BG: #DEB00C;
$PLATINUM_BG: #E5E4E2;
$LIGHTWHITE: #FBFBFB;
$LIGHTGREY:#939393;
$LIGHTBLACK:#14191F;
$LIGHTBROWN:#9A8383;



// Color

$TXT_WHITE: #ffffff;
$TXT_BLACK: #000000;
$TXT_GREY:#6E7191;
$TXT_LIGHT:#4E4B66;
$TXT_SECONDARY: #141414;
$TXT_PRIMARY:#FF001D;
$TXT_GREEN:#04D454;
$TXT_YELLOW:#FFA600;
$TXT_BLUE:#0B1EFF;
$TXT_PLACEHOLDER:#A0A3BD;
$TXT_NEON:#12EDFF;
$TXT_BLUELIGHT:#3F72FF;
$TXT_DARKYELLOW:#EEBA00;
$TXT_PINk:#FF3ACC;
$TXT_BLACKISH:#14142B;

// border

$BORDER:#D9DBE9;
$LIGHTBORDER:#D0D0D0;
$DARKBORDER: #A0A3BD;
$DIMBORDER: #EFF0F7;

// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_36: 36px;
$FONT_40: 40px;
$FONT_48: 48px;
$FONT_70: 70px;
$FONT_60: 60px;
$FONT_65: 65px;
$FONT_120: 120px;


// Font Family

$BARLOW_REGULAR: "barlowregular";
$BARLOW_BOLD: "barlowbold";
$BARLOW_LIGHT: "barlowlight";
$BARLOW_MEDIUM: "barlowmedium";
$BARLOW_SEMIBOLD: "barlowsemibold";
$BARLOW_BLACK: "barlowblack";
$POPPINS_LIGHT:"poppinslight";
$POPPINS_REGULAR:"poppinsregular";
$POPPINS_MEDIUM:"poppinsmedium";
$POPPINS_SEMIBOLD:"poppinssemibold";
$POPPINS_BOLD:"poppinsbold";


