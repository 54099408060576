@import "../../assests/scss/variables.scss";

.portfolio_Page {
  .content_Section {
    display: flex;
    align-self: center;
    width: 100%;
    flex-direction: row;
    padding-bottom: 85px;
    padding: 80px 120px;
    .titleText {
      font-size: $FONT_48;
      font-family: $BARLOW_MEDIUM;
      padding: 0;
      padding-right: 120px;
      align-self: center;
      &:last-child {
        width: 570px;
        font-size: $FONT_16;
        font-family: $BARLOW_SEMIBOLD;
        color: $SECONDARY;
        position: relative;
        padding-left: 16px;
        padding-right: 0;
        &:before {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          background: $SECONDARY;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .image_Section {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-fluid{
      height: 680px !important;
    }
  }
  .icon_Section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0px 80px 0px;
    .prev {
      margin-right: 32px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 120px;
      .titleText {
        font-size: $FONT_32;
        font-family: $BARLOW_MEDIUM;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_SEMIBOLD;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 120px;
      .titleText {
        font-size: $FONT_24;
        font-family: $BARLOW_REGULAR;
        padding-right: 98px;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 120px;
      .titleText {
        font-size: $FONT_24;
        font-family: $BARLOW_REGULAR;
        padding-right: 98px;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 16px;
      .titleText {
        font-size: $FONT_24;
        font-family: $BARLOW_REGULAR;
        padding-right: 80px;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
        }
      }
    }
    .image_Section {
      img.social_Image {
        height: 358px;
        width: 448px;
      }
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 16px;
      .titleText {
        font-size: $FONT_24;
        font-family: $BARLOW_REGULAR;
        padding-right: 56px;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
        }
      }
    }
    .image_Section {
      img.social_Image {
        height: 320px;
        width: 400px;
      }
    }
    .icon_Section {
      .prev {
        margin-right: 16px;
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .portfolio_Page {
    .content_Section {
      padding: 80px 8px;
      .titleText {
        font-size: $FONT_24;
        font-family: $BARLOW_REGULAR;
        padding-right: 32px;
        &:last-child {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
        }
      }
    }
    .image_Section {
      img.social_Image {
        height: 280px;
        width: 360px;
      }
    }
    .icon_Section {
      .prev {
        margin-right: 16px;
      }
    }
  }
}
