@import "../../assests/scss/variables.scss";

.home_page {
  background-image: url("../../assests/img/homebg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 964px;
  position: relative;

  .banner_Text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;
    margin-bottom: 110px;

    .titleText {
      font-size: $FONT_24;
      color: $TXT_WHITE !important;
      line-height: 36px;
      padding-bottom: 4px;
    }

    .bold {
      font-size: $FONT_120;
      font-family: $BARLOW_BLACK;
      line-height: 180px;
    }

    .small {
      font-size: $FONT_20;
      line-height: 30px;
      padding-bottom: 52px;
    }

    button {
      width: 180px;
      background: $PRIMARY !important;
      color: $TXT_WHITE !important;
    }
  }

  .icon-downs {
    color: $TXT_WHITE;
    position: absolute;
    bottom: 40px;
    font-size: $FONT_24;
  }

  img {
    &.size {
      width: 72px;
    }
  }

  .skill_Sectoion {
    background: white;
    padding: 110px 25px;
    padding-top: 0;
    display: initial;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        img {
          &.size {
            width: 72px;
          }
        }
      }
    }
  }

  .about_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      .card_Items {
        display: flex;

        .card {
          background: $LIGHTPINK;
          padding: 32px;
          text-align: left;
          border-radius: 10px;
          border: none;
          width: 49%;
          margin-right: 24px;
          margin-bottom: 25px;

          &.green {
            background: $LIGHTGREEN;
            margin-right: 0;
          }

          &.blue {
            background: $LIGHTBLUE;
            margin-right: 0;
          }

          &.orange {
            background: $LIGHTORANGE;
          }

          .content {
            i {
              font-size: $FONT_60;
              color: $TXT_PRIMARY;

              &.green {
                color: $TXT_GREEN;
              }

              &.blue {
                color: $TXT_BLUE;
              }

              &.yellow {
                color: $TXT_YELLOW;
              }
            }

            .titleText {
              padding-top: 15px;
              padding-bottom: 8px;
              font-size: $FONT_24;
              font-family: $BARLOW_SEMIBOLD;
              line-height: 36px;

              &.green {
                color: $TXT_GREEN;
              }

              &.blue {
                color: $TXT_BLUE;
              }

              &.yellow {
                color: $TXT_YELLOW;
              }

              &:last-child {
                font-family: $BARLOW_REGULAR;
                font-size: $FONT_16;
                padding: 0;
                color: $SECONDARY;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }

  .offerings_Section {
    display: flex;
    flex-direction: column;
    background: $SECONDARY;
    padding-bottom: 0;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 80px;
      background: $SECONDARY;
      padding: 80px 120px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;
        background: $SECONDARY;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_WHITE;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $WHITE;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      display: flex;
      margin-bottom: -120px;
      padding: 0 120px;

      .card_Items {
        width: 33.33%;

        &.marginT140 {
          margin-top: 140px;
        }

        &.marginT80 {
          margin-top: 80px;
        }

        &.marginT100 {
          margin-top: 100px;

          .card {
            margin-right: 0;
          }
        }

        &.marginT60 {
          margin-top: 60px;
        }

        .card {
          background: #1f1f1f;
          padding: 32px;
          text-align: left;
          border-radius: 10px;
          border: none;
          margin-right: 24px;

          &.marginB {
            margin-bottom: 25px;
          }

          .content {
            .titleText {
              padding-top: 15px;
              padding-bottom: 8px;
              font-size: $FONT_24;
              font-family: $BARLOW_SEMIBOLD;
              line-height: 36px;
              color: $TXT_WHITE;

              &:last-child {
                font-family: $BARLOW_REGULAR;
                font-size: $FONT_16;
                padding: 0;
                color: $TXT_WHITE;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }

  .work_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    padding-top: 220px;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;
      justify-content: space-between;

      .left_Content {
        display: flex;

        .titleText {
          font-size: $FONT_48;
          font-family: $BARLOW_MEDIUM;
          padding: 0;
          padding-right: 120px;
          align-self: center;

          &:nth-child(2) {
            width: 570px;
            font-size: $FONT_16;
            font-family: $BARLOW_SEMIBOLD;
            color: $SECONDARY;
            position: relative;
            padding-left: 16px;
            padding-right: 0;

            &:before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              background: $SECONDARY;
              left: 0;
              top: 0;
            }
          }
        }
      }

      .more_Link {
        display: flex;
        text-decoration: none;
        align-items: center;

        .titleText {
          color: $TXT_BLACK;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          padding: 0;
        }

        i {
          position: relative;
          color: $TXT_PRIMARY;
          bottom: unset;
          font-size: $FONT_32;
        }
      }
    }

    .card_Section {
      .card_Items {
        display: flex;

        .card {
          border: none;
          width: 49%;
          margin-right: 24px;
          margin-bottom: 25px;

          &.green {
            background: $LIGHTGREEN;
          }

          &.blue {
            background: $LIGHTBLUE;
          }

          &.orange {
            background: $LIGHTORANGE;
          }

          .content {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .why_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      display: flex;

      .titleText {
        &.heading {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_BLACK;
        }
      }

      .titleText {
        color: $TXT_GREY;
        font-size: $FONT_14;
        line-height: 21px;
      }

      .left_Section {
        width: 40%;
        padding-right: 44px;

        .nav-pills {
          --bs-nav-pills-border-radius: 0.375rem;
          --bs-nav-pills-link-active-color: #fff;
          --bs-nav-pills-link-active-bg: red;
        }

        .titleText {
          padding-bottom: 11px;
        }

        .tab_Section {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            background: transparent;
            border-color: transparent;
            color: $TXT_SECONDARY;
            margin-bottom: 20px;
            border-radius: 10px;
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 380px;

            &:focus {
              box-shadow: none;
            }

            &.active {
              font-family: $BARLOW_SEMIBOLD;
              background: $PRIMARY;
              color: $TXT_WHITE;
              height: 49px;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                width: 14px;
                height: 2px;
                top: 22px;
                left: 15px;
                background: $WHITE;
              }
            }
          }
        }
      }

      .right_Section {
        width: 60%;
        background: $LIGHTBG;
        padding: 40px 78px;
        border-radius: 10px;

        .titleText {
          padding-bottom: 0;

          &:first-child {
            padding-bottom: 25px;
            width: 478px;
            line-height: 35px;
          }
        }
      }
    }
  }

  .process_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    background: $SECONDARY;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 135px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;
          color: $TXT_WHITE;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $WHITE;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      display: flex;

      .titleText {
        &.heading {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_WHITE;
          line-height: 36px;
          padding-bottom: 16px !important;
        }
      }

      .titleText {
        color: $TXT_WHITE;
        font-size: $FONT_14;
        line-height: 21px;
      }

      .left_Section {
        width: 18.7%;
        padding-left: 25px;

        .tab_Section {
          display: flex;
          flex-direction: column;
          align-items: center;
          --bs-nav-link-color: #A0A3BD;
          --bs-nav-pills-border-radius: 0.375rem;
          --bs-nav-pills-link-active-color: #fff;
          --bs-nav-pills-link-active-bg: none;

          .nav-item {
            .nav-link {
              height: 40px;
            }

            .titleText {
              color: $TXT_WHITE;
              padding-bottom: 32px;
              font-size: $FONT_16;
              font-family: $BARLOW_REGULAR;
              padding-bottom: 32px;

              .tab_Section>.active {
                font-family: $BARLOW_SEMIBOLD;
                color: $TXT_WHITE;
                position: relative;

                &:before {
                  content: "";
                  position: absolute;
                  width: 15px;
                  height: 2px;
                  top: 8px;
                  left: -25px;
                  background: $PRIMARY;
                }
              }
            }
          }
        }
      }

      .right_Section {
        width: 81.3%;

        .titleText {
          padding-bottom: 0;

          &:first-child {
            padding-bottom: 25px;
            width: 478px;
            line-height: 35px;
          }
        }

        .inner_Card {
          width: 47%;
          float: left;
          background: #1e1e1e;
          padding: 40px;
          border-radius: 10px;
          margin-right: 12px;
          text-align: left;

          &:last-child {
            margin-left: 12px;
          }

          img {
            padding-bottom: 24px;
          }
        }
      }
    }
  }

  .casestudy_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    background: $SECONDARY;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 135px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;
          color: $TXT_WHITE;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $WHITE;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .slick-next,
    .slick-prev {
      height: 30px;
      width: 30px;
      right: 10;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 30px;
    }

    .card_Section {
      display: flex;
      padding-left: 8em;

      .titleText {
        &.heading {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_WHITE;
          line-height: 36px;
          padding-bottom: 16px !important;
        }
      }

      .titleText {
        color: $TXT_WHITE;
        font-size: $FONT_14;
        line-height: 21px;
      }

      .bottom_Section {
        display: flex;
        width: 15%;

        .tab_Section {
          display: flex;
          flex-direction: column;
          --bs-nav-link-color: #A0A3BD;
          --bs-nav-pills-border-radius: 0.375rem;
          --bs-nav-pills-link-active-color: #fff;
          --bs-nav-pills-link-active-bg: none;

          .titleText {
            color: $TXT_WHITE;
            padding-bottom: 32px;
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-bottom: 32px;

            &.active {
              font-family: $BARLOW_SEMIBOLD;
              color: $TXT_WHITE;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                width: 15px;
                height: 2px;
                top: 8px;
                left: -25px;
                background: $PRIMARY;
              }
            }
          }
        }
      }

      .content {
        .tab-content>.active {
          display: flex;


          .img_Content {
            .img_Section {
              margin-right: 40px;

              img {
                width: 40vw;
              }

            }
          }

          .right_Section {
            width: 50%;
            text-align: left;

            .inner_Card {
              border-radius: 10px;
              margin-right: 12px;
              text-align: left;
              padding-bottom: 8px;

              &:last-child {
                margin-left: 12px;
                padding-bottom: 40px;
              }

              button {
                width: 154px;
                height: 48px;
                background: $PRIMARY !important;
                color: $TXT_WHITE !important;
              }
            }
          }
        }
      }
    }

  }

  .blog_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;
      justify-content: space-between;

      .left_Content {
        display: flex;

        .titleText {
          font-size: $FONT_48;
          font-family: $BARLOW_MEDIUM;
          padding: 0;
          padding-right: 120px;
          align-self: center;

          &:nth-child(2) {
            width: 570px;
            font-size: $FONT_16;
            font-family: $BARLOW_SEMIBOLD;
            color: $SECONDARY;
            position: relative;
            padding-left: 16px;
            padding-right: 0;

            &:before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              background: $SECONDARY;
              left: 0;
              top: 0;
            }
          }
        }
      }

      .more_Link {
        display: flex;
        text-decoration: none;
        align-items: center;

        .titleText {
          color: $TXT_BLACK;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          padding: 0;
        }

        i {
          position: relative;
          color: $TXT_PRIMARY;
          bottom: unset;
          font-size: $FONT_32;
        }
      }
    }

    .card_Section {
      .card_Items {
        display: flex;

        .card {
          border: none;
          width: 33.33%;
          margin-right: 24px;
          margin-bottom: 25px;
          border: 1px solid $BORDER;
          border-radius: 20px;

          .img {
            img {
              width: 100%;
            }
          }

          .content {
            padding: 16px;
            position: relative;

            .titleText {
              &.pholder {
                color: $TXT_PLACEHOLDER;
                font-size: 16px;
                font-family: $BARLOW_MEDIUM;
                padding-bottom: 8px;
              }

              &.label {
                color: $SECONDARY;
                font-size: $FONT_16;
                font-family: $BARLOW_MEDIUM;
                color: $TXT_GREY !important;
                padding: 0;
              }

              &.heading {
                font-size: $FONT_24 !important;
                padding-bottom: 8px;
                color: $SECONDARY !important;
                line-height: 35px !important;
              }
            }

            .colorRed {
              font-size: $FONT_14;
              font-family: $BARLOW_SEMIBOLD;
              position: absolute;
              right: 20px;
              bottom: 10px;
            }
          }
        }
      }
    }
  }

  .testimonials_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    margin-bottom: 80px;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_SectionTest {
      padding: 0 2em;

      .slick-next,
      .slick-prev {
        height: 30px;
        width: 30px;
        right: 10;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 30px;
      }

      .testimonials_Sectoion {
        padding: 0 0.2em 0 1em;
        background: $LIGHTBLUEBG;

        .testimonals {
          width: 100%;

          .inner_Testimonals {
            position: relative;

            .titleText {
              font-size: $FONT_24;
              color: $TXT_LIGHT;
            }

            .paddB {
              padding-bottom: 24px;
            }

            .paddT {
              position: absolute;
              bottom: -24px;
              right: 24px;
            }
          }

          .from_Name {
            padding-bottom: 0;
            font-size: $FONT_16;
            font-family: $BARLOW_SEMIBOLD;
            color: $SECONDARY;
            line-height: 24px;
          }

          .post {
            font-size: $FONT_14;
            color: $TXT_GREY;
            padding-bottom: 57px;
          }
        }
      }
    }
  }

}

@media only screen and (min-device-width: 992px) and (max-device-width: 1290px) {
  .home_page {
    .about_Section {
      padding: 80px 15px;
    }

    .offerings_Section {
      .content_Section {
        padding: 80px 20px;
        padding-bottom: 15px;

        .titleText {
          font-size: $FONT_32;

          &.sub-title {
            font-size: $FONT_14;
          }
        }
      }

      .card_Section {
        padding: 0 15px;

        .card_Items {
          .card {
            margin-bottom: 16px;
            margin-right: 16px;
          }
        }
      }
    }

    .work_Section {
      padding: 80px 16px;
      padding-top: 220px;
    }

    .blog_Section {
      padding: 80px 16px;
    }

    .why_Section {
      padding: 80px 16px;

      .card_Section {
        .left_Section {
          .heading {
            line-height: 25px;
          }
        }
      }
    }

    .testimonials_Section {
      padding: 80px 16px;
    }

    .process_Section {
      padding: 80px 30px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .left_Section {
          width: 30%;
        }

        .right_Section {
          width: 70%;

          .inner_Card {
            width: 98%;
            margin-right: 0;

            &:first-child {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .casestudy_Section {

      .card_Section {
        .bottom_Section {}

        .content {
          .tab-content>.active {
            display: block;
            margin: 0 0 0 50px;

            .img_Content {
              .img_Section {
                width: auto;
              }
            }

            .right_Section {
              width: 80%;

              .inner_Card {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .home_page {
    background-size: auto;
    height: 812px;

    .banner_Text {
      .titleText {
        font-size: $FONT_16;
        line-height: 24px;
        text-align: center;
        padding-bottom: 16px;
      }

      .bold {
        font-size: $FONT_70;
        line-height: 60px;
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 20px;
            width: 24%;
            font-size: $FONT_24;
          }
        }
      }
    }

    .offerings_Section {
      .content_Section {
        padding: 28px 20px;
        padding-bottom: 15px;

        .titleText {
          font-size: $FONT_32;

          &.sub-title {
            font-size: $FONT_14;
          }
        }
      }

      .card_Section {
        padding: 0 15px;

        .card_Items {
          .card {
            margin-bottom: 16px;
            margin-right: 16px;
          }
        }
      }
    }

    .work_Section {
      padding: 80px 16px;
      padding-top: 220px;

      .content_Section {
        position: relative;

        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 409px;
            }
          }
        }

        .more_Link {
          position: absolute;
          right: 0;
          bottom: 50px;
        }
      }
    }

    .process_Section {
      padding: 80px 30px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .left_Section {
          width: 30%;
        }

        .right_Section {
          width: 70%;

          .inner_Card {
            width: 98%;
            margin-right: 0;

            &:first-child {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .blog_Section {
      padding: 80px 16px;

      .card_Section {
        .card_Items {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .card {
            width: 80%;
          }
        }
      }

      .content_Section {

        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 409px;
            }
          }
        }
      }
    }

    .why_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right: 60px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 493px;
          }
        }
      }

      .card_Section {
        .left_Section {
          .heading {
            line-height: 25px;
          }

          .tab_Section {
            button {
              width: 280px;

              &.active {
                &:before {
                  left: 8px;
                }
              }
            }
          }
        }

        .right_Section {
          padding: 40px 24px;

          .titleText {
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }

    .testimonials_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .testimonials_Sectoion {
          .testimonals {
            padding: 32px 78px;

            .inner_Testimonals {
              .titleText {
                font-size: $FONT_16;
              }
            }
          }
        }
      }
    }

    .casestudy_Section {
      padding: 40px 30px;

      .card_Section {
        padding-left: 70px;
        ;

        .bottom_Section {}

        .content {
          .tab-content>.active {
            display: block;
            margin: 0 0 0 50px;

            .img_Content {
              .img_Section {
                width: auto;
              }
            }

            .right_Section {
              width: 100%;

              .inner_Card {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 665px) and (max-device-width: 767px) {
  .home_page {
    background-size: auto;
    height: 812px;
    padding: 0 15px;

    .banner_Text {
      .titleText {
        font-size: $FONT_16;
        line-height: 24px;
        text-align: center;
        padding-bottom: 24px;
      }

      .bold {
        font-size: $FONT_40;
        line-height: 60px;
      }
    }

    .skill_Sectoion {
      padding: 110px 15px;

      .slick-list {
        .slick-track {
          .slick-slide {
            img {
              width: 54px;
              height: 54px;
            }
          }
        }
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 24%;
          }
        }
      }
    }

    .offerings_Section {
      .content_Section {
        padding: 32px 20px;
        padding-bottom: 15px;

        .titleText {
          font-size: $FONT_32;

          &.sub-title {
            font-size: $FONT_14;
          }
        }
      }

      .card_Section {
        padding: 0 15px;
        flex-direction: column;
        margin-bottom: 16px;

        .card_Items {
          width: 100%;

          &.marginT140 {
            margin-top: 0;
          }

          &.marginT100 {
            margin-top: 0;
          }

          &.marginT60 {
            margin-top: 15px;
          }

          .card {
            margin-bottom: 16px !important;
            margin-right: 0;

            &.marginB {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .work_Section {
      padding: 40px 16px;

      .content_Section {
        position: relative;

        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;
            padding-right: 81px;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 400px;
            }
          }
        }

        .more_Link {
          position: absolute;
          right: 0;
          bottom: 50px;
        }
      }
    }

    .process_Section {
      padding: 80px 30px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .left_Section {
          width: 40%;
        }

        .right_Section {
          width: 70%;

          .inner_Card {
            width: 98%;
            margin-right: 0;

            &:first-child {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .blog_Section {
      padding: 80px 16px;

      .content_Section {
        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 309px;
            }
          }
        }
      }
    }

    .why_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 60px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 100%;
          }
        }
      }

      .card_Section {
        flex-direction: column;

        .left_Section {
          width: 100%;
          padding: 0;

          .heading {
            line-height: 25px;
          }

          .tab_Section {
            button {
              width: 100%;
              text-align: left;
              padding-left: 32px;

              &.active {
                &:before {
                  left: 8px;
                }
              }
            }
          }
        }

        .right_Section {
          padding: 40px 24px;
          width: 100%;

          .titleText {
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }

    .testimonials_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .testimonials_Sectoion {
          .testimonals {
            padding: 32px 78px;
            width: 100% !important;

            .inner_Testimonals {
              .titleText {
                font-size: $FONT_16;
              }
            }
          }
        }
      }
    }

    .casestudy_Section {
      padding: 40px 30px;

      .card_Section {
        .bottom_Section {
          width: 16%;
        }

        .content {
          padding-left: 60px;

          .tab-content>.active {
            display: block;
            margin: 0 0 0 10px;

            .img_Content {
              .img_Section {
                img {
                  width: 50vw;
                }
              }
            }

            .right_Section {
              width: 100%;

              .inner_Card {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 561px) and (max-device-width: 664px) {
  .home_page {
    background-size: auto;
    height: 812px;
    padding: 0 15px;

    .banner_Text {
      .titleText {
        font-size: $FONT_16;
        line-height: 24px;
        text-align: center;
        padding-bottom: 24px;
      }

      .bold {
        font-size: $FONT_40;
        line-height: 60px;
      }
    }

    .skill_Sectoion {
      padding: 40px 15px;

      .slick-list {
        .slick-track {
          .slick-slide {
            img {
              width: 54px;
              height: 54px;
            }
          }
        }
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 30%;
          }
        }
      }
    }

    .offerings_Section {
      .content_Section {
        padding: 32px 20px;
        padding-bottom: 15px;

        .titleText {
          font-size: $FONT_32;

          &.sub-title {
            font-size: $FONT_14;
          }
        }
      }

      .card_Section {
        padding: 0 15px;
        flex-direction: column;
        margin-bottom: 16px;

        .card_Items {
          width: 100%;

          &.marginT140 {
            margin-top: 0;
          }

          &.marginT100 {
            margin-top: 0;
          }

          &.marginT60 {
            margin-top: 15px;
          }

          .card {
            margin-bottom: 16px !important;
            margin-right: 0;

            &.marginB {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .work_Section {
      padding: 40px 16px;

      .content_Section {
        position: relative;

        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;
            padding-right: 81px;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 309px;
            }
          }
        }

        .more_Link {
          position: absolute;
          right: 0;
          bottom: 50px;
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }

    .process_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .left_Section {
          width: 40%;
        }

        .right_Section {
          width: 60%;

          .inner_Card {
            width: 98%;
            margin-right: 0;

            &:first-child {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .blog_Section {
      padding: 40px 16px;

      .content_Section {
        .left_Content {
          .titleText {
            font-size: $FONT_32;
            font-family: $BARLOW_REGULAR;
            padding-right: 80px;

            &:last-child {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
              width: 309px;
            }
          }
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
            border-top-left-radius: 38px;
            border-top-right-radius: 38px;
          }
        }
      }
    }

    .contact_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right: 45px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 300px;
          }
        }
      }

      .card_Section {
        form {
          padding: 40px 16px;
        }
      }
    }

    .testimonials_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        .testimonials_Sectoion {
          .testimonals {
            padding: 32px 78px;
            width: 100% !important;

            .inner_Testimonals {
              .titleText {
                font-size: $FONT_16;
              }
            }
          }
        }
      }
    }

    .why_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 60px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 390px;
          }
        }
      }

      .card_Section {
        flex-direction: column;

        .left_Section {
          width: 100%;
          padding: 0;

          .heading {
            line-height: 25px;
          }

          .tab_Section {
            button {
              width: 100%;
              text-align: left;
              padding-left: 32px;

              &.active {
                &:before {
                  left: 8px;
                }
              }
            }
          }
        }

        .right_Section {
          padding: 40px 24px;
          width: 100%;

          .titleText {
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }

    .casestudy_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        padding-left: 0;

        .bottom_Section {
          width: 13%;

          .tab_Section {
            display: none;
          }
        }

        .content {
          padding-left: 20px;

          .tab-content>.active {
            display: block;
            margin: 0 0 0 40px;

            .img_Content {
              .img_Section {
                width: 0px;

                img {
                  width: 350px;
                }
              }
            }

            .right_Section {
              width: 90%;

              .inner_Card {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }



  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 560px) {
  .home_page {
    background-size: auto;
    height: 812px;

    .banner_Text {
      padding: 0 15px;

      .titleText {
        font-size: $FONT_16;
        line-height: 24px;
        text-align: center;
        padding-bottom: 24px;
      }

      .bold {
        font-size: $FONT_40;
        line-height: 60px;
      }
    }

    .skill_Sectoion {
      padding: 40px 16px;

      .slick-list {
        .slick-track {
          .slick-slide {
            img {
              width: 54px;
              height: 54px;
            }
          }
        }
      }
    }

    .about_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          &:last-child {
            font-size: $FONT_12;
          }

          &:first-child {
            padding-right: 10px;
            font-size: $FONT_16;
            width: 66%;
          }
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
            padding: 15px;
            margin-bottom: 16px;

            .content {
              i {
                font-size: $FONT_40;
              }

              .titleText {
                font-size: $FONT_16;
                padding-bottom: 8px;
                line-height: 24px;

                &:last-child {
                  font-size: $FONT_14;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }

    .offerings_Section {
      .content_Section {
        padding: 32px 20px;
        padding-bottom: 15px;

        .titleText {
          font-size: $FONT_16;
          padding-right: 40px;

          &.sub-title {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
          }
        }
      }

      .card_Section {
        padding: 0 15px;
        flex-direction: column;
        margin-bottom: 32px;

        .card_Items {
          width: 100%;

          &.marginT140 {
            margin-top: 0;
          }

          &.marginT100 {
            margin-top: 0;
          }

          &.marginT60 {
            margin-top: 15px;
          }

          .card {
            margin-bottom: 16px !important;
            margin-right: 0;

            &:last-child {
              margin-bottom: 0 !important;
            }

            &.marginB {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .work_Section {
      padding: 32px 16px;

      .content_Section {
        position: relative;

        .left_Content {
          .titleText {
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-right: 20px;

            &:last-child {
              font-size: $FONT_12;
              font-family: $BARLOW_REGULAR;
              width: 270px;
            }
          }
        }

        .more_Link {
          position: absolute;
          right: 0;
          bottom: 50px;
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }

    .process_Section {
      padding: 40px 30px;

      .content_Section {
        padding-bottom: 40px;

        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 40px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 471px;
          }
        }
      }

      .card_Section {
        justify-content: baseline;

        .left_Section {
          width: 45%;

          .tab_Section {
            .nav-item {
              .nav-link {
                height: auto;
              }
            }
          }
        }

        .right_Section {
          width: 60%;

          .inner_Card {
            width: 98%;
            margin-right: 0;

            &:first-child {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .blog_Section {
      padding: 40px 16px;

      .content_Section {
        position: relative;

        .left_Content {
          .titleText {
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-right: 20px;

            &:last-child {
              font-size: $FONT_12;
              font-family: $BARLOW_REGULAR;
              width: 270px;
            }
          }
        }

        .more_Link {
          position: absolute;
          right: 0;
          bottom: 50px;
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
            border-top-left-radius: 38px;
            border-top-right-radius: 38px;
          }
        }
      }
    }

    .contact_Section {
      padding: 40px 16px;

      .content_Section {
        padding-bottom: 20px;

        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 50px;

          &:last-child {
            font-size: $FONT_12;
            font-family: $BARLOW_REGULAR;
            width: 290px;
          }
        }
      }

      .card_Section {
        form {
          padding: 40px 16px;

          .input_Section {
            flex-direction: column;

            .input {
              width: 100%;
              padding-bottom: 23px;
            }

            .phone_Section {
              width: 100%;
              margin-bottom: 23px;
            }
          }
        }
      }
    }

    .testimonials_Section {
      padding: 40px 16px;
      margin-bottom: 0;

      .content_Section {
        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 20px;

          &:last-child {
            font-size: $FONT_12;
            font-family: $BARLOW_REGULAR;
            width: 290px;
          }
        }
      }

      .card_Section {
        .testimonials_Sectoion {
          .testimonals {
            padding: 32px 28px;
            width: 100% !important;

            .inner_Testimonals {
              .paddB {
                width: 16px;
              }

              .paddT {
                width: 16px;
                bottom: 0;
              }

              .titleText {
                font-size: $FONT_16;
              }
            }
          }
        }
      }
    }

    .why_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 0;
          width: 70%;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 270px;
          }
        }
      }

      .card_Section {
        flex-direction: column;

        .left_Section {
          width: 100%;
          padding: 0;

          .heading {
            line-height: 25px;
          }

          .tab_Section {
            button {
              width: 100%;
              text-align: left;

              font-size: 12px;

              &.active {
                padding-left: 32px;

                &:before {
                  left: 8px;
                }
              }
            }
          }
        }

        .right_Section {
          padding: 40px 24px;
          width: 100%;

          .titleText {
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }

    .casestudy_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 0;
          width: 70%;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 270px;
          }
        }
      }

      .card_Section {
        padding-left: 10px;

        .titleText {
          .heading {
            font-size: $FONT_20;
          }


        }

        .bottom_Section {
          width: 33%;

          .tab_Section {
            display: none;
          }
        }

        .content {
          padding-left: 25px;

          .tab-content>.active {
            display: block;
            margin: 0 0 0 0px;

            .img_Content {
              .img_Section {
                width: 0px;

                img {
                  width: 300px;
                }
              }
            }

            .right_Section {
              width: 100%;

              .inner_Card {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}