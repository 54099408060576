@import "../../assests/scss/variables.scss";

.footer-Container {

  .footer-Img {
    background-image: url("../../assests/img/bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;

    .footer-Items {
      display: flex;
      text-align: left;
      padding: 62px 172px;

      .listings-Main {
        list-style: none;
        padding: 0;
        width: 26.66%;
        margin: 0;
        padding-right: 14px !important;

        &:last-child {
          width: 20%;

          li {
            padding-bottom: 40px;
          }
        }

        li {
          color: $TXT_WHITE;
          font-size: $FONT_16;
          padding-bottom: 24px;
          font-family: $BARLOW_REGULAR;

          &.main-Title {
            font-size: $FONT_24;
            padding-bottom: 40px;
          }

          &.padd50 {
            padding-bottom: 56px;
          }

          &.padd40 {
            padding-bottom: 40px;
          }

          .paddR {
            padding-right: 11px;
          }

          i {
            font-size: $FONT_24;
          }

          .font18 {
            font-size: $FONT_18;
          }
        }
      }
    }
  }

  .contact-us-footer {
    right: 0;
    background: $PRIMARY;
    width: 110px;
    padding: 8px 0px;
    bottom: 18px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;

      img {
        width: 20px;
      }

      .titleText {
        color: white;
        padding: 0 !important;
        font-size: 16px;
        font-family: $BARLOW_SEMIBOLD;
        padding-left: 8px !important;
        padding-bottom: 2px !important;
      }
    }
  }

  .copy-Right {
    min-height: 48px;
    display: flex;
    align-items: center;
    width: 100%;

    .titleText {
      padding: 0;
      color: $TXT_BLACK;
    }

    .lists {
      width: 100%;
      display: flex;
      padding: 0 172px;

      .copy-Text {
        width: 50%;

        .titleText {
          padding: 0;
          font-size: $FONT_14 !important;
        }
      }

      .ul-Footer {
        display: flex;
        padding: 0;
        margin: 0;
        width: 100%;
        cursor: pointer;
        justify-content: flex-end;

        .titleText {
          padding-right: 24px;
          font-size: $FONT_14;

          &:last-child {
            padding-right: 0;
          }
        }

        li {
          list-style: none;
          padding-right: 32px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
  .footer-Container {
    .footer-Img {
      .footer-Items {
        padding: 62px 16px;
      }
    }

    .copy-Right {
      .lists {
        padding: 0 16px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer-Container {
    .footer-Img {
      background-size: auto;

      .footer-Items {
        padding: 62px 16px;

        .listings-Main {
          li {
            &.main-Title {
              font-size: $FONT_16;
              font-family: $BARLOW_SEMIBOLD;
            }
          }
        }
      }
      .contact-us-footer{
        bottom: 55px !important;
      }
    }

    .copy-Right {
      .lists {
        padding: 0 16px;

        .copy-Text {
          .titleText {
            font-size: $FONT_10 !important;
          }
        }

        .ul-Footer {
          .titleText {
            font-size: $FONT_10 !important;
          }
        }
      }
    }
  }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .footer-Container {
    .footer-Img {
      background-size: 100%;
      background-image: url("../../assests/img/footer-mobile.svg");

      .footer-Items {
        padding: 49px 34px;
        flex-direction: column;

        .listings-Main {
          width: 100%;

          li {
            &.main-Title {
              font-size: $FONT_16;
              font-family: $BARLOW_SEMIBOLD;
            }
          }

          &:last-child {
            width: 100%;
          }
        }
      }
    }

    .copy-Right {
      flex-direction: column;

      .lists {
        padding: 14px 24px;
        flex-direction: column;

        .copy-Text {
          width: 100%;
          padding-bottom: 24px;

          .titleText {
            font-size: $FONT_14 !important;
          }
        }

        .ul-Footer {
          justify-content: flex-start;
          flex-wrap: wrap;

          .titleText {
            font-size: $FONT_14 !important;

            &:first-child {
              padding-bottom: 14px;
            }
          }
        }
      }
    }
    .contact-us-footer{
      bottom: 25px;
    }
  }
}