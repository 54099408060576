@import "../../assests/scss/variables.scss";

.menuListing {
  padding: 0 !important;

  .logo-Img {
    cursor: pointer;
  }

  .container {
    max-width: 100%;
    padding: 0 120px;
    padding-right: 15px;
    background: $WHITE;
    height: 60px;

    .profile-dropdown {
      &.dropdown {
        .dropdown-menu {
          a {
            &:nth-child(3) {
              border-top: 1px solid grey;
              padding-top: 10px;
            }
          }
        }
      }
    }

    .navbar-collapse {
      .nav-item {
        .dropdown-menu {
          padding: 0;
          right: -340px;
          left: unset;
          top: 40px;
          border-radius: 14px;
        }

        a {
          font-family: $BARLOW_SEMIBOLD;
          font-size: $FONT_16;
          color: $TXT_SECONDARY;
          padding: 0;
          padding-right: 24px;

          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          .dropdown-Options {
            display: flex;
            padding: 40px;

            .item-List {
              padding-right: 64px;

              &.paddR {
                padding-right: 0 !important;
              }

              a {
                padding: 0;

                &:hover {
                  background: transparent;
                }
              }

              .mainTitle {
                padding-bottom: 40px;

                i {
                  font-size: 20px;
                  padding-right: 11px;
                }
              }

              .dropdown-item {
                &.borderB {
                  position: relative;
                  margin-bottom: 16px;

                  &::before {
                    content: "";
                    position: absolute;
                    width: 66%;
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    background: $PRIMARY;
                  }
                }
              }
            }
          }
        }
      }

      .navbar-nav {
        align-items: center;

        .dropdown {
          background: #eff0f6;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .profileCss {
            font-size: $FONT_16;
            color: $TXT_GREY;
          }

          button {
            &:after {
              display: none;
            }
          }
        }

        .nav-link {
          padding: 0;
          font-family: $BARLOW_SEMIBOLD;
          font-size: $FONT_16;
          color: $TXT_SECONDARY;
          padding-right: 24px;
        }
      }

      .btm_Img {
        position: relative;

        .image {
          img {
            width: 100%;
          }
        }

        .text-Btn {
          position: absolute;
          left: 0;
          top: 22px;
          display: flex;
          width: 100%;
          justify-content: space-around;

          .title-Btn {
            justify-content: space-between;
            flex-direction: column;

            .titleText {
              color: $TXT_WHITE;
              font-family: $BARLOW_MEDIUM;
              font-size: $FONT_16;
              padding-bottom: 0;
              line-height: 24px;
            }
          }

          button {
            width: 108px;
          }
        }
      }
    }

    .mobile_Resp {
      display: none;

      .accordion {
        border: none !important;

        .accordion-item {
          border: none !important;

          .accordion-header {
            border: none !important;

            .accordion-button {
              background: transparent;
              color: $TXT_SECONDARY;
              font-size: $FONT_16;
              font-family: $BARLOW_REGULAR;
              border: none !important;

              &:focus {
                box-shadow: none;
              }

              &:after {
                background-image: none;
                content: "\e913";
                position: absolute;
                font-family: "icomoon" !important;
                right: 20px;
                color: $TXT_PRIMARY;
              }

              i {
                padding-right: 10px;
                font-size: 20px;
              }

              &.collapsed {
                &:after {
                  background-image: none;
                  content: "\e914";
                  position: absolute;
                  font-family: "icomoon" !important;
                  right: 20px;
                }
              }
            }
          }

          .accordion-collapse {
            border: none !important;

            &:hover {
              background: transparent;
            }
          }

          .accordion-body {
            border: none !important;

            .dropdown-Items {
              border: none !important;
              list-style: none;
              padding-left: 0;

              li {
                font-family: $BARLOW_REGULAR;
                border: none !important;

                .borderB {
                  border: none !important;
                  position: relative;
                  padding: 0;
                  margin-bottom: 16px;

                  &::before {
                    content: "";
                    position: absolute;
                    width: 20%;
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    background: $PRIMARY;
                  }
                }

                a {
                  font-size: $FONT_14;
                  font-family: $BARLOW_REGULAR;
                  line-height: 21px;
                  padding-bottom: 8px;
                  border: none !important;

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .dropdown-profile {
      background: #eff0f6;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }
}
.show-modal {
  display: none;
}
.hoverToSee:hover .show-modal {
  display: block;
}

.modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    max-width: 100%;

    .modal-content {
      width: 1000px;
      display: flex;
      align-self: center;
      justify-content: center;

      .modalBody {
        .portfolio_Page {
          .content_Section {
            padding: 20px 8px;

            .titleText {
              font-size: $FONT_24;
              font-family: $BARLOW_REGULAR;
              padding-right: 32px;

              &:last-child {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
      }
    }
  }

  .carousel-control-prev {
    left: -50px !important;
  }

  .carousel-control-next {
    right: -50px !important;
  }
}

.carousel-control-prev-icon {
  background: $PRIMARY;
  padding: 1.2em;
  border-radius: 50%;
}

.carousel-control-next-icon {
  background: $PRIMARY;
  padding: 1.2em;
  border-radius: 50%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .navbar-expand-lg {
    .container {
      padding: 0 30px;
      padding-right: 0 !important;
      .main-Container {
        position: relative;

        .navbar-toggler {
          display: block;
          border: none;

          .navbar-toggler-icon {
            // position: absolute;
            // left: -250px;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .navbar-collapse {
          .nav-item {
            .dropdown-menu {
              right: 0px;
              width: 277px;
              border: none;
              margin-top: 12px;

              .dropdown-Options {
                padding: 30px;

                .item-List {
                  padding-right: 40px;
                }
              }
            }
          }

          .iPad {
            display: none !important;
          }

          .btm_Img {
            display: none;
          }

          .mobile_Resp {
            display: block !important;

            .accordion {
              .accordion-item {
                .accordion-header {
                  .accordion-button {
                    padding-bottom: 15px;
                    justify-content: end;
                    padding-right: 49px;
                  }
                }

                .accordion-collapse {
                  .accordion-body {
                    padding-top: 0;

                    .dropdown-Items {
                      li {
                        a {
                          padding-bottom: 0;

                          &:before {
                            right: 0;
                            width: 40%;
                            left: unset;
                          }

                          &.dropdown-item {
                            padding-right: 0;
                            padding-top: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .collapse:not(.show) {
          display: none;

          .me-auto {
            flex-direction: row;
          }
        }

        .navbar-collapse {
          position: absolute;
          right: 0px;
          width: 277px;
          height: 93vh;
          top: 35px;
          background: $TXT_WHITE;
          z-index: 1;

          .nav-item {
            a {
              text-align: right;
              padding-top: 24px;
              padding-bottom: 10px;
            }
          }

          .navbar-nav {
            align-items: end;

            a {
              text-align: right;
              padding-bottom: 24px;
            }
          }
          .profile-dropdown {
            margin-right: 18px;
            margin-top: 8px;
            .dropdown-menu {
              &.show {
                right: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .navbar-expand-lg {
    .container {
      padding: 0 30px !important;
      .main-Container {
        // position: relative;
        .navbar-toggler {
          display: block;
          border: none;

          &:focus {
            box-shadow: none;
          }
        }

        .navbar-collapse {
          .nav-item {
            .dropdown-menu {
              right: 0;
              width: 277px;
              border: none;
              margin-top: 12px;
              .dropdown-Options {
                padding: 30px;

                .item-List {
                  padding-right: 40px;
                }
              }
            }
          }

          .iPad {
            display: none !important;
          }

          .btm_Img {
            display: none;
          }

          .mobile_Resp {
            display: block !important;

            .accordion {
              .accordion-item {
                .accordion-header {
                  .accordion-button {
                    padding-bottom: 15px;
                    justify-content: end;
                    padding-right: 49px;
                  }
                }

                .accordion-collapse {
                  .accordion-body {
                    padding-top: 0;

                    .dropdown-Items {
                      li {
                        a {
                          padding-bottom: 0;

                          &:before {
                            right: 0;
                            width: 40%;
                            left: unset;
                          }

                          &.dropdown-item {
                            padding-right: 0;
                            padding-top: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .collapse:not(.show) {
          display: none;

          .me-auto {
            flex-direction: row;
          }
        }

        .navbar-collapse {
          position: absolute;
          right: 0px;
          width: 277px;
          height: 93vh;
          top: 50px;
          background: $TXT_WHITE;
          z-index: 1;

          .nav-item {
            a {
              text-align: right;
              padding-top: 24px;
              padding-bottom: 10px;
            }
          }

          .navbar-nav {
            align-items: end;

            a {
              text-align: right;
              padding-bottom: 24px;
            }
          }
          .profile-dropdown {
            margin-right: 18px;
            margin-top: 16px;
            .dropdown-menu {
              &.show {
                right: 8px;
              }
            }
          }
        }
      }
    }
  }
  .container_Section.container {
    justify-content: space-between !important;
  }
  .menuListing .container {
    padding: 0px 16px 0px 16px !important;
  }
}
