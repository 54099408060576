@import "../../assests/scss/variables.scss";


.about_Page {
  .heading_Section {
    padding: 74px 120px 0 120px;
    padding-bottom: 60px;

    .about_us {
      font-size: $FONT_48;
      color: $TXT_PRIMARY;
      font-family: $BARLOW_BOLD;
      padding-bottom: 24px;
    }

    .about_Inner {
      font-size: $FONT_24;
      font-family: $BARLOW_BOLD;
      color: $TXT_SECONDARY;
      letter-spacing: 1px;
      padding: 0;
    }
  }

  .content_Img {
    display: flex;
    justify-content: space-between;
    padding: 74px 120px 0 120px;

    .width50 {
      width: 49%;

      .main_Title {
        font-size: $FONT_32;
        font-family: $BARLOW_BOLD;
        color: $SECONDARY;
      }

      .inner_Title {
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
        color: $SECONDARY;
        padding-bottom: 48px;
      }

      img {
        width: 100%;

      }

      .skill_Section {
        display: flex;

        .small50 {
          width: 50%;

          .titleText {
            position: relative;
            color: $SECONDARY;
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-left: 16px;
            line-height: 24px;
            padding-bottom: 12px;

            &:before {
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background: $SECONDARY;
              position: absolute;
              left: 0;
              top: 8px;
            }
          }
        }
      }
    }
  }

  .contact_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    background: $LIGHTBLUEBG;
    margin-top: 154px;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      form {
        background: $WHITE;
        padding: 44px 32px;
        width: 100%;
        display: table;

        .input_Section {
          display: flex;
          justify-content: space-between;

          .input {
            width: 49%;
            padding-bottom: 40px;
            position: relative;

            span {
              position: absolute;
              top: -3px;
              left: 161px;

              &.left200 {
                left: 201px;
              }

              &.left300 {
                left: 189px;
              }
            }

            .form-control {
              border: none;
              border-bottom: 1px solid $LIGHTBORDER;
              border-radius: 0;
              padding: 0;
              padding-bottom: 12px;

              &:focus {
                outline: none;
                box-shadow: none;
              }

              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
          }

          .phone_Section {
            width: 49%;

            .PhoneInput {
              border-bottom: 1px solid $LIGHTBORDER;
              padding-bottom: 12px;

              .PhoneInputInput {
                &:focus {
                  outline: none;
                  box-shadow: none;
                }

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: $TXT_PLACEHOLDER;
                  font-size: $FONT_16;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: $TXT_PLACEHOLDER;
                  font-size: $FONT_16;
                }

                &::placeholder {
                  color: $TXT_PLACEHOLDER;
                  font-size: $FONT_16;
                }
              }

              .PhoneInputInput {
                border: none;

              }
            }
          }
        }

        .textarea {
          #formBasicEmail {
            border: none;
            border-bottom: 1px solid $LIGHTBORDER;
            border-radius: 0;
            padding: 0;

            &::-webkit-input-placeholder {
              /* Edge */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &::placeholder {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
          }
        }

        .submit_Btn {
          width: 154px;
          position: relative;
          float: right;
          margin-top: 40px;

          .submitBtn {
            background: $SECONDARY  !important;
            height: 48px;
            color: $TXT_WHITE  !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;
            text-align: left;
            padding-left: 16px;
          }

          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 12px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }

  .padd_Bottom {
    padding-bottom: 90px;
  }

  .leader_Section {
    background: $LIGHTBG;
    padding: 0 120px 0 120px;

    .width20 {
      width: 30%;

      .skill_Section {
        .small50 {
          padding-top: 56px;

          .header {
            border-bottom: 1px solid $LIGHTBROWN;
            margin-bottom: 48px;

            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_LIGHT;
              color: $LIGHTGREY;
              padding-bottom: 15px;
            }

            .inner {
              font-size: $FONT_24;
              font-family: $BARLOW_REGULAR;
              color: $LIGHTBLACK;
              padding-left: 15px;
              padding-bottom: 28px;
            }
          }

          .name_Designation {
            position: relative;
            padding-left: 16px;
            padding-bottom: 24px;

            &::before {
              content: '';
              position: absolute;
              height: 58px;
              width: 3px;
              top: 2px;
              left: 0;
              background: $SECONDARY;
            }

            .name {
              font-size: $FONT_24;
              font-family: $BARLOW_REGULAR;
              color: $LIGHTBLACK;
              padding-bottom: 6px;
            }

            .designation {
              font-size: $FONT_18;
              font-family: $BARLOW_LIGHT;
              color: $LIGHTBLACK;
              padding-bottom: 4px;
            }

          }

          .inner_Details {
            padding-bottom: 24px;
            padding-left: 16px;

            .main_Text {
              font-size: $FONT_24;
              font-family: $BARLOW_LIGHT;
              color: $LIGHTBLACK;
              padding-bottom: 10px;
            }

            .text {
              font-size: $FONT_18;
              font-family: $BARLOW_LIGHT;
              color: $LIGHTBLACK;
            }
          }
        }
      }
    }

    .width60 {
      width: 70%;

      .img_Div {
        position: relative;

        img {
          width: 100%;
          position: relative;
        }

        .img_Text {
          position: absolute;
          bottom: 0;
          left: 0;
          background: #5b595e45;
          padding: 32px 60px;

          .bg_Text {
            .name {
              color: #C2BABA;
              font-size: $FONT_32;
              font-family: $BARLOW_REGULAR;
              padding-bottom: 14px;
            }

            .designation {
              font-size: $FONT_18;
              font-family: $BARLOW_LIGHT;
              color: $WHITE;
              padding-bottom: 15px;
            }

            .inner {
              font-size: $FONT_18;
              font-family: $BARLOW_LIGHT;
              color: $WHITE;
              padding-bottom: 0;
            }
          }
        }
      }
    }

  }

  .slick-list {
    padding-left: 20px;
  }

  .img_Slider {
    padding: 84px 116px;

    .imgSlider {
      padding-right: 41px;
      width: 33%;
      float: left;

      .imgSlider_Inner {
        border: 1px solid $BORDER;
        border-radius: 35px;

        img {
          width: 100%;
        }

        .head {
          padding: 32px 16px 0 16px;
          font-size: $FONT_14;
          font-family: $BARLOW_SEMIBOLD;
          color: $DARKBORDER;
        }

        .inner {
          padding: 0 16px 43px 16px;
          font-size: $FONT_24;
          font-family: $BARLOW_REGULAR;
          color: $SECONDARY;
        }
      }
    }

    .slick-prev {
      &::before {
        color: $SECONDARY;
      }
    }

    .slick-next {
      &::before {
        color: $SECONDARY;
      }
    }
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about_Page {
    .heading_Section {
      padding: 25px 15px;
    }

    .content_Img {
      padding: 25px 15px;
    }

    .img_Slider {
      padding: 25px 35px;
    }

    .leader_Section {
      padding: 0;

      .width60 {
        width: 50%;
      }

      .width20 {
        width: 50% !important;

        .skill_Section {
          .small50 {
            padding-left: 15px;
            padding-top: 15px;

            .header {
              margin-bottom: 18px;

              .inner {
                padding-bottom: 20px;
              }
            }

            .name_Designation {
              padding-bottom: 10px;
            }

            .inner_Details {
              padding-bottom: 0;

              .text {
                padding-bottom: 15px;
              }
            }
          }
        }
      }

      .width60 {
        width: 50% !important;
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .about_Page {
    .heading_Section {
      padding: 15px 15px;

      .about_us {
        font-size: $FONT_30;
        padding-bottom: 15px;
      }

      .about_Inner {
        font-size: $FONT_20;
      }
    }

    .content_Img {
      padding: 15px 15px;
      display: flex;
      flex-direction: column;

      .width50 {
        width: 100%;
        padding-bottom: 30px;

        .main_Title {
          font-size: $FONT_24;
          padding-bottom: 10px;
        }

        .inner_Title {
          padding-bottom: 20px;
        }
      }
    }

    .img_Slider {
      padding: 25px 35px;
    }

    .leader_Section {
      padding: 0;

      .width60 {
        width: 100% !important;
      }

      .width20 {
        width: 100% !important;

        .skill_Section {
          .small50 {
            padding-left: 15px;
            padding-top: 15px;

            .header {
              margin-bottom: 18px;

              .inner {
                padding-bottom: 20px;
              }
            }

            .name_Designation {
              padding-bottom: 10px;
            }

            .inner_Details {
              padding-bottom: 0;

              .text {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about_Page {
    .heading_Section {
      padding: 15px 15px;

      .about_us {
        font-size: $FONT_30;
        padding-bottom: 15px;
      }

      .about_Inner {
        font-size: $FONT_20;
      }
    }

    .content_Img {
      padding: 15px 15px;
      display: flex;
      flex-direction: column;

      .width50 {
        width: 100%;
        padding-bottom: 30px;

        .main_Title {
          font-size: $FONT_24;
          padding-bottom: 10px;
        }

        .inner_Title {
          padding-bottom: 20px;
        }
      }
    }

    .img_Slider {
      padding: 25px 35px;
    }

    .leader_Section {
      padding: 0;

      .width60 {
        width: 100% !important;
      }

      .width20 {
        width: 100% !important;

        .skill_Section {
          .small50 {
            padding-left: 15px;
            padding-top: 15px;

            .header {
              margin-bottom: 18px;

              .inner {
                padding-bottom: 20px;
              }
            }

            .name_Designation {
              padding-bottom: 10px;
            }

            .inner_Details {
              padding-bottom: 0;

              .text {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .about_Page {
    .heading_Section {
      padding: 15px 15px;

      .about_us {
        font-size: $FONT_30;
        padding-bottom: 15px;
      }

      .about_Inner {
        font-size: $FONT_20;
      }
    }

    .content_Img {
      padding: 15px 15px;
      display: flex;
      flex-direction: column;

      .width50 {
        width: 100%;
        padding-bottom: 30px;
        .skill_Section{
          flex-direction: column;
          .small50{
            width: 100%;
          }
        }
        .main_Title {
          font-size: $FONT_24;
          padding-bottom: 10px;
        }
        .inner_Title {
          padding-bottom: 20px;
        
        }
      }
    }

    .img_Slider {
      padding: 25px 35px;
    }

    .leader_Section {
      padding: 0;

     
      .width60 {
        width: 100% !important;
        .img_Div {
          .img_Text{
            padding:15px;
          }
        }
      }

      .width20 {
        width: 100% !important;

        .skill_Section {
          .small50 {
            padding-left: 15px;
            padding-top: 15px;

            .header {
              margin-bottom: 18px;

              .inner {
                padding-bottom: 20px;
              }
            }

            .name_Designation {
              padding-bottom: 10px;
            }

            .inner_Details {
              padding-bottom: 0;

              .text {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .about_Page {
    .heading_Section {
      padding: 15px 15px;

      .about_us {
        font-size: $FONT_30;
        padding-bottom: 15px;
      }

      .about_Inner {
        font-size: $FONT_20;
      }
    }

    .content_Img {
      padding: 15px 15px;
      display: flex;
      flex-direction: column;

      .width50 {
        width: 100%;
        padding-bottom: 30px;
        .skill_Section{
          flex-direction: column;
          .small50{
            width: 100%;
          }
        }
        .main_Title {
          font-size: $FONT_24;
          padding-bottom: 10px;
        }
        .inner_Title {
          padding-bottom: 20px;
        
        }
      }
    }

    .img_Slider {
      padding: 25px 35px;
    }

    .leader_Section {
      padding: 0;

     
      .width60 {
        width: 100% !important;
        .img_Div {
          .img_Text{
            padding:15px;
          }
        }
      }

      .width20 {
        width: 100% !important;

        .skill_Section {
          .small50 {
            padding-left: 15px;
            padding-top: 15px;

            .header {
              margin-bottom: 18px;

              .inner {
                padding-bottom: 20px;
              }
            }

            .name_Designation {
              padding-bottom: 10px;
            }

            .inner_Details {
              padding-bottom: 0;

              .text {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
