@import "../../assests/scss/variables.scss";
.titleText{
	font-size: $FONT_12;
	color: $TXT_PRIMARY;
	text-align: left;
	padding-bottom: 24px;
	font-family: $BARLOW_REGULAR;
	margin: 0;
	display: table;
    width: auto;
	background:transparent !important;
}