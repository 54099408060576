@import "../../assests/scss/variables.scss";
.info_Details {
    position: relative;
    text-align: center;
    .certificate_Img {
    }
    .name {
        position: absolute;
        top: 40.5%;
        left: 44%;
        //   transform: translate(-50%, -50%);
        font-size: 1.5vw;
        font-family:' Sacramento',cursive;
    }
    .program {
        position: absolute;
        top: 57%;
        left: 32%;
        font-size: 1.5vw;
        font-family: "Sacramento",cursive;
    }
}
