@import "../../assests/scss/variables.scss";

.inner_Page {
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
  background: $WHITE;
  padding: 25px 120px;

  .inner_Main {
    padding-top: 40px;

    .tab_Main_Section {
      .nav-item {
        padding-right: 48px;

        &:nth-child(1) {
          padding-left: 15px;
        }

        .nav-link {
          position: relative;
          padding: 0;
          line-height: 24px;
          padding-bottom: 10px;
          border: 0;

          &.active {
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background: $PRIMARY;
              bottom: 0-1px;
              left: 0;
            }
          }
        }
      }
    }

    .conatiner {
      display: flex;
      flex-wrap: wrap;
      padding-top: 40px;
    }

    .tab_Container {
      width: 32%;
      margin-right: 1.3%;
      padding-bottom: 56px;

      .tab_Content {
        .main_tab_Section {
          img {
            width: 100%;
          }

          .content_Section {
            border: 1px solid $BORDER;
            border-radius: 0 0 20px 20px;
            border-top: none;
            padding: 0 16px;
            margin-top: -17px;

            .height {
              height: 34px;
            }

            h1 {
              margin: 0 !important;
            }

            .titleText {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_14;
              padding-bottom: 8px;
            }

            .header_Label {
              color: $TXT_SECONDARY;
              font-size: $FONT_24;
              line-height: 40px;
            }

            .text_More {
              display: flex;
              flex-direction: row;

              .inner_Txt {
                font-size: $FONT_16;
                padding-bottom: 21px;
                font-family: $BARLOW_MEDIUM;
                color: $TXT_PLACEHOLDER;
                margin: 0;
                letter-spacing: 0.5px;
              }

              .learnMore {
                font-family: $BARLOW_SEMIBOLD;
                font-size: $FONT_16;
                text-decoration: none;
                color: $TXT_PRIMARY;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Container {
        width: 49%;
        margin-right: 1%;
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Container {
        width: 49%;
        margin-right: 1%;
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Container {
        width: 49%;
        margin-right: 1%;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Main_Section {
        .nav-item {
          padding-right: 30px;
        }
      }

      .tab_Container {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Main_Section {
        .nav-item {
          padding-right: 25px;
        }
      }

      .tab_Container {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .inner_Page {
    padding: 25px 15px;

    .inner_Main {
      .tab_Main_Section {
        .nav-item {
          padding-right: 20px;
        }
      }

      .tab_Container {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}