.headerText {
    font-size: 60px;
    margin-top: 30px;
    padding: 50px 50px 10px 50px; 
}


.links {
    display:block;
    margin:10px;
    font-size: 30px;
    padding-left: 50px;
    line-height: 30px;
    color: blue;
}