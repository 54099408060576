@import "./../../assests/scss/variables.scss";
.checkboxMain {
	// Checkbox Css
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
		position: absolute;
		left: -9999px;
	}
	[type="checkbox"]:not(:checked)+label,
	[type="checkbox"]:checked+label {
		position: relative;
		padding-left: 26px;
		cursor: pointer;
		padding-top: 2px;
	}
	/* checkbox aspect */
	[type="checkbox"]:not(:checked)+label:before,
	[type="checkbox"]:checked+label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 3px;
		width: 20px;
		height: 20px;
		border: 1px solid $TXT_SECONDARY;
		background: $WHITE;
		border-radius: 3px;
	}
	/* checked mark aspect */
	[type="checkbox"]:not(:checked)+label:after,
	[type="checkbox"]:checked+label:after {
		content: "\e92a";
		position: absolute;
		top: 6px;
		left: 0.22em;
		font-size: 8px;
		line-height: 0.8;
		color: #09ad7e;
		transition: all 0.2s;
		font-family: "tonypang" !important;
	}
	/* checked mark aspect changes */
	[type="checkbox"]:not(:checked)+label:after {
		opacity: 0;
		transform: scale(0);
	}
	[type="checkbox"]:checked+label:after {
		opacity: 1;
		transform: scale(1);
	}
	// Checkbox Css
	label {
		font-size:$FONT_16;
		font-family: $POPPINS_REGULAR;
		color: $TXT_SECONDARY;
	}
}