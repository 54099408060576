@import "../../assests/scss/variables.scss";

.main_container {
  .privacy_page {
    .main_section {
      width: 100%;
      padding: 84px 120px;
      .top_content {
        .top_heading {
          .heading {
            font-size: $FONT_48;
            font-family: $BARLOW_BOLD;
            color: $TXT_PRIMARY;
          }
          .inner_text {
            margin-top: 16px;
            .text1 {
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              color: $TXT_SECONDARY;
              line-height: 150%;
              text-align: left;
            }
          }
        }
      }
      .middle_content {
        margin-top: 36px;
        .middle_heading {
          .heading {
            font-size: $FONT_48;
            font-family: $BARLOW_BOLD;
            color: $TXT_PRIMARY;
          }
          .text {
            margin-top: 16px;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            color: $TXT_SECONDARY;
          }
          .list {
            margin-top: 16px;
            margin-bottom: 50px;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            color: $TXT_SECONDARY;
            margin-left: 24px;
            li {
              list-style: disc;
            }
            ul li {
              margin-top: 16px;
            }
          }
          .inner_text {
            .text1 {
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              color: $TXT_SECONDARY;
              line-height: 150%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
 
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
 
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .main_container {
    .privacy_page {
      .main_section {
        padding: 68px 16px;
        .top_content {
          .top_heading {
            .heading {
              font-size: $FONT_32;
            }
            .inner_text {
              margin-top: 8px;
              .text1 {
                font-size: $FONT_16;
              }
            }
          }
        }
        .middle_content {
          margin-top: 36px;
          .middle_heading {
            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_SEMIBOLD;
            }
            .text {
              margin-top: 8px;
            }
            .list {
              margin-top: 8px;
              margin-left: 24px;
              ul li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_container {
    .privacy_page {
      .main_section {
        padding: 68px 16px;
        .top_content {
          .top_heading {
            .heading {
              font-size: $FONT_32;
            }
            .inner_text {
              margin-top: 8px;
              .text1 {
                font-size: $FONT_16;
              }
            }
          }
        }
        .middle_content {
          margin-top: 36px;
          .middle_heading {
            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_SEMIBOLD;
            }
            .text {
              margin-top: 8px;
            }
            .list {
              margin-top: 8px;
              margin-left: 24px;
              ul li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main_container {
    .privacy_page {
      .main_section {
        padding: 68px 16px;
        .top_content {
          .top_heading {
            .heading {
              font-size: $FONT_32;
            }
            .inner_text {
              margin-top: 8px;
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
        .middle_content {
          margin-top: 36px;
          .middle_heading {
            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_SEMIBOLD;
            }
            .text {
              font-family: $BARLOW_REGULAR;
            }
            .inner_text {
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
            .text {
              margin-top: 8px;
            }
            .list {
              margin-top: 8px;
              font-family: $BARLOW_REGULAR;

              margin-left: 16px;

              ul li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .main_container {
    .privacy_page {
      .main_section {
        padding: 48px 16px;
        .top_content {
          .top_heading {
            .heading {
              font-size: $FONT_32;
            }
            .inner_text {
              margin-top: 8px;
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
        .middle_content {
          margin-top: 24px;
          .middle_heading {
            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_SEMIBOLD;
            }
            .text {
              font-family: $BARLOW_REGULAR;
            }
            .inner_text {
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
            .text {
              margin-top: 8px;
            }
            .list {
              margin-top: 8px;
              font-family: $BARLOW_REGULAR;
              margin-left: 16px;
              ul li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .main_container {
    .privacy_page {
      .main_section {
        padding: 32px 8px;
        .top_content {
          .top_heading {
            .heading {
              font-size: $FONT_32;
            }
            .inner_text {
              margin-top: 8px;
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
        .middle_content {
          margin-top: 16px;
          .middle_heading {
            .heading {
              font-size: $FONT_32;
              font-family: $BARLOW_SEMIBOLD;
            }
            .text {
              font-family: $BARLOW_REGULAR;
            }
            .inner_text {
              .text1 {
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }
            }
            .text {
              margin-top: 8px;
            }
            .list {
              margin-top: 8px;
              font-family: $BARLOW_REGULAR;
              margin-left: 16px;
              ul li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
