@import "../../assests/scss/variables.scss";

.SearchBox {
  padding: 0;
  width: 100%;
  display: inline-block;
  position: relative;
  .inputBox {
    position: relative;
  }
  .form-control {
    border-color: $BORDER;
    font-size: $FONT_12;
    font-weight: 400;
    height: auto;
    padding: 6px 16px 6px 50px;
    text-overflow: ellipsis;
    outline: none;
    height: 40px;
    background: $LIGHTBG !important;
    color:$TXT_PLACEHOLDER;
    font-size: $FONT_16;
    font-family: $BARLOW_SEMIBOLD;
    border-radius: 5px;
    position: relative;
    -webkit-appearance: none;
		-moz-appearance: none;
  
    &::-webkit-input-placeholder {
       color: $TXT_PLACEHOLDER;
    }
    &::-moz-placeholder {
       color: $TXT_PLACEHOLDER;
    }
    &:-ms-input-placeholder {
       color: $TXT_PLACEHOLDER;
    }
    &:-moz-placeholder {
       color: $TXT_PLACEHOLDER;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
    i{
    position: absolute;
    top: 15px;
    left: 24px;
    font-size:$FONT_16;
    color:$TXT_PLACEHOLDER;
    font-weight: 600;
    }
}
