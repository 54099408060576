@import "../../assests/scss/variables.scss";

.ios_Page {
  .banner_Text_Img {
    background-image: url("../../assests/img/homebg.svg");
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .text {
      display: flex;
      flex-direction: column;
      text-align: center;

      .main_Heading {
        color: $WHITE;
        font-size: $FONT_65;
        font-family: $BARLOW_BOLD;
        line-height: 150%;
        font-weight: 900;
        letter-spacing: 0.1em;
        padding-bottom: 1.5rem;
      }

      .sub_Heading {
        color: $WHITE;
        font-size: $FONT_24;
        padding: 0 15rem 0 15rem;
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 400;

        line-height: 150%;
      }
    }

    .appleImg {
      position: absolute;
      right: 12rem;
      top: 0.5rem;
    }
  }

  .about_Section {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      .card_Items {
        display: flex;

        .card {
          background: $LIGHTPINK;
          padding: 32px;
          text-align: left;
          border-radius: 10px;
          border: none;
          width: 49%;
          margin-right: 24px;
          margin-bottom: 25px;

          &.green {
            background: $LIGHTGREEN;
          }

          &.neon {
            background: $LIGHTNEON;
            margin-right: 0;
          }

          &.blue {
            background: $LIGHTBLUE;
          }

          &.blue {
            background: $LIGHTBGBLUE;
          }

          &.yellow {
            background: $LIGHTYELLOW;
          }

          &.pink {
            background: $LIGHTPINK;
          }

          .content {
            i {
              font-size: $FONT_60;
              color: $TXT_PRIMARY;

              &.green {
                color: $TXT_GREEN;
              }

              &.neon {
                color: $TXT_NEON;
              }

              &.blue {
                color: $TXT_BLUE;
              }

              &.yellow {
                color: $TXT_DARKYELLOW;
              }

              &.pink {
                color: $TXT_PINk;
              }
            }

            .titleText {
              padding-top: 15px;
              padding-bottom: 8px;
              font-size: $FONT_24;
              font-family: $BARLOW_SEMIBOLD;
              line-height: 36px;

              &.green {
                color: $TXT_GREEN;
              }

              &.neon {
                color: $TXT_NEON;
              }

              &.blue {
                color: $TXT_BLUE;
              }

              &.yellow {
                color: $TXT_DARKYELLOW;
              }

              &.pink {
                color: $TXT_PINk;
              }
            }
          }
        }
      }
    }
  }

  .content_Img {
    display: flex;
    padding: 74px 120px 0 120px;
    justify-content: space-between;

    .width50 {
      width: 49%;

      .main_Title {
        font-size: $FONT_32;
        font-family: $BARLOW_BOLD;
        color: $SECONDARY;
      }

      .inner_Title {
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
        color: $SECONDARY;
        padding-bottom: 48px;
      }

      img {
        width: 100%;
      }

      .skill_Section {
        display: flex;

        .small50 {
          width: 50%;

          .titleText {
            position: relative;
            color: $SECONDARY;
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-left: 16px;
            line-height: 24px;
            padding-bottom: 12px;

            &:before {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background: $SECONDARY;
              position: absolute;
              left: 0;
              top: 8px;
            }
          }
        }
      }
    }
  }

  .process {
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    background-color: $SECONDARY;

    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;

        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: white;
          position: relative;
          padding-left: 16px;
          padding-right: 0;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $WHITE;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .client_onboard {
      display: flex;
      justify-content: space-around;

      .one {
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 300;
        font-size: $FONT_48;
        line-height: 150%;

        color: $TXT_PRIMARY;
      }

      .onboarding {
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_32;
        line-height: 150%;

        color: $WHITE;
      }

      .pro {
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 600;
        font-size: $FONT_16;
        line-height: 150%;
        color: $WHITE;
      }
    }
  }

  .valueSection {
    display: flex;
    padding: 0px 120px 0px 120px;

    .inner {
      padding: 40px;
      border: 1px solid $BORDER;
      border-radius: 15px;
      margin-right: 24px;

      .im {
        padding-bottom: 24px;
      }

      .head {
        padding-bottom: 24px;
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;

        color: $TXT_SECONDARY;
      }

      .brief {
        padding-bottom: 48px;
        font-family: $BARLOW_REGULAR;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        color: $TXT_SECONDARY;
      }
    }
  }

  .iosApp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 42px 120px 84px 120px;
    background: #f2f2f2;
    margin-top: 100px;

    .head {
      font-family: $BARLOW_REGULAR;
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 150%;
      color: $TXT_BLACK;
      padding-bottom: 24px;
    }

    .subHeading {
      font-family: $BARLOW_REGULAR;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: $TXT_BLACK;
      padding-bottom: 24px;
    }

    .input_Center {
      width: 100%;

      .form1 {
        display: flex;
        align-items: baseline;

        .phone_Sec {
          background-color: $WHITE;
          width: 40%;

          button,
          input,
          optgroup,
          select,
          textarea {
            border-radius: 8px;
            height: 60px;
            border: 1px solid #efefef;
          }
        }

        .or {
          font-family: $BARLOW_REGULAR;
          font-size: $FONT_24;
          font-weight: 400;
          line-height: 150%;
          padding: 0px 8px 0px 8px;
        }

        .email_Div {
          width: 40%;

          .textField .form-control {
            border-radius: 8px;
            border: 1px solid #efefef;
          }
        }

        .submit_Btn {
          .bt {
            font-family: $BARLOW_REGULAR;
            background-color: #000000;
            margin-left: 40px;
            padding: 12px 54px;
          }
        }
      }
    }
  }

  .faq_List {
    padding: 40px 120px 0px 120px;

    .accordion__Block {
      display: flex;

      .accordion__Dots {
        button#dropdown-basic {
          background-color: $TXT_GREY !important;
          border-radius: 50%;
        }
      }

      .accordion__faq {
        width: 98%;
        font-family: $BARLOW_REGULAR;
        font-weight: 400;
        background-color: white;
        margin-bottom: 20px;
        padding: 0px 15px;
        border-radius: 5px;
        cursor: pointer;

        .accordion__Faq-heading {
          font-size: $FONT_16;
          line-height: 24px;
          border-bottom: 1px solid $BORDER;
          margin-bottom: 12px;

          .accordion__Icon {
            .vertical {
              position: absolute;
              left: 120px;
            }
          }

          div {
            padding-left: 18px;
          }

          .active {
            display: block;
          }
        }

        .accordion__Answer {
          font-size: $FONT_14;
          line-height: 21px;
          margin-bottom: 32px;

          .active {
            padding-left: 18px;
            display: block;
          }

          .inactive {
            display: none;
          }
        }
      }
    }
  }
}

.introClass {
  display: flex;
  align-self: center;
  width: 100%;
  flex-direction: row;
  padding: 100px 120px 85px 120px;

  .head {
    font-size: $FONT_48;
    font-family: $BARLOW_MEDIUM;
    padding: 0;
    padding-right: 118px;
    align-self: center;
  }

  .para {
    width: 570px;
    font-size: $FONT_16;
    font-family: $BARLOW_SEMIBOLD;
    color: $SECONDARY;
    position: relative;
    padding-left: 16px;
    padding-right: 0;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background: black;
      left: 0;
      top: 0;
    }
  }
}

@media only screen and (min-device-width: 1116px) and (max-device-width: 1270px) {
  .ios_Page {
    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 4rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_48;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 700;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_16;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 0rem;
        top: 0rem;
      }
    }

    .about_Section {
      padding: 80px 15px;
    }

    .iosApp {
      padding: 42px 120px 84px 120px;

      .head {}

      .subHeading {}

      .input_Center {
        width: 100%;

        .form1 {
          .phone_Sec {
            width: 35%;
          }

          .or {
            padding: 0px 8px 0px 8px;
          }

          .email_Div {
            width: 35%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
            }
          }

          .submit_Btn {
            .bt {}
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1115px) {
  .ios_Page {
    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: unset;

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 4rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_48;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 700;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_16;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 4rem;
        top: 0rem;
      }
    }

    .about_Section {
      padding: 80px 15px;
    }

    .content_Img {
      padding: 74px 15px 0 15px;
    }

    .contact_Section {
      padding: 80px 16px;

      .card_Section {
        form {
          padding: 40px 16px;
        }
      }
    }

    .process {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 20px;
            width: 24%;
            font-size: $FONT_24;
          }
        }
      }

      .client_onboard {
        .txt {
          .one {}

          .onboarding {}
        }
      }
    }

    .valueSection {
      padding: 0px 15px 0px 15px;

      .inner {
        padding: 24px 16px 48px 16px;

        margin-right: 15px;

        .im {
          padding-bottom: 24px;
        }

        .head {
          padding-bottom: 24px;
        }

        .brief {
          padding-bottom: 48px;
        }
      }
    }

    .iosApp {
      padding: 42px 16px 84px 16px;

      .head {
        font-size: $FONT_24;
        font-weight: 400;
        line-height: 36px;
        padding-bottom: 8px;
      }

      .subHeading {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 32px;
      }

      .input_Center {
        width: 100%;

        .form1 {
          .phone_Sec {
            width: 35%;
          }

          .or {
            font-size: $FONT_14;
            padding: 0px 8px 0px 8px;
          }

          .email_Div {
            width: 35%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
            }
          }

          .submit_Btn {
            .bt {}
          }
        }
      }
    }

    .faq_List {
      padding: 0px 15px 0px 15px;

      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }
  }

  .introClass {
    padding: 80px 15px;

    .head {}

    .para {}
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .ios_Page {
    background-size: auto;
    height: 773px;


    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: unset;

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 4rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 700;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_14;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 0rem;
        top: 0rem;
        left: 20rem;
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 20px;
            width: 24%;
            font-size: $FONT_24;
          }
        }
      }
    }

    .content_Img {
      padding: 50px 15px 0 15px;
      flex-direction: column;

      &.web_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      &.marketing_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      .width50 {
        width: 100%;

        .skill_Section {
          padding-bottom: 20px;
        }
      }
    }

    .contact_Section {
      padding: 80px 16px;
      margin-top: 0;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right: 52px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 534px;
          }
        }
      }

      .card_Section {
        form {
          padding: 40px 16px;
        }
      }
    }

    .process {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 20px;
            width: 24%;
            font-size: $FONT_24;
          }
        }
      }

      .client_onboard {
        .txt {
          .one {}

          .onboarding {}
        }
      }
    }

    .valueSection {
      padding: 0px 15px 0px 15px;

      .inner {
        padding: 24px 16px;

        margin-right: 15px;

        .im {
          padding-bottom: 24px;
        }

        .head {
          font-weight: 600;
          font-size: $FONT_16;
          padding-bottom: 12px;
        }

        .brief {
          font-weight: 400;
          font-size: $FONT_16;
          padding-bottom: 0px;
        }
      }
    }

    .iosApp {
      padding: 40px 16px 40px 16px;

      .head {
        font-size: $FONT_24;
        font-weight: 400;
        line-height: 36px;
        padding-bottom: 8px;
      }

      .subHeading {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 32px;
      }

      .input_Center {
        width: 100%;

        .form1 {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .phone_Sec {
            width: 80%;
          }

          .or {
            font-size: $FONT_14;
            padding: 8px 0px 16px 0px;
          }

          .email_Div {
            width: 80%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
            }
          }

          .submit_Btn {
            display: flex;

            align-self: flex-end;

            .bt {
              margin-left: 0px;
              margin-right: 74px;
            }
          }
        }
      }
    }

    .faq_List {
      padding: 0px 15px 0px 15px;

      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }
  }

  .introClass {
    padding: 80px 16px;
    margin-top: 0;

    .head {
      font-size: $FONT_32;
      font-family: $BARLOW_REGULAR;
      padding-right: 52px;
    }

    .para {
      font-size: $FONT_14;
      font-family: $BARLOW_REGULAR;
      width: 534px;
    }
  }
}

@media only screen and (min-device-width: 665px) and (max-device-width: 767px) {
  .ios_Page {
    background-size: auto;
    height: 600px;
    padding: 0 15px;

    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: unset;

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 4rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 700;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_14;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 0rem;
        top: 0rem;
        left: 20rem;
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 24%;
          }
        }
      }
    }

    .content_Img {
      padding: 50px 15px 0 15px;
      flex-direction: column;

      &.web_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      &.marketing_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      .width50 {
        width: 100%;

        .skill_Section {
          padding-bottom: 20px;
        }
      }
    }

    .contact_Section {
      padding: 80px 16px;

      .content_Section {
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right: 60px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 370px;
          }
        }
      }

      .card_Section {
        form {
          padding: 80px 0;
        }
      }
    }

    .process {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 30%;
          }
        }
      }

      .client_onboard {
        flex-wrap: wrap;

        .txt {
          .one {
            font-size: $FONT_32;
          }

          .onboarding {
            font-size: $FONT_26;
            font-weight: 600;
          }

          .pro {
            font-size: $FONT_20;
            font-weight: 400;
          }
        }
      }
    }

    .valueSection {
      flex-wrap: wrap;

      padding: 0px 15px 0px 15px;

      .inner {
        padding: 24px 16px;

        margin-right: 0px;
        margin-bottom: 16px;

        .im {
          padding-bottom: 24px;
        }

        .head {
          font-weight: 600;
          font-size: $FONT_16;
          padding-bottom: 12px;
        }

        .brief {
          font-weight: 400;
          font-size: $FONT_16;
          padding-bottom: 0px;
        }
      }
    }

    .iosApp {
      padding: 40px 16px 40px 16px;

      .head {
        font-size: $FONT_24;
        font-weight: 400;
        line-height: 36px;
        padding-bottom: 8px;
      }

      .subHeading {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 32px;
      }

      .input_Center {
        width: 100%;

        .form1 {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .phone_Sec {
            width: 80%;
          }

          .or {
            padding: 8px 0px 16px 0px;
            font-size: $FONT_14;
          }

          .email_Div {
            width: 80%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
            }
          }

          .submit_Btn {
            display: flex;

            align-self: flex-end;

            .bt {
              margin-left: 0px;
              margin-right: 74px;
            }
          }
        }
      }
    }

    .faq_List {
      padding: 0px 15px 0px 15px;

      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }
  }

  .introClass {
    padding: 80px 16px;

    .head {
      font-size: $FONT_32;
      font-family: $BARLOW_REGULAR;
      padding-right: 60px;
    }

    .para {
      font-size: $FONT_14;
      font-family: $BARLOW_REGULAR;
      width: 370px;
    }
  }
}

@media only screen and (min-device-width: 561px) and (max-device-width: 664px) {
  .ios_Page {
    background-size: auto;
    height: 600px;
    padding: 0 15px;

    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 0rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_30;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 900;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_14;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 0rem;
        top: 0rem;
        left: 0rem;

        .im {
          height: 44vh;
        }
      }
    }

    .about_Section {
      padding: 80px 15px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 30%;
          }
        }
      }
    }

    .content_Img {
      padding: 40px 15px 0 15px;
      flex-direction: column;

      &.web_Section {
        .image {
          padding-bottom: 50px;
        }

        .skill_Section {
          padding-bottom: 0 !important;
        }
      }

      &.marketing_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      .width50 {
        width: 100%;

        .main_Title {
          font-size: $FONT_24;
        }

        .inner_Title {
          padding-bottom: 24px;
        }

        .skill_Section {
          padding-bottom: 20px;
          flex-direction: column;

          .small50 {
            width: 100% !important;
          }
        }
      }
    }

    .contact_Section {
      padding: 40px 16px;
      margin-top: 0;

      .content_Section {
        .titleText {
          padding: 40px 16px;
          margin-top: 0;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 300px;
          }
        }
      }

      .card_Section {
        form {
          padding: 40px 16px;
        }
      }
    }

    .process {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          &:first-child {
            padding-right: 10px;
            font-size: $FONT_24;
            width: 30%;
          }
        }
      }

      .client_onboard {
        flex-wrap: wrap;

        .txt {
          .one {
            font-size: $FONT_32;
          }

          .onboarding {
            font-size: $FONT_26;
            font-weight: 600;
          }

          .pro {
            font-size: $FONT_20;
            font-weight: 400;
          }
        }
      }
    }

    .valueSection {
      flex-wrap: wrap;

      padding: 40px 15px 0px 15px !important;

      .inner {
        padding: 24px 16px;
        margin-bottom: 16px;
        margin-right: 0px;

        .im {
          padding-bottom: 24px;
        }

        .head {
          font-weight: 600;
          font-size: $FONT_16;
          padding-bottom: 12px;
        }

        .brief {
          font-weight: 400;
          font-size: $FONT_16;
          padding-bottom: 0px;
        }
      }
    }

    .iosApp {
      padding: 40px 16px 40px 16px;
      text-align: center;
      margin-top: 48px;

      .head {
        font-size: $FONT_24;
        font-weight: 400;
        line-height: 36px;
        padding-bottom: 8px;
      }

      .subHeading {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 32px;
      }

      .input_Center {
        width: 100%;

        .form1 {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .phone_Sec {
            width: 100%;
          }

          .or {
            padding: 8px 0px 16px 0px;
            font-size: $FONT_14;
          }

          .email_Div {
            width: 100%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
             
            }
          }

          .submit_Btn {
            display: flex;

            align-self: flex-end;

            .bt {
              margin-left: 0px;
              padding: 12px 24px;
            }
          }
        }
      }
    }

    .faq_List {
      padding: 0px 40px 0px 40px;

      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 40px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }
  }

  .introClass {
    padding: 40px 16px;
    margin-top: 0;

    .head {
      padding-right: 10px;
      font-size: $FONT_24;
      width: 30%;
    }

    .para {
      font-size: $FONT_14;
      font-family: $BARLOW_REGULAR;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 560px) {

  .ios_Page {
    background-size: auto;
    height: 812px;
    height: 600px;
    padding: 0;

    .banner_Text_Img {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      height:546px !important;
      .text {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding-left: 0rem;

        .main_Heading {
          color: $WHITE;
          font-size: $FONT_30;
          font-family: $BARLOW_REGULAR;
          line-height: 150%;
          font-weight: 900;
          letter-spacing: 0em;
          padding-bottom: 1.5rem;
        }

        .sub_Heading {
          color: $WHITE;
          font-size: $FONT_14;

          padding: 0;

          font-family: $BARLOW_REGULAR;
          font-style: normal;
          font-weight: 400;

          line-height: 150%;
        }
      }

      .appleImg {
        position: absolute;
        right: 0rem;
        top: 0rem;
        left: 0rem;

        .img {
          height: 44vh;
        }
      }
    }

    .about_Section {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          &:last-child {
            font-size: $FONT_12;
          }

          &:first-child {
            padding-right: 10px;
            font-size: $FONT_16;
            width: 66%;
          }
        }
      }

      .card_Section {
        .card_Items {
          flex-direction: column;

          .card {
            width: 100%;
            margin-right: 0;
            padding: 15px;
            margin-bottom: 16px;

            .content {
              i {
                font-size: $FONT_40;
              }

              .titleText {
                font-size: $FONT_16;
                padding-bottom: 8px;
                line-height: 24px;

                &:last-child {
                  font-size: $FONT_14;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }

    .content_Img {
      padding: 40px 15px 0 15px;
      flex-direction: column;

      &.web_Section {
        .image {
          padding-bottom: 50px;
        }

        .skill_Section {
          padding-bottom: 0 !important;
        }
      }

      &.marketing_Section {
        .image {
          padding-bottom: 50px;
        }
      }

      .width50 {
        width: 100%;

        .main_Title {
          font-size: $FONT_24;
        }

        .inner_Title {
          padding-bottom: 24px;
        }

        .skill_Section {
          padding-bottom: 20px;
          flex-direction: column;

          .small50 {
            width: 100% !important;
          }
        }
      }
    }

    .contact_Section {
      padding: 40px 16px;
      margin-top: 0;

      .content_Section {
        padding-bottom: 20px;

        .titleText {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          padding-right: 0;
          width: 41%;

          &:last-child {
            font-size: $FONT_12;
            font-family: $BARLOW_REGULAR;
            width: 290px;
          }
        }
      }

      .card_Section {
        form {
          padding: 40px 16px;

          .input_Section {
            flex-direction: column;

            .input {
              width: 100%;
              padding-bottom: 23px;
            }

            .phone_Section {
              width: 100%;
              margin-bottom: 23px;
            }
          }
        }
      }
    }

    .process {
      padding: 40px 16px;

      .content_Section {
        .titleText {
          &:last-child {
            font-size: $FONT_12;
          }

          &:first-child {
            padding-right: 10px;
            font-size: $FONT_16;
            width: 66%;
          }
        }
      }

      .client_onboard {
        flex-wrap: wrap;

        .txt {
          .one {
            font-size: $FONT_24;
          }

          .onboarding {
            font-size: $FONT_14;
            font-weight: 600;
          }

          .pro {
            font-size: $FONT_14;
            font-weight: 400;
          }
        }
      }
    }

    .valueSection {
      flex-wrap: wrap;
      padding: 0px 15px 0px 15px;

      .inner {
        padding: 24px 16px;
        margin-bottom: 16px;
        margin-right: 0px;

        .img {
          padding-bottom: 24px;
        }

        .head {
          font-weight: 600;
          font-size: $FONT_16;
          padding-bottom: 12px;
        }

        .brief {
          font-weight: 400;
          font-size: $FONT_16;
          padding-bottom: 0px;
        }
      }
    }

    .iosApp {
      padding: 40px 10px 40px 10px;
      text-align: center;
      margin-top: 48px;

      .head {
        font-size: $FONT_24;
        font-weight: 400;
        line-height: 36px;
        padding-bottom: 8px;
      }

      .subHeading {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 32px;
      }

      .input_Center {
        width: 100%;

        .form1 {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .phone_Sec {
            width: 100%;
          }

          .or {
            padding: 8px 0px 16px 0px;
            font-size: $FONT_14;
          }

          .email_Div {
            width: 100%;

            .textField .form-control {
              border-radius: 8px;
              border: 1px solid #efefef;
              height: 48px;
            }
          }

          .submit_Btn {
            display: flex;

            align-self: flex-end;

            .bt {
              margin-left: 0px;
              padding: 12px 24px;
            }
          }
        }
      }
    }

    .faq_List {
      padding: 0px 16px 0px 16px;

      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 20px !important;
              }
            }
          }
        }
      }
    }
    .iosApp .input_Center .form1 .phone_Sec input{
      height: 48px;
    }
  }

  .introClass {
    padding: 40px 16px;
    margin-top: 0;

    .head {
      padding-right: 10px;
      font-size: $FONT_16;
      width: 66%;
    }

    .para {
      font-size: $FONT_14;
      font-family: $BARLOW_LIGHT;
    }
  }
}