@import "../../assests/scss/variables.scss";



.loginHeader{
  padding: 0 !important;
  .container{
    max-width: 100%;
    background: $WHITE;
    height: 60px;
    .navbar-brand{
      padding: 0;
    }
    .main-Container{
      .navbar-toggler{
       border: none;
       display: none;
      }
     
        .displayShow{
          .dropdown{
            .dropdown-toggle {
            background-color: grey;
              width: 90px;
              height: 50px;
              border-radius: 45px;
              border:none;
              padding: 13px;
                 &:focus{
              box-shadow: none;
            }
            &:after{
              position: absolute;
              left: 18px;
              top: 22px;
            }
            .iconCss{
              padding-left: 20px;
            }
          }
          .dropdown-menu{
            right:0;
            left: unset;
          }
        }
        }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .loginHeader {
    .container{
      padding: 0 15px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px){
  .loginHeader {
    .container{
      padding: 0 15px;
    }
  }
}
@media only screen and (min-width:300px) and (max-width: 767px){
  .loginHeader {
    .container{
      padding: 0 12px;
    }
  }
}
