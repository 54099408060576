@import "../../assests/scss/variables.scss";

.ThankYouPopUp {
    .modal-dialog {
        max-width: 600px;

        .modal-content {
            height: 600px;
            border-radius: 16px;
            border-color: transparent;

            .modal-header {
                padding: 0;
                border: none;

                .header-img {
                    position: relative;

                    .bgImg {}

                    .close {
                        position: absolute;
                        top: 32%;
                        left: 30%;
                    }
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;
                padding: 0;
                align-items: center;
                flex: initial;

                .headerTitle {
                    font-size: $FONT_48;
                    font-family: $BARLOW_BOLD;
                    color: $PRIMARY;
                    padding-bottom: 8px;
                    line-height: 72px;
                }

                .name {
                    font-size: $FONT_24;
                    font-family: $BARLOW_REGULAR;
                    color: $SECONDARY;
                    padding-bottom: 56px;
                    line-height: 36px;
                }

                .inner {
                    font-size: $FONT_24;
                    font-family: $BARLOW_REGULAR;
                    color: $SECONDARY;
                    padding-bottom: 56px;
                    line-height: 36px;
                }
            }

            .modal-footer {
                border: none;
                padding: 0;
                display: flex;
                justify-content: center;
                .submitBtn {
                    width: 154px;
                    height: 48px;
                    padding: 0;
                    margin: 0;
                    background: $PRIMARY  !important;
                    color: $TXT_WHITE  !important;
                    border-radius: 8px !important;
                }
            }
        }
    }
}