@import "../../assests/scss/variables.scss";

.date {
  width: 100%;
  position: relative;
  i {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input {
        width: 100%;
        height: 48px;
        border: 1px solid $TABLE_BG;
        border-radius: 10px;
        padding: 0 26px;
        background: $TABLE_BG;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }
        &:focus {
          outline: none !important;
        }
      }
    }
  }
}
