@import "../../assests/scss/variables.scss";
.leave_Card{
	background: $TABLE_BG;
	height: auto;
	border-color: $TABLE_BG;
	display: flex;
	// justify-content: center;
	align-items: center;
	border-radius: 10px;
	.titleNos{
		font-size: $FONT_40;
		color: $TXT_BLACK;
		line-height: 60px;
		padding-bottom: 0;
		font-family: $BARLOW_SEMIBOLD;
		margin: 0;
	}
	.titleType{
		font-size: $FONT_16;
		color: $TXT_BLACK;
		line-height: 24px;
		padding-bottom: 0;
		font-family: $BARLOW_REGULAR;
	}

}