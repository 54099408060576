@import "../../assests/scss/variables.scss";

.customDropdown {
	margin: 0 !important;
	border-radius: 45px;

	.dropdown {
		width: 100%;
		text-align: left;

		.btn-primary {
			position: relative;
			background-color: $TABLE_BG  !important;
			width: 100%;
			font-weight: normal;
			text-align: left;
			padding: 7px 16px !important;
			border-radius: 5px;
			border: 1px solid $TABLE_BG  !important;
			font-family: $BARLOW_REGULAR;
			height: 48px;
			color: $TXT_GREY;
			font-size: $FONT_16;
			font-family: $BARLOW_REGULAR;

			&:after {
				position: absolute;
				right: 15px;
				top: 22px;
			}

			&:focus {
				box-shadow: none !important;
				border: 1px solid;
			}

			&:active {
				color: grey;
				border: 1px solid;
			}
		}

		.dropdown-menu {
			width: 100%;
			padding: 0;
			border: none;
			border-radius: 0;
			top: -2px !important;

			.dropdownData {
				display: block;
				width: 100%;
				max-height: 250px;
				overflow-y: scroll;
				padding: 0;
				padding: 8px 0;
				scrollbar-color: #d2d2d2;
				border-radius: 5px;
				border: 1px solid $TXT_GREY;
				-webkit-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
					0px 20px 32px rgba(96, 97, 112, 0.24);
				-moz-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
					0px 20px 32px rgba(96, 97, 112, 0.24);
				box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
					0px 20px 32px rgba(96, 97, 112, 0.24);
				border: none;

				&::-webkit-scrollbar {
					width: 6px;
					display: none;
				}

				.dropdown-item {
					font-size: $FONT_16;
					font-weight: normal;
					padding: 0;
					font-weight: 500 !important;
					padding: 8px 15px !important;

					&:last-child {
						border: none;
					}

					&:hover {
						background: transparent;
						// color: $TXT_WHITE;
					}

					&:active {
						color: Black;
					}

					&:first-child {
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}
				}
			}
		}

		&.selected {
			.btn-primary {
				color: $TXT_GREY  !important;
				height: 48px;
				background: $TABLE_BG;
			}
		}

		&.show {
			.btn-primary {
				&:after {
					color: blue;
				}
			}
		}
	}
}