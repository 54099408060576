@import "../../assests/scss/variables.scss";
.contact_Maindiv {
  .center_Section {
    display: flex;
    padding: 80px 0px 40px 324px;
    flex-direction: column;
    background: $LIGHTBLUEBG;
    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;

      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;
        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .form1 {
      background: $WHITE;
      padding: 40px 120px 76px 102px;

      .textField .form-control {
        border: none;
        border-bottom: 1px solid rgba(20, 20, 20, 0.2);
        border-radius: 0px;
        padding: 0px 0px;
        width: 100%;
        font-family: Barlow;
        font-style: normal;
        font-weight: normal;
        font-size: $FONT_16;
        line-height: 150%;
        color: $TXT_PLACEHOLDER;
      }

      .first_Line {
        display: flex;

        .whole_Name {
          display: flex;
          position: relative;
          margin-right: 63px;
          width: 100%;

          span {
            left: 175px;
            position: absolute;
            padding-top: 15px;
          }
        }
        .service {
          display: flex;
          position: relative;
          width: 100%;
          .colorRed {
            left: 220px;
            position: absolute;
            top: 15px;
          }
          span {
            left: 600px;
            position: absolute;
            top: 15px;
          }
        }
      }
      .second_Line {
        display: flex;

        .email_Div {
          display: flex;
          margin-right: 39px;
          width: 100%;
          position: relative;
          span {
            padding-left: 210px;
            position: absolute;
            padding-top: 15px;
          }
        }
      }
      .phone_Sec {
        width: 100%;
        .PhoneInput {
          border-bottom: 1px solid $LIGHTBORDER;
          padding: 16.5px;
          .PhoneInputInput {
            &:focus {
              outline: none;
              box-shadow: none;
            }
            &::-webkit-input-placeholder {
              /* Edge */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &::placeholder {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
          }
          .PhoneInputInput {
            border: none;
          }
        }
      }
      .submit_Btn {
        width: 154px;
        position: relative;
        float: right;
      
        .submitBtn {
          background: $SECONDARY !important;
          height: 48px;
          color: $TXT_WHITE !important;
          font-size: $FONT_16;
          font-family: $BARLOW_BOLD;
          text-align: left;
          padding-left: 16px;
        }
        i {
          position: absolute;
          right: 24px;
          color: $TXT_WHITE;
          top: 14px;
          font-weight: 600;
          font-size: $FONT_24;
        }
      }
    }
  }
}
.PhoneInputCountryIcon--border{
  border: none;
  box-shadow: none;
  background: transparent;
}
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .contact_Maindiv {
    height: auto !important;
    .center_Section {
      padding: 80px 0px 40px 324px;

      .content_Section {
        .titleText {
          &:last-child {
            &:before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              background: $SECONDARY;
              left: 0;
              top: 0;
            }
          }
        }
      }
      .form1 {
        .textField .form-control {
        }

        .first_Line {
          .whole_Name {
            span {
            }
          }
          .service {
            .colorRed {
              left: 220px !important;

              padding-top: 1px;
            }
            span {
              left: 320px !important;
            }
          }
        }
        .second_Line {
          display: flex;

          .email_Div {
            margin-right: 39px;

            span {
            }
          }
        }
        .phone_Sec {
          width: 100%;
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
              border: none;
            }
          }
        }
        .submit_Btn {
          width: 154px;
          position: relative;
          float: right;

          .submitBtn {
            background: $SECONDARY !important;
            height: 48px;
            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;
            text-align: left;
            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .contact_Maindiv {
    .center_Section {
      padding: 48px 32px 48px 32px !important;
      .content_Section {
        padding-bottom: 52px;
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          padding-right: 103px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;

            &:before {
              content: "";
            }
          }
        }
      }
      .form1 {
        background: $WHITE;
        padding: 40px 30px 76px 32px;

        .textField .form-control {
        }

        .first_Line {
          .whole_Name {
            margin-right: 16px;

            span {
              left: 175px;
            }
          }
          .service {
            .colorRed {
              left: 220px !important;
            }
            span {
              left: 400px !important;
            }
          }
        }
        .second_Line {
          .email_Div {
            margin-right: 16px;

            span {
              padding-left: 210px;
            }
          }
        }
        .phone_Sec {
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
            }
          }
        }
        .submit_Btn {
          width: 154px;

          .submitBtn {
            background: $SECONDARY !important;

            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;

            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .contact_Maindiv {
    .center_Section {
      padding: 48px 99px 48px 99px !important;
      .content_Section {
        padding-bottom: 52px;
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          padding-right: 103px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;

            &:before {
              content: "";
            }
          }
        }
      }
      .form1 {
        background: $WHITE;
        padding: 40px 30px 48px 32px !important;

        .textField .form-control {
        }

        .first_Line {
          .whole_Name {
            margin-right: 16px;

            span {
              left: 0px !important;
            }
          }
          .service {
            .colorRed {
              left: 0px !important;
            }
            span {
              left: 0px !important;
            }
          }
        }
        .second_Line {
          .email_Div {
            margin-right: 16px;

            span {
              left: 0px !important;
            }
          }
        }
        .phone_Sec {
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
            }
          }
        }
        .submit_Btn {
          width: 154px;

          padding-top: 40px !important;
          .submitBtn {
            background: $SECONDARY !important;

            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;

            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .contact_Maindiv {
    .center_Section {
      padding: 48px 32px 48px 32px !important;
      .content_Section {
        padding-bottom: 52px;
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          padding-right: 103px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;

            &:before {
              content: "";
            }
          }
        }
      }
      .form1 {
        background: $WHITE;
        padding: 40px 30px 76px 32px !important;

        .textField .form-control {
        }

        .first_Line {
          .whole_Name {
            margin-right: 16px;

            span {
              left: 175px !important;

              top: 0px !important;
            }
          }
          .service {
            .colorRed {
              left: 220px !important;

              top: 15px !important;
            }
            span {
              left: 260px !important;

              top: 15px !important;
            }
          }
        }
        .second_Line {
          .email_Div {
            margin-right: 16px;

            span {
              padding-left: 210px;

              padding-top: 15px;
            }
          }
        }
        .phone_Sec {
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
            }
          }
        }
        .submit_Btn {
          width: 154px;
          padding-top: 0px !important;
          margin-top: 0px !important;
          .submitBtn {
            background: $SECONDARY !important;

            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD !important;
            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_Maindiv {
    .center_Section {
      padding: 48px 32px 48px 32px !important;
      .content_Section {
        padding-bottom: 52px;
        .titleText {
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;

          padding-right: 103px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;

            &:before {
              content: "";
            }
          }
        }
      }
      .form1 {
        background: $WHITE;
        padding: 40px 30px 76px 32px;

        .textField .form-control {
        }

        .first_Line {
          .whole_Name {
            margin-right: 16px;

            span {
              left: 175px !important;

              top: 0px !important;
            }
          }
          .service {
            .colorRed {
              left: 220px !important;

              top: 15px !important;
            }
            span {
              left: 260px !important;

              top: 15px !important;
            }
          }
        }
        .second_Line {
          .email_Div {
            margin-right: 16px;

            span {
              padding-left: 210px;

              padding-top: 15px;
            }
          }
        }
        .phone_Sec {
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
            }
          }
        }
        .submit_Btn {
          width: 154px;
          padding-top: 0px !important;
          margin-top: 0px !important;
         
          .submitBtn {
            background: $SECONDARY !important;

            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD !important;

            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact_Maindiv {
    .center_Section {
      padding: 48px 15px 48px 15px !important;
      .content_Section {
        .titleText {
          font-size: $FONT_24;
          font-family: $BARLOW_REGULAR;
          padding-left: 15px;
          padding-right: 75px;

          &:last-child {
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;

            &:before {
              content: "";
            }
          }
        }
      }
      .form1 {
        padding: 40px 5px 76px 5px;

        .textField .form-control {
          font-size: 14px !important;
        }

        .first_Line {
          .whole_Name {
            margin-right: 10px;

            span {
              left: 154px;

              top: 1px;
            }
          }
          .service {
            .colorRed {
              left: 193px !important;

              top: 15px;
            }
            span {
              left: 250px !important;

              top: 15px;
            }
          }
        }
        .second_Line {
          .email_Div {
            margin-right: 10px;

            span {
              left: -26px !important;
            }
          }
        }
        .phone_Sec {
          .PhoneInput {
            border-bottom: 1px solid $LIGHTBORDER;
            padding: 16.5px;
            .PhoneInputInput {
              &:focus {
              }
              &::-webkit-input-placeholder {
                /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }

              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
            .PhoneInputInput {
            }
          }
        }
        .submit_Btn {
          width: 123px;

          .submitBtn {
            background: $SECONDARY !important;

            color: $TXT_WHITE !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;

            padding-left: 16px;
          }
          i {
            position: absolute;
            right: 24px;
            color: $TXT_WHITE;
            top: 14px;
            font-weight: 600;
            font-size: $FONT_24;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .center_Section {
    padding: 48px 10px 48px 32px !important;
    .content_Section {
      padding-bottom: 40px !important;
      .titleText {
        font-size: $FONT_16 !important;
        font-family: $BARLOW_REGULAR;

        padding-right: 16px !important;

        &:last-child {
          font-size: $FONT_14 !important;
          font-family: $BARLOW_LIGHT !important;

          &:before {
            content: "";
          }
        }
      }
    }
    .form1 {
      padding: 24px 16px 76px 16px !important;

      .textField .form-control {
        font-size: 14px !important;
      }

      .first_Line {
        display: grid !important;
        .whole_Name {
          margin-right: 10px;

          span {
            left: 155px !important;

            top: 1px;
          }
        }
        .service {
          .colorRed {
            left: 194px !important;
          }
          span {
            left: 380px !important;
          }
        }
      }
      .second_Line {
        display: grid !important;
        .email_Div {
          margin-right: 10px;

          span {
            left: -26px !important;
          }
        }
      }
      .phone_Sec {
        .PhoneInput {
          border-bottom: 1px solid $LIGHTBORDER;
          padding: 16.5px;
          margin-bottom: 22px;
          .PhoneInputInput {
            margin-right: 14px;
            &:focus {
            }
            &::-webkit-input-placeholder {
              /* Edge */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }

            &::placeholder {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
          }
          .PhoneInputInput {
          }
        }
      }
      .submit_Btn {
        width: 109px !important;

        .submitBtn {
          background: $SECONDARY !important;

          color: $TXT_WHITE !important;
          font-size: $FONT_14;
          font-family: $BARLOW_BOLD !important;

          padding-left: 16px;
        }
        i {
          position: absolute;
          right: 8px !important;
          color: $TXT_WHITE;
          top: 14px;
          font-weight: 600;
          font-size: $FONT_24;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .center_Section {
    padding: 40px 15px 48px 15px !important;
    .content_Section {
      padding-bottom: 40px !important;
      .titleText {
        font-size: $FONT_16 !important;
        font-family: $BARLOW_REGULAR;

        padding-right: 16px !important;

        &:last-child {
          font-size: $FONT_14 !important;
          font-family: $BARLOW_LIGHT !important;

          &:before {
            content: "";
          }
        }
      }
    }
    .form1 {
      padding: 24px 16px 76px 16px !important;

      .textField .form-control {
        font-size: 14px !important;
      }

      .first_Line {
        display: grid !important;
        .whole_Name {
          margin-right: 10px;

          span {
            left: 154px !important;

            top: 1px;
          }
        }
        .service {
          .colorRed {
            left: 193px !important;
          }
          span {
            left: 280px !important;
          }
        }
      }
      .second_Line {
        display: grid !important;
        .email_Div {
          margin-right: 10px;

          span {
            left: -26px !important;
          }
        }
      }
      .phone_Sec {
        .PhoneInput {
          border-bottom: 1px solid $LIGHTBORDER;
          padding: 16px;
          margin-bottom: 24px;
          .PhoneInputInput {
            &:focus {
            }
            &::-webkit-input-placeholder {
              /* Edge */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_14;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_14;
            }

            &::placeholder {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_14;
            }
          }
          .PhoneInputInput {
          }
        }
      }
      .submit_Btn {
        width: 109px !important;

        .submitBtn {
          color: $TXT_WHITE !important;
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;

          padding-left: 16px;
        }
        i {
          position: absolute;
          right: 10px !important;
          color: $TXT_WHITE;
          top: 13.5px !important;
          font-weight: 600;
          font-size: $FONT_24;
        }
      }
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 299px) {
  .contact_Maindiv {
    display: none;
  }
}
