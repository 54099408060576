@import "../../assests/scss/variables.scss";

.login_Container {
  height: 100vh;
  background: $WHITE;
  position: relative;
  width: 100%;
  float: left;

  .login_Page {
    .center_Section {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      .left_Side {
        height: 100vh;
        display: flex;
        align-items: center;
        width: 50%;
        padding-left: 150px;
        background: $WHITE;

        .img_Section {
          flex-direction: column;
          display: flex;
          width: 64%;
        }
      }

      .right_Side {
        background: rgba(245, 245, 245, 0.5);
        height: 100vh;
        display: flex;
        justify-content: center;
        width: 50%;
        padding: 0 72px;

        .input_Center {
          width: 485px;
          justify-content: center;
          display: flex;
          flex-direction: column;
        }

        .titleText {
          font-family: $POPPINS_SEMIBOLD;
          font-size: $FONT_30;
          color: $TXT_SECONDARY;
        }

        .remember_Forgot {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .forgot_Pass {
            font-size: $FONT_16;
            font-family: $POPPINS_REGULAR;
            color: $TXT_SECONDARY;
            text-decoration: none;
          }
        }

        .login_Btn {
          display: flex;
          justify-content: center;
          padding-top: 40px;

          .submitBtn {
            width: 485px;
            height: 60px;
            background: $PRIMARY  !important;
            color: $TXT_WHITE  !important;
            font-size: $FONT_16;
            font-family: $POPPINS_REGULAR;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .login_Page {
    .center_Section {
      .left_Side {
        padding-left: 80px;
      }

      .right_Side {
        padding: 0 50px;

        .input_Center {
          width: 404px;

        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .login_Page {
    .center_Section {
      .left_Side {
        padding-left: 80px;
      }

      .right_Side {
        padding: 0 55px;

        .input_Center {
          width: 267px;

          .textField {
            margin-bottom: 16px;

            .form-control {
              height: 48px;
            }

            &.textFieldCss {
              margin-bottom: 8px;
            }
          }

          .checkboxMain {
            .pointerC {
              font-size: $FONT_14;
              padding-left: 20px;
            }

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
              width: 16px;
              height: 16px;
            }
          }

          .forgot_Pass {
            font-size: $FONT_14;
          }
        }

        .login_Btn {
          padding-top: 20px;

          .submitBtn {
            height: 48px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width:575px) and (max-width: 767px) {
  .login_Page {
    .center_Section {
      .left_Side {
        padding-left: 80px;
      }

      .right_Side {
        padding: 0 55px;

        .input_Center {
          width: 267px;

          .textField {
            margin-bottom: 16px;

            .form-control {
              height: 48px;
            }

            &.textFieldCss {
              margin-bottom: 8px;
            }
          }

          .checkboxMain {
            .pointerC {
              font-size: $FONT_14;
              padding-left: 20px;
            }

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
              width: 16px;
              height: 16px;
            }
          }

          .forgot_Pass {
            font-size: $FONT_14;
          }
        }

        .login_Btn {
          padding-top: 20px;

          .submitBtn {
            height: 48px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
  .login_Page {
    .center_Section {
      .left_Side {
        display: none;
      }

      .right_Side {
        width: 100%;
        padding: 0 16px;

        .input_Center {
          width: 100%;

          .textField {
            margin-bottom: 24;

            .form-control {
              height: 60px;
            }

          }

          .checkboxMain {
            .pointerC {
              font-size: $FONT_14;
              padding-left: 20px;
            }

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
              width: 16px;
              height: 16px;
            }
          }

          .forgot_Pass {
            font-size: $FONT_14;
          }
        }

        .login_Btn {
          padding-top: 32px;

          .submitBtn {
            height: 48px;
          }
        }
      }
    }
  }
}