
@import "./assests/scss/variables.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Custom Fonts 
@font-face {
  font-family: 'icomoon';
  src:  url('./assests/fonts/icomoon.eot?bzvhla');
  src:  url('./assests/fonts/icomoon.eot?bzvhla#iefix') format('embedded-opentype'),
    url('./assests/fonts/icomoon.ttf?bzvhla') format('truetype'),
    url('./assests/fonts/icomoon.woff?bzvhla') format('woff'),
    url('./assests/fonts/icomoon.svg?bzvhla#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-circle:before {
  content: "\e901";
}
.icon-design:before {
  content: "\e902";
}
.icon-desktop:before {
  content: "\e903";
}
.icon-dollar:before {
  content: "\e904";
}
.icon-health:before {
  content: "\e905";
}
.icon-mail:before {
  content: "\e906";
}
.icon-marketing:before {
  content: "\e907";
}
.icon-mobile:before {
  content: "\e908";
}
.icon-phone:before {
  content: "\e909";
}
.icon-scooter:before {
  content: "\e90a";
}
.icon-seo:before {
  content: "\e90b";
}
.icon-setting:before {
  content: "\e90c";
}
.icon-shopping:before {
  content: "\e90d";
}
.icon-social:before {
  content: "\e90e";
}
.icon-taxi:before {
  content: "\e90f";
}
.icon-view:before {
  content: "\e910";
}
.icon-wheel:before {
  content: "\e911";
}
.icon-world:before {
  content: "\e912";
}
.icon-minus:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e914";
}
.icon-be:before {
  content: "\e915";
}
.icon-pinterest:before {
  content: "\e916";
}
.icon-downs:before {
  content: "\e917";
}
.icon-more-right:before {
  content: "\e918";
}
.icon-left-arrow:before {
  content: "\e919";
}
.icon-right-arrow:before {
  content: "\e91a";
}
.icon-visible:before {
  content: "\e91b";
}
.icon-profile:before {
  content: "\e91c";
}
.icon-search:before {
  content: "\e91d";
}
.icon-calendar:before {
  content: "\e91e";
}
.icon-edit:before {
  content: "\e91f";
}
.icon-delete:before {
  content: "\e920";
}
.icon-delete:before {
  content: "\e920";
}
.icon-add:before {
  content: "\e921";
}
.icon-pencil:before {
  content: "\e922";
}
.icon-attached:before {
  content: "\e923";
}
.icon-back:before {
  content: "\e924";
}
@font-face {
  font-family: 'barlowblack';
  src: url('./assests/fonts/barlow-black-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlowregular';
  src: url('./assests/fonts/barlow-regular-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'barlowbold';
  src: url('./assests/fonts/barlow-bold-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlowlight';
  src: url('./assests/fonts/barlow-light-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlowmedium';
  src: url('./assests/fonts/barlow-medium-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlowsemibold';
  src: url('./assests/fonts/barlow-semibold-webfont.woff2') format('woff2'),
       url('./assests/fonts/barlow-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'poppinslight';
  src: url('./assests/fonts/poppins-light-webfont.woff2') format('woff2'),
       url('./assests/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsregular';
  src: url('./assests/fonts/poppins-regular-webfont.woff2') format('woff2'),
       url('./assests/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsbold';
  src: url('./assests/fonts/poppins-bold-webfont.woff2') format('woff2'),
       url('./assests/fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsmedium';
  src: url('./assests/fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('./assests/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinssemibold';
  src: url('./assests/fonts/poppins-semibold-webfont.woff2') format('woff2'),
       url('./assests/fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


.colorRed{
  color: $TXT_PRIMARY !important;
}

.cursorP{
  cursor: pointer;
}

.colorBlue{
  color:$BLUE;
}

.colorRed{
  color:$RED;
}

ul{
  padding: 0 !important;
  margin: 0!important;
  li{
    list-style: none;
    padding: 0;
  }
}

// @media (min-width: 992px){

  .navbar-expand-lg {
    .navbar-nav {
      .dropdown-menu {
        position: absolute;
        left: unset;
        right: 0;
      }
    }
  }
// }