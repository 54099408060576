@import "../../assests/scss/variables.scss";

.blog_Page {
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
  background: $WHITE;
  padding: 0px 120px !important;

  .container_Section {
    padding: 0;
  }

  .blog_Main {
    padding-top: 55px;

    .tab_Main_Section {
      .nav-item {
        padding-right: 48px;

        &:nth-child(1) {
          padding-left: 15px;
        }

        .nav-link {
          position: relative;
          padding: 0;
          line-height: 24px;
          padding-bottom: 10px;
          color: $SECONDARY;
          border: 0;

          &.active {
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background: $PRIMARY;
              bottom: 0-1px;
              left: 0;
            }
          }
        }
      }

    }

    .common_Header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      padding-top: 43px;

      .search_Box {
        .SearchBox {
          width: 384px;

          .inputBox {
            input {
              height: 48px;
            }
          }
        }
      }

      .right_Section {
        display: flex;

        .date_Inline {
          display: flex;

          .date_Section {
            position: relative;
            margin-right: 24px;

            .react-datepicker-wrapper {
              width: 190px;

              input {
                height: 48px;
                width: 100%;
                border: 1px solid $TXT_PLACEHOLDER;
                border-radius: 8px;
                padding: 0 16px;

                &:focus {
                  box-shadow: none;
                  outline: none;
                }
              }
            }

            .iconCalendar {
              position: absolute;
              right: 15px;
              top: 16px;
              color: $OFFLIGHT;
              font-size: $FONT_14;
            }
          }
        }

        .submitBtn {
          background: red !important;
          color: $TXT_WHITE !important;
          width: 110px;
          height: 48px;
        }

      }
    }

    .faq_Main {
      padding-top: 40px;

      .submitBtn {
        width: 150px;
        background: $PRIMARY !important;
        color: $TXT_WHITE !important;
        height: 48px;
        float: right;
        margin-bottom: 16px;
      }

      .accordion_Main {
        display: table;
        width: 100%;

        .accordion {
          .accordion-item {
            border: 0;
            position: relative;

            .dropodown_Faq {
              display: block;
              position: absolute;
              top: 15px;
              z-index: 1111;
              right: 10px;

              button {
                background: #898989;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border-color: transparent;
                display: flex;
                justify-content: center;

                &:focus {
                  box-shadow: none;
                }
              }

              .dropdown-menu {
                text-align: right;
                min-width: 94px;
                border-radius: 8px;
                border-color: $WHITE;
                box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
                transform: translate(0px, 33px);

                .dropdown-item {
                  font-size: $FONT_16;
                  color: $TXT_BLACK;
                  line-height: 24px;

                  &:hover {
                    background-color: transparent;
                  }
                }
              }
            }

            .accordion-header {
              padding-right: 20px;

              .accordion-button {
                background-color: $WHITE;
                color: $TXT_BLACK;
                border-bottom: 1px solid;

                &.collapsed {
                  .dropodown_Faq {
                    display: none;
                  }
                }

                &:focus {
                  outline: none;
                  box-shadow: none;
                }

                &:after {
                  background-image: none;
                  content: "\e913";
                  font-family: icomoon;
                  left: 0;
                  width: 0;
                  margin: 0;
                  position: absolute;
                  font-size: $FONT_14;
                  top: 16px;
                  transform: none;
                }

                &.collapsed {
                  &:after {
                    background-image: none;
                    content: "\e914";
                    font-family: icomoon;
                    left: 0;
                    width: 0;
                    margin: 0;
                    position: absolute;
                    font-size: $FONT_14;
                    top: 14px;
                    transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    // holiday list
    .right_Section {
      &.holidayList {
        justify-content: flex-end;
        display: flex;

        .submitBtn {
          width: 173px;
          background: $PRIMARY !important;
          height: 48px;
          color: $TXT_WHITE !important;
          margin-top: 24px;
          font-size: $FONT_16;
          font-family: $BARLOW_BOLD;
        }

      }
    }

    .contact_Table {
      &.holiday_Table {
        margin-top: 40px !important;

        .header {
          padding: 0 48px !important;

          li {
            width: 39% !important;
            font-size: $FONT_16;
            font-family: $BARLOW_BOLD;
            color: $SECONDARY;

            &:last-child {
              width: 15% !important;
            }
          }
        }

        .data_List {
          padding: 0 48px !important;

          li {
            width: 39% !important;

            &:last-child {
              width: 15% !important;
            }

            .paddRight {
              padding-right: 20px;
            }
          }
        }
      }
    }

    // faq css
    .faq_List {
      padding-top: 40px;

      .update__faq {
        margin-top: 89px;
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;

        .submitBtn {
          width: 150px;
          background: $PRIMARY !important;
          color: $TXT_WHITE !important;
          height: 48px;
        }
      }

      .accordion__Block {
        display: flex;

        .accordion__Dots {
          button#dropdown-basic {
            background-color: $TXT_GREY !important;
            border-radius: 50%;
          }
        }

        .accordion__faq {
          width: 98%;
          font-family: $BARLOW_REGULAR;
          font-weight: 400;
          background-color: white;
          margin-bottom: 20px;
          padding: 0px 15px;
          border-radius: 5px;
          cursor: pointer;

          .accordion__Faq-heading {
            font-size: $FONT_16;
            line-height: 24px;
            border-bottom: 1px solid $BORDER;
            margin-bottom: 12px;

            .accordion__Icon {
              .vertical {
                position: absolute;
                left: 120px;
              }
            }

            div {
              padding-left: 18px;
            }

            .active {
              display: block;
            }
          }

          .accordion__Answer {
            font-size: $FONT_14;
            line-height: 21px;
            margin-bottom: 32px;

            .active {
              padding-left: 18px;
              display: block;
            }

            .inactive {
              display: none;
            }
          }
        }
      }
    }

    .query {
      padding-top: 32px;
      display: flex;
      flex-wrap: wrap;

      .data_List--Query {
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
        padding: 12px 23px !important;
        margin-bottom: 24px !important;
        margin-right: 24px !important;
        background: $LIGHTBG;
        min-height: 58px;
        border: 1px solid $TABLE_BG;
        align-items: center;
        width: 18%;
        display: flex;
        flex-direction: column;
        text-align: right;

        li {
          width: 100% !important;
          margin-bottom: 8px;

          &.email {
            width: 100%;
            position: relative;

            &:before {
              content: "Email";
              position: absolute;
              top: 7px;
              left: 0px;
              font-size: $FONT_16;
              font-family: $BARLOW_SEMIBOLD !important;
              line-height: 10px;
            }
          }

          &.phone {
            width: 100%;
            position: relative;

            &:before {
              content: "Phone";
              position: absolute;
              top: 7px;
              left: 0px;
              font-size: $FONT_16;
              font-family: $BARLOW_SEMIBOLD !important;
              line-height: 10px;
            }
          }

          &.date {
            width: 100%;
            position: relative;

            &:before {
              content: "Date";
              position: absolute;
              top: 7px;
              left: 0px;
              font-size: $FONT_16;
              font-family: $BARLOW_SEMIBOLD !important;
              line-height: 10px;
            }
          }
        }
      }
    }

    .card_Section {
      display: flex;
      justify-content: space-between;

      .card_Main {
        width: 32.33%;

        .img_Section {
          width: 100%;
          position: relative;

          .blog_Img {
            width: 100%;

            &.displayIpad {
              display: none;
            }
          }

          .custom_Dropdown {
            position: absolute;
            top: 16px;
            right: 16px;

            button {
              width: 24px;
              height: 24px;
              border-radius: 50% !important;
              background-color: $SECONDARY !important;
              opacity: 0.5;
              border-color: transparent !important;
              display: flex;
              justify-content: center;
              align-items: center;

              &:after {
                display: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .iconCss {
              color: $TXT_WHITE;
            }

            .dropdown-item {
              color: $TXT_BLACK;
              font-family: $BARLOW_REGULAR;
              font-size: $FONT_16;
              line-height: 24px;
              text-align: right;
              padding: 0;
              padding-bottom: 8px !important;

              &:hover {
                background: transparent;
              }

              &.redC {
                color: $RED;
              }
            }

            .dropdown-menu {
              border: none;
              padding: 8px 8px;
              padding-bottom: 0 !important;
              min-width: 94px;
              border-radius: 8px;

              &.show {
                right: 0 !important;
                left: unset !important;
              }
            }
          }

          .date_Section {
            background: $OFFLIGHTGREY;
            position: absolute;
            bottom: 16px;
            right: 16px;
            height: 37px;
            width: 109px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;

            .date {
              color: $TXT_WHITE;
              text-align: center;
              font-size: $FONT_16;
              font-weight: $BARLOW_SEMIBOLD;
            }
          }
        }

        .content_Section {
          border: 1px solid $BORDER;
          border-top: 0;
          border-radius: 20px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          padding: 0 16px;
          margin-top: -22px;
          padding-bottom: 16px;

          .main_Heading {
            color: $TXT_PLACEHOLDER;
            font-family: $BARLOW_SEMIBOLD;
            font-size: $FONT_14;
            line-height: 21px;
            padding-top: 32px;
            padding-bottom: 8px;
          }

          .heading {
            color: $TXT_PLACEHOLDER;
            font-family: $BARLOW_REGULAR;
            font-size: $FONT_24;
            line-height: 26px;
            padding-bottom: 8px;
          }

          .inner_TxtSection {
            position: relative;

            .titleText {
              color: $TXT_GREY;
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              padding-bottom: 16px;
              line-height: 21px;
            }

            .read_More {
              position: absolute;
              bottom: -4px;
              font-size: $FONT_14;
              font-family: $BARLOW_SEMIBOLD;
              color: $TXT_PRIMARY;
            }
          }
        }
      }
    }

    // contact form
    .tab_Button {
      display: flex;

      .submitBtn {
        width: 150px;
        background: $PRIMARY !important;
        color: $TXT_WHITE !important;
        height: 48px;

        &:nth-child(2) {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          background: $LIGHTBG !important;
          color: $TXT_SECONDARY !important;
        }
      }
    }

    .contact_Table {
      margin: 34px 0;
      border: 1px solid $TABLE_BG;
      border-radius: 10px;
      background: $TABLE_BG;

      .header {
        display: flex;
        height: 58px;
        align-items: center;
        padding: 0 24px !important;

        .combine {
          display: flex;
          width: 58%;

          .combine_Li {
            display: flex;
            width: 100%;

            li {
              width: 33%;
              padding-right: 10px;

              &.email {
                width: 40%;
              }

              &.phone {
                width: 23%;
              }
            }
          }
        }

        li {
          width: 16%;
          padding-right: 10px;

          &.date {
            width: 14%;
          }

          &.project {
            width: 25%;
          }
        }
      }

      .data_List {
        display: flex;
        min-height: 58px;
        border-bottom: 1px solid $TABLE_BG;
        align-items: center;
        padding: 0 24px !important;
        background: $LIGHTBLUEBG;

        .combine {
          display: flex;
          width: 58%;

          .combine_Li {
            display: flex;
            width: 100%;

            li {
              width: 33%;
              padding-right: 10px;

              &.email {
                width: 40%;
              }

              &.phone {
                width: 23%;
              }
            }
          }
        }

        li {
          width: 16%;
          padding-right: 10px;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;

          &.date {
            width: 14%;
          }

          &.project {
            width: 25%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    // upload section
    .upload_Section {
      padding-top: 34px;

      .backIcon {
        padding-left: 8px;
      }

      .img_Section {
        padding-top: 40px;
        display: flex;
        justify-content: space-between;

        .width50 {
          width: 49.5%;
          border: 2px dashed $TXT_PLACEHOLDER;
          border-radius: 12px;

          img {
            width: 100%;
          }

          &.left_Section {
            position: relative;

            .icon_Bg {
              position: absolute;
              right: 16px;
              background: $PRIMARY;
              width: 24px;
              height: 24px;
              border-radius: 12px;
              top: 16px;
              display: flex;
              justify-content: center;
              align-items: center;

              .deleteIcon {
                font-size: $FONT_14;
                color: $TXT_WHITE;
              }
            }
          }

          &.right_Section {
            position: relative;

            input {
              border: none;
              font-size: 0;
              background: transparent;
              opacity: 0;
              padding: 0;
              height: 100%;
            }

            .add_Image {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 45%;
              top: 43%;

              .addIcon {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_24;
              }

              label {
                margin: 0;
                color: $TXT_PLACEHOLDER;
                padding-top: 6px;
              }
            }
          }
        }
      }

      .content_Section {
        padding-top: 32px;

        .form1 {
          .textField {
            margin-bottom: 8px;

            .form-control {
              background: $LIGHTBG;
              border: none;
              padding: 32px;
            }
          }

          .heightText {
            height: 225px;
            padding: 32px;
            margin-top: 18px;
            background: $LIGHTBG;
            border: none;
            border-radius: 10px;
            resize: none;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            color: $TXT_LIGHT;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .upload_Btn {
            padding-bottom: 50px;
            display: table;
            float: right;

            .submitBtn {
              background: $PRIMARY !important;
              width: 245px;
              color: $TXT_WHITE !important;
              height: 48px;
              border-radius: 5px !important;
              margin-top: 40px;
              font-family: $BARLOW_SEMIBOLD;
            }
          }
        }

        &.add_FAQ {
          padding-top: 0;
        }
      }
    }
  }

  // policy css
  .privacy_page {
    .policy_Button {
      display: flex;
      justify-content: flex-end;
      padding-top: 24px;
      padding-bottom: 32px;

      .submitBtn {
        width: 175px;
        background: $SECONDARY !important;
        height: 48px;
        border-radius: 5px !important;
        color: $WHITE !important;

        &:last-child {
          margin-left: 24px;
          background: $PRIMARY !important;
        }
      }
    }

    .policy_Img {
      position: relative;

      .img_Txt {
        position: absolute;
        background: #62696a63;
        width: 100%;
        bottom: 0;
        height: 60px;
        display: flex;
        justify-content: center;

        .titleText {
          color: $TXT_WHITE !important;
          text-align: center;
          padding: 0;
          align-self: center;
          font-size: $FONT_32;
          font-family: $BARLOW_BOLD;
        }
      }
    }

    .main_section {
      width: 100%;
      padding: 30px 0;

      .top_content {
        .top_heading {
          .heading {
            font-size: $FONT_48;
            font-family: $BARLOW_BOLD;
            color: $TXT_PRIMARY;
          }

          .inner_text {
            margin-top: 16px;

            .text1 {
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              color: $TXT_SECONDARY;
              line-height: 150%;
              text-align: left;
            }
          }
        }

      }

      .middle_content {
        margin-top: 36px;

        .middle_heading {
          .heading {
            font-size: $FONT_48;
            font-family: $BARLOW_BOLD;
            color: $TXT_PRIMARY;
          }

          .text {
            margin-top: 16px;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            color: $TXT_SECONDARY;
          }

          .list {
            margin-top: 16px;
            margin-bottom: 50px;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            color: $TXT_SECONDARY;
            margin-left: 24px;

            li {
              list-style: disc;
            }

            ul li {
              margin-top: 16px;
            }
          }

          .inner_text {
            .text1 {
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              color: $TXT_SECONDARY;
              line-height: 150%;
              text-align: left;
            }
          }
        }
      }
    }
  }

  // management 
  .contact_Table {
    &.management_Table {
      .header {
        padding: 0 32px !important;

        li {
          width: 10% !important;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;

          &:nth-child(2) {
            width: 20% !important;
          }

          &:nth-child(3) {
            width: 8% !important;
          }

          &:nth-child(4) {
            width: 8% !important;
          }

          &:nth-child(5) {
            width: 10% !important;
          }

          &:nth-child(6) {
            width: 30% !important;
          }

        }
      }

      .data_List {
        li {
          width: 10% !important;

          &.leave {
            .customDropdown {
              width: 111px;

              .dropdown {
                .dropdown-toggle {
                  width: 111px;
                  height: 32px;
                  padding: 2px 16px !important;
                  border: 1px solid $BORDER !important;
                  background-color: $WHITE !important;

                  &:after {
                    right: 15px;
                    top: 14px;
                    color: $TXT_BLACK;
                  }
                }
              }
            }
          }

          &.status {
            .customDropdown {
              width: 111px;

              .dropdown {
                .dropdown-toggle {
                  width: 111px;
                  height: 32px;
                  padding: 2px 16px !important;
                  border: 1px solid $BORDER !important;
                  background-color: $WHITE !important;

                  &:after {
                    right: 15px;
                    top: 14px;
                    color: $TXT_BLACK;
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            width: 20% !important;
          }

          &:nth-child(3) {
            width: 8% !important;
          }

          &:nth-child(4) {
            width: 8% !important;
          }

          &:nth-child(5) {
            width: 10% !important;
          }

          &:nth-child(6) {
            width: 30% !important;
          }
        }
      }
    }
  }

  .query_card {
    padding-top: 20px;

    .card_Section {
      width: 282px;
      background: $LIGHTBG;
      padding: 12px 23px;
      border-radius: 10px;
      margin-right: 24px;

      .content_section {

        .d-flex {
          padding-bottom: 8px;
        }

        .paddingLedt {
          padding-left: 10px;
          font-size: 16px;
          color: $SECONDARY;
        }

        span {
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          width: 45px;

        }
      }
    }
  }
}

// Apply Leave

.leave_Main {
  padding-top: 40px;

  .leave_Type {
    display: flex;
    flex-wrap: wrap;

    .card_Width {
      width: 180px;
      margin-right: 24px;
      margin-bottom: 24px;
      height: 120px;
      justify-content: center;

      &:nth-child(6) {
        margin-right: 0;
      }
    }
  }

  .user_Details {
    padding-top: 22px;
    display: flex;
    justify-content: space-between;

    .name_Section {
      display: flex;
      flex-direction: column;

      .common_Details {
        display: flex;
        align-items: baseline;

        .common {
          width: 90px;
          display: table;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }

        .bold {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          padding-left: 13px;
        }
      }
    }

    .button_Bottom {
      display: flex;
      align-items: flex-end;

      button {
        width: 129px;
        background: $PRIMARY !important;
        color: $TXT_WHITE !important;
        height: 48px;
      }
    }
  }

  .leave_Table {
    margin: 34px 0;
    border: 1px solid $TABLE_BG;
    border-radius: 10px;
    background: $TABLE_BG;

    .header {
      display: flex;
      height: 58px;
      align-items: center;
      padding: 0 24px !important;

      li {
        width: 16%;
        padding-right: 10px;

        &.reason {
          width: 36%;
        }
      }
    }

    .data_List {
      display: flex;
      min-height: 58px;
      border-bottom: 1px solid $TABLE_BG;
      align-items: center;
      padding: 0 24px !important;
      background: $LIGHTBLUEBG;

      li {
        width: 16%;
        padding-right: 10px;
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;

        &.reason {
          width: 36%;
        }
      }
    }
  }

  .apply_Leave_Section {
    margin-bottom: 40px;
    margin-top: 46px;

    .titleText {
      background: $WHITE;
      font-size: $FONT_24;
      font-family: $BARLOW_SEMIBOLD;
      color: $TXT_BLACK;
      width: fit-content;
      height: auto;
    }

    .date_Section {
      display: flex;
      justify-content: space-between;

      .date {
        width: 49%;
        position: relative;

        i {
          position: absolute;
          right: 20px;
          top: 15px;
        }

        .react-datepicker-wrapper {
          width: 100%;

          .react-datepicker__input-container {
            input {
              width: 100%;
              height: 48px;
              border: 1px solid $TABLE_BG;
              border-radius: 10px;
              padding: 0 26px;
              background: $TABLE_BG;

              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: $TXT_GREY;
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }

              &::-moz-placeholder {
                /* Firefox 19+ */
                color: $TXT_GREY;
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }

              &:-ms-input-placeholder {
                /* IE 10+ */
                color: $TXT_GREY;
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }

              &:-moz-placeholder {
                /* Firefox 18- */
                color: $TXT_GREY;
                font-size: $FONT_16;
                font-family: $BARLOW_REGULAR;
              }

              &:focus {
                outline: none !important;
              }
            }
          }
        }
      }

    }

    textarea {
      margin-top: 24px;
      height: 150px;
      resize: none;
      border: 1px solid $TABLE_BG;
      border-radius: 10px;
      padding: 24px 26px;
      background: $TABLE_BG;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $TXT_GREY;
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $TXT_GREY;
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $TXT_GREY;
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $TXT_GREY;
        font-size: $FONT_16;
        font-family: $BARLOW_REGULAR;
      }
    }

    .attached_Upload {
      margin-top: 24px;
      width: 50%;
      position: relative;

      i {
        position: absolute;
        right: 25px;
        top: 14px;
        color: $SECONDARY;
        z-index: 111;
      }

      input {
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
        }
      }

      .file-upload-wrapper {
        color: $TABLE_BG;
        height: 48px;
        position: relative;
        width: 100%;

        &:after {
          content: attr(data-text);
          font-size: 18px;
          position: absolute;
          top: 0;
          left: 0;
          background: $TABLE_BG;
          display: block;
          pointer-events: none;
          z-index: 20;
          color: #999;
          border-radius: 5px 10px 10px 5px;
          font-weight: 300;
          width: 100%;
          height: 48px;
          padding: 10px 15px;
        }

        input {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 99;
          height: 48px;
          margin: 0;
          padding: 0;
          display: block;
          cursor: pointer;
          width: 100%;
        }
      }
    }

    .leave_Bottom {
      display: flex;
      justify-content: flex-end;
      padding-top: 40px;
      padding-bottom: 30px;

      .submitBtn {
        width: 281px;
        margin-right: 24px;
        border: 1px solid $BORDER !important;
        height: 60px;
        color: $TXT_BLACK !important;
        background: $TABLE_BG !important;

        &:nth-child(2) {
          background: $PRIMARY !important;
          color: $TXT_WHITE !important;
          border: none !important;
        }
      }
    }
  }
}

// modal css
.modalPopup {
  .modal-dialog {
    max-width: 950px;

    .modal-content {
      padding: 32px;
      width: 100%;

      .modal-header {
        border: none !important;
        padding: 0;

        .titleText {
          font-size: $FONT_24;
          font-family: $BARLOW_SEMIBOLD;
          padding-bottom: 18px;
          color: $SECONDARY;
        }
      }

      .modal-body {
        padding: 0;

        .inner_Modal {
          textarea {
            background: $TABLE_BG;
            resize: none;
            border-color: $TABLE_BG;
            border-radius: 10px !important;
          }
        }

        .holiday_Modal {
          .date_Section {
            position: relative;
            margin-bottom: 32px;

            input {
              width: 100%;
              height: 48px;
              background: $TABLE_BG;
              border: 1px solid $TABLE_BG;
              border-radius: 8px;
              padding: 0 16px;
            }

            .iconCalendar {
              position: absolute;
              right: 15px;
              top: 14px;
              font-size: $FONT_24;
            }
          }

          .customDropdown {
            width: 100%;
          }
        }
      }

      .modal-footer {
        border: none !important;
        padding: 0;

        .submitBtn {
          width: 173px;
          height: 48px;
          background: $PRIMARY !important;
          color: $TXT_WHITE !important;
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .blog_Page {
    
    .query {
      .data_List--Query {
        width: 30% !important;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .blog_Page {
    .center-blog-section {
      padding: 0px 120px;
    }
    .blog_Main {
      .card_Section {
        .card_Main {
          .img_Section {
            .blog_Img {
              &.displayDesktop {
                display: none;
              }

              &.displayIpad {
                display: block;
              }
            }

            .date_Section {
              width: 107px;

              .date {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }

          .content_Section {
            .heading {
              font-size: $FONT_16;
              line-height: 20px;
            }

            .inner_TxtSection {
              .titleText {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
      }
    }

    .faq_List {
      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }

    .query {
      .data_List--Query {
        width: 30% !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .blog_Page {
    .center-blog-section {
      padding: 0px 60px;
    }
    .blog_Main {
      .tab-content {
        .tab-pane {
          .common_Header {
            .search_Box {
              .SearchBox {
                width: 236px;
              }
            }

            .right_Section {
              .date_Section {
                width: 173px;
                margin-right: 15px;

                .react-datepicker-wrapper {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .card_Section {
        .card_Main {
          .img_Section {
            .blog_Img {
              &.displayDesktop {
                display: none;
              }

              &.displayIpad {
                display: block;
              }
            }

            .date_Section {
              width: 107px;

              .date {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }

          .content_Section {
            .heading {
              font-size: $FONT_16;
              line-height: 20px;
            }

            .inner_TxtSection {
              .titleText {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
      }

      .contact_Table {
        .header {
          li.date {
            width: 20%;
          }

          .combine {
            width: 38%;

            .combine_Li {
              li {
                &.phone {
                  display: none;
                }

                &.email {
                  display: none;
                }
              }
            }
          }

          li {
            &.date {
              width: 12%;
            }

            &.project {
              width: 34%;
            }
          }
        }

        .data_List {
          .combine {
            width: 38%;

            .combine_Li {
              flex-direction: column;

              li {
                width: 100% !important;
              }
            }
          }

          li {
            &.date {
              width: 20%;
            }

            &.project {
              width: 34%;
            }
          }
        }
      }
    }

    .faq_List {
      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {}
      }
    }

    .query {
      .data_List--Query {
        width: 40% !important;
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .blog_Page {

    .center-blog-section {
      padding: 25px 15px;
    }

    .blog_Main {
      .tab-content {
        .tab-pane {
          .common_Header {
            flex-direction: column;

            .search_Box {
              .SearchBox {
                width: 100%;
                margin-bottom: 24px;
              }
            }

            .right_Section {
              .date_Section {
                width: 193px;
                margin-right: 24px;

                .react-datepicker-wrapper {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .card_Section {
        display: table;

        .card_Main {
          width: 48%;
          float: left;
          margin-right: 2%;
          margin-bottom: 2%;

          &:nth-child(2n-2) {
            margin-right: 0;
          }

          .img_Section {
            .blog_Img {
              &.displayDesktop {
                display: none;
              }

              &.displayIpad {
                display: block;
              }
            }

            .date_Section {
              width: 107px;

              .date {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }

          .content_Section {
            .heading {
              font-size: $FONT_16;
              line-height: 20px;
            }

            .inner_TxtSection {
              .titleText {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
      }

      .contact_Table {
        .header {
          .combine {
            width: 55%;

            .combine_Li {
              li {
                &.name {
                  width: 100%;
                }

                &.phone {
                  display: none;
                }

                &.email {
                  display: none;
                }
              }
            }
          }

          li {
            &.date {
              width: 25%;
            }

            &.service {
              width: 25%;
            }

            &.project {
              display: none;
            }
          }
        }

        .data_List {
          .combine {
            width: 55%;

            .combine_Li {
              flex-direction: column;

              li {
                width: 100% !important;
              }
            }
          }

          li {
            &.date {
              width: 35%;
            }

            &.project {
              display: none;
            }

            &.service {
              width: 25%;
            }
          }
        }
      }
    }

    .faq_List {
      .accordion__Block {
        .accordion__faq {
          .accordion__Faq-heading {
            .accordion__Icon {
              .vertical {
                left: 12px !important;
              }
            }
          }
        }
      }

      .update__faq {
        .submitBtn {
          // width: 100% !important;
        }
      }
    }

    .query {
      .data_List--Query {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
  .blog_Page {
    padding: 25px 12px;

    .blog_Main {
      .tab-content {
        .tab-pane {
          .common_Header {
            flex-direction: column;

            .search_Box {
              .SearchBox {
                width: 100%;
                margin-bottom: 24px;
              }
            }

            .right_Section {
              flex-direction: column;

              .date_Section {
                width: 193px;
                margin-right: 15px;
                margin-bottom: 24px;

                &.marginR {
                  margin-right: 0;
                }

                .react-datepicker-wrapper {
                  width: 100%;
                }
              }

              .submitBtn {
                width: 100%;
              }
            }
          }
        }
      }

      .tab_Main_Section {
        .nav-item {
          padding-right: 30px;
        }
      }

      .tab_Main_Section.nav.nav-tabs {
        flex-wrap: nowrap;
      }

      .card_Section {
        display: table;

        .card_Main {
          width: 100%;
          margin-bottom: 2%;

          .img_Section {
            .blog_Img {
              &.displayDesktop {
                display: none;
              }

              &.displayIpad {
                display: block;
              }
            }

            .date_Section {
              width: 107px;

              .date {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }

          .content_Section {
            .heading {
              font-size: $FONT_16;
              line-height: 20px;
            }

            .inner_TxtSection {
              .titleText {
                font-size: $FONT_14;
                font-family: $BARLOW_REGULAR;
              }
            }
          }
        }
      }

      .contact_Table {
        .header {
          display: none;

          .combine {
            width: 55%;

            .combine_Li {
              li {
                &.name {
                  width: 100%;
                }

                &.phone {
                  display: none;
                }

                &.email {
                  display: none;
                }
              }
            }
          }

          li {
            &.date {
              width: 20%;
            }

            &.service {
              width: 25%;
            }

            &.project {
              display: none;
            }
          }
        }

        .data_List {
          flex-wrap: wrap;
          padding: 48px 81px 16px 16px !important;

          .combine {
            width: 65%;

            .combine_Li {
              flex-direction: column;

              li {
                width: 100% !important;

                &.name {
                  position: relative;
                  width: 66%;

                  &:before {
                    content: "Full Name";
                    position: absolute;
                    bottom: 36px;
                    font-size: $FONT_16;
                    font-family: $BARLOW_SEMIBOLD;
                    line-height: 10px;
                  }
                }

                &.email {
                  padding-bottom: 4px;
                }
              }
            }
          }

          li {
            &.date {
              position: relative;
              width: 30%;

              &:before {
                content: "Date";
                position: absolute;
                bottom: 72px;
                font-size: $FONT_16;
                font-family: $BARLOW_SEMIBOLD;
                line-height: 10px;
              }
            }

            // padding:4px;
            // width: 30%;

            &.project {
              width: 100%;
              padding-top: 44px;
              position: relative;

              &:before {
                content: "Project brief";
                position: absolute;
                top: 25px;
                font-size: $FONT_16;
                font-family: $BARLOW_SEMIBOLD;
                line-height: 10px;
              }
            }

            &.service {
              padding-top: 44px;
              // width: 25%;

              position: relative;
              width: 66%;

              &:before {
                content: "Service";
                position: absolute;
                top: 25px;
                font-size: $FONT_16;
                font-family: $BARLOW_SEMIBOLD;
                line-height: 10px;
              }
            }
          }
        }
      }

      .faq_Main {
        .accordion_Main {
          .accordion {
            .accordion-item {
              .dropodown_Faq {
                right: 0;
              }

              .accordion-header {
                .accordion-button {
                  font-size: $FONT_14;
                }
              }
            }
          }
        }

        .submitBtn {
          width: 100%;
        }
      }

      .faq_List {
        .accordion__Block {
          .accordion__faq {
            .accordion__Faq-heading {
              .accordion__Icon {
                .vertical {
                  left: 12px !important;
                }
              }
            }
          }
        }

        .update__faq {
          .submitBtn {
            width: 100% !important;
          }
        }
      }

      .query {
        .data_List--Query {
          width: 100% !important;
        }
      }
    }
  }
}