
@import "../../assests/scss/variables.scss";
.portfolio_Listing{
    position: relative;
    width: 100%;
    height: 100vh;
    float: left;
    background: $WHITE;
  .inner_Section{
    padding: 0px 120px !important;
  
    .tab_Main_Section {
        margin: 40px 0 !important;
        padding: 0px 120px !important;
        .nav-item {
          padding-right: 48px;
  
          &:nth-child(1) {
            padding-left: 15px;
          }
  
          .nav-link {
            position: relative;
            padding: 0;
            line-height: 24px;
            padding-bottom: 10px;
            color: $SECONDARY;
            border: 0;
  
            &.active {
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background: $PRIMARY;
                bottom: 0-1px;
                left: 0;
              }
            }
          }
        }
  
      }
      .tab-content{
        padding: 0px 120px !important;
      }
      .card_Section {
        display: flex;
        justify-content: space-between;
       
        .card_Main {
          width: 32.33%;
          padding-bottom:56px !important;
          .img_Section {
            width: 100%;
            position: relative;
  
            .blog_Img {
              width: 100%;
  
              &.displayIpad {
                display: none;
              }
            }
  
            .custom_Dropdown {
              position: absolute;
              top: 16px;
              right: 16px;
  
              button {
                width: 24px;
                height: 24px;
                border-radius: 50% !important;
                background-color: $SECONDARY !important;
                opacity: 0.5;
                border-color: transparent !important;
                display: flex;
                justify-content: center;
                align-items: center;
  
                &:after {
                  display: none;
                }
  
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
  
              .iconCss {
                color: $TXT_WHITE;
              }
  
              .dropdown-item {
                color: $TXT_BLACK;
                font-family: $BARLOW_REGULAR;
                font-size: $FONT_16;
                line-height: 24px;
                text-align: right;
                padding: 0;
                padding-bottom: 8px !important;
  
                &:hover {
                  background: transparent;
                }
  
                &.redC {
                  color: $RED;
                }
              }
  
              .dropdown-menu {
                border: none;
                padding: 8px 8px;
                padding-bottom: 0 !important;
                min-width: 94px;
                border-radius: 8px;
  
                &.show {
                  right: 0 !important;
                  left: unset !important;
                }
              }
            }
  
            .date_Section {
              background: $OFFLIGHTGREY;
              position: absolute;
              bottom: 16px;
              right: 16px;
              height: 37px;
              width: 109px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
  
              .date {
                color: $TXT_WHITE;
                text-align: center;
                font-size: $FONT_16;
                font-weight: $BARLOW_SEMIBOLD;
              }
            }
          }
  
          .content_Section {
            border: 1px solid $BORDER;
            border-top: 0;
            border-radius: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding: 0 16px;
            margin-top: -22px;
            padding-bottom: 16px;
  
            .main_Heading {
              color: $TXT_PLACEHOLDER;
              font-family: $BARLOW_SEMIBOLD;
              font-size: $FONT_14;
              line-height: 21px;
              padding-top: 32px;
              padding-bottom: 8px;
            }
  
            .heading {
              color: $SECONDARY;
              font-family: $BARLOW_REGULAR;
              font-size: $FONT_24;
              line-height: 26px;
              padding-bottom: 8px;
            }
  
            .inner_TxtSection {
              position: relative;
  
              .titleText {
                color: $TXT_GREY;
                font-size: $FONT_16;
                font-family: $BARLOW_MEDIUM;
                padding-bottom: 16px;
                line-height: 21px;
              }
  
              .read_More {
                position: absolute;
                bottom: -4px;
                font-size: $FONT_14;
                font-family: $BARLOW_SEMIBOLD;
                color: $TXT_PRIMARY;
              }
            }
          }
        }
      }
}
}
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .portfolio_Listing {
    .inner_Section {
      padding: 0 80px !important;
     
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
   .portfolio_Listing {
    .inner_Section {
      padding: 0 40px !important;
      .tab_Main_Section {
        padding: 0 !important;
      }
      .tab-content{
        padding:0 !important;
      }
    }
    .card_Section {
      .card_Main{
        width: 49% !important;
      }
    } 
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px){
  .portfolio_Listing {
    .inner_Section {
      padding: 0 40px !important;
      .tab_Main_Section {
        padding: 0 !important;
      }
      .tab-content{
        padding:0 !important;
      }
    }
    .card_Section {
      .card_Main{
        width: 49% !important;
      }
    } 
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px){
  .portfolio_Listing {
    .inner_Section {
      padding: 0 20px !important;
      .tab_Main_Section {
        padding: 0 !important;
      }
      .tab-content{
        padding:0 !important;
      }
    }
    .card_Section {
      .card_Main{
        width: 100% !important;
      }
    } 
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px){
  .portfolio_Listing {
    .inner_Section {
      padding: 0 20px !important;
      .tab_Main_Section {
        padding: 0 !important;
      }
      .tab-content{
        padding:0 !important;
      }
    }
    .card_Section {
      .card_Main{
        width: 100% !important;
      }
    } 
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px){
  .portfolio_Listing {
    .inner_Section {
      padding: 0 20px !important;
      .tab_Main_Section {
        padding: 0 !important;
      }
      .tab-content{
        padding:0 !important;
      }
    }
    .card_Section {
      .card_Main{
        width: 100% !important;
      }
    } 
  }
}