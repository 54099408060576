@import "../../assests/scss/variables.scss";

.leave_Page {
	position: relative;
	width: 100%;
	height: 100vh;
	float: left;
	background: $WHITE;
	padding: 25px 120px;
	overflow: hidden;

	.loginHeader {
		border-bottom: 1px solid #D9DBE9;
		background: $WHITE;

		.container {
			padding-bottom: 15px !important;
		}
	}

	.leave_Main {
		padding: 0 !important;
		height: calc(100vh - 60px);
		overflow: scroll;

		.leave_Center {
			padding-top: 40px;
		}

		.leave_Type {
			display: flex;
			flex-wrap: wrap;
			padding: 0 120px;

			.card_Width {
				width: 180px;
				margin-right: 24px;
				margin-bottom: 24px;
				height: 120px;
				justify-content: center;

				&:nth-child(6) {
					margin-right: 0;
				}
			}
		}

		.user_Details {
			padding-top: 22px;
			padding: 0 120px;
			display: flex;
			justify-content: space-between;

			.name_Section {
				display: flex;
				flex-direction: column;

				.common_Details {
					display: flex;
					align-items: baseline;

					.common {
						width: 90px;
						display: table;
						font-size: $FONT_16;
						font-family: $BARLOW_REGULAR;
					}

					.bold {
						font-size: $FONT_24;
						font-family: $BARLOW_SEMIBOLD;
						padding-left: 13px;
					}
				}
			}

			.button_Bottom {
				display: flex;
				align-items: flex-end;

				button {
					width: 129px;
					background: $PRIMARY !important;
					color: $TXT_WHITE !important;
					height: 48px;
				}
			}
		}

		.leave_Table {
			margin: 34px 120px !important;
			border: 1px solid $TABLE_BG;
			border-radius: 10px;
			background: $TABLE_BG;

			.header {
				display: flex;
				height: 58px;
				align-items: center;
				padding: 0 24px !important;

				li {
					width: 16%;
					padding-right: 10px;

					&.reason {
						width: 36%;
					}
				}
			}

			.data_List {
				display: flex;
				min-height: 58px;
				border-bottom: 1px solid $TABLE_BG;
				align-items: center;
				padding: 0 24px !important;
				background: $LIGHTBLUEBG;

				li {
					width: 16%;
					padding-right: 10px;
					font-size: $FONT_16;
					font-family: $BARLOW_REGULAR;

					&.reason {
						width: 36%;
					}
				}
			}
		}

		.apply_Leave_Section {
			margin-bottom: 40px;
			margin-top: 46px;

			.titleText {
				background: $WHITE;
				font-size: $FONT_24;
				font-family: $BARLOW_SEMIBOLD;
				color: $TXT_BLACK;
				width: fit-content;
				height: auto;
			}

			.date_Section {
				display: flex;
				justify-content: space-between;

				.date {
					width: 49%;
					position: relative;

					i {
						position: absolute;
						right: 20px;
						top: 15px;
					}

					.react-datepicker-wrapper {
						width: 100%;

						.react-datepicker__input-container {
							input {
								width: 100%;
								height: 48px;
								border: 1px solid $TABLE_BG;
								border-radius: 10px;
								padding: 0 26px;
								background: $TABLE_BG;

								&::-webkit-input-placeholder {
									/* Chrome/Opera/Safari */
									color: $TXT_GREY;
									font-size: $FONT_16;
									font-family: $BARLOW_REGULAR;
								}

								&::-moz-placeholder {
									/* Firefox 19+ */
									color: $TXT_GREY;
									font-size: $FONT_16;
									font-family: $BARLOW_REGULAR;
								}

								&:-ms-input-placeholder {
									/* IE 10+ */
									color: $TXT_GREY;
									font-size: $FONT_16;
									font-family: $BARLOW_REGULAR;
								}

								&:-moz-placeholder {
									/* Firefox 18- */
									color: $TXT_GREY;
									font-size: $FONT_16;
									font-family: $BARLOW_REGULAR;
								}

								&:focus {
									outline: none !important;
								}
							}
						}
					}
				}

			}

			textarea {
				margin-top: 24px;
				height: 150px;
				resize: none;
				border: 1px solid $TABLE_BG;
				border-radius: 10px;
				padding: 24px 26px;
				background: $TABLE_BG;

				&:focus {
					outline: none;
					box-shadow: none;
				}

				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: $TXT_GREY;
					font-size: $FONT_16;
					font-family: $BARLOW_REGULAR;
				}

				&::-moz-placeholder {
					/* Firefox 19+ */
					color: $TXT_GREY;
					font-size: $FONT_16;
					font-family: $BARLOW_REGULAR;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $TXT_GREY;
					font-size: $FONT_16;
					font-family: $BARLOW_REGULAR;
				}

				&:-moz-placeholder {
					/* Firefox 18- */
					color: $TXT_GREY;
					font-size: $FONT_16;
					font-family: $BARLOW_REGULAR;
				}
			}

			.attached_Upload {
				margin-top: 24px;
				width: 50%;
				position: relative;

				i {
					position: absolute;
					right: 25px;
					top: 14px;
					color: $SECONDARY;
					z-index: 111;
				}

				input {
					&::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						color: $TXT_GREY;
						font-size: $FONT_16;
						font-family: $BARLOW_REGULAR;
					}

					&::-moz-placeholder {
						/* Firefox 19+ */
						color: $TXT_GREY;
						font-size: $FONT_16;
						font-family: $BARLOW_REGULAR;
					}

					&:-ms-input-placeholder {
						/* IE 10+ */
						color: $TXT_GREY;
						font-size: $FONT_16;
						font-family: $BARLOW_REGULAR;
					}

					&:-moz-placeholder {
						/* Firefox 18- */
						color: $TXT_GREY;
						font-size: $FONT_16;
						font-family: $BARLOW_REGULAR;
					}
				}

				.file-upload-wrapper {
					color: $TABLE_BG;
					height: 48px;
					position: relative;
					width: 100%;

					&:after {
						content: attr(data-text);
						font-size: 18px;
						position: absolute;
						top: 0;
						left: 0;
						background: $TABLE_BG;
						display: block;
						pointer-events: none;
						z-index: 20;
						color: #999;
						border-radius: 5px 10px 10px 5px;
						font-weight: 300;
						width: 100%;
						height: 48px;
						padding: 10px 15px;
					}

					input {
						opacity: 0;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 99;
						height: 48px;
						margin: 0;
						padding: 0;
						display: block;
						cursor: pointer;
						width: 100%;
					}
				}
			}

			.leave_Bottom {
				display: flex;
				justify-content: flex-end;
				padding-top: 40px;

				.submitBtn {
					width: 281px;
					margin-right: 24px;
					border: 1px solid $BORDER !important;
					height: 60px;
					color: $TXT_BLACK !important;
					background: $TABLE_BG !important;

					&:nth-child(2) {
						background: $PRIMARY !important;
						color: $TXT_WHITE !important;
						border: none !important;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
	.leave_Page {
		.leave_Main {
			.leave_Type {
				padding: 0 10px;
			}
		}
	}
}

@media only screen and (min-width: 993px) and (max-width: 1025px) {
	.leave_Page {
		padding: 0 !important;

		.leave_Main {
			.leave_Type {
				padding: 0 10px;

				.card_Width {
					width: 173px;

					&:nth-child(5) {
						margin-right: 0;
					}
				}
			}
			.leave_Table {
				margin: 34px 10px !important;
	
			}
			.user_Details{
				padding: 0 10px;
			}
		}
		
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.leave_Page {
		padding:0 !important;

		.leave_Main {
			.leave_Type {
				padding: 0 10px;

				.card_Width {
					width: 164px;
					margin-right: 15px;

				}
			}

			.leave_Table {
			    margin: 34px 25px !important;

			}
			.user_Details{
				padding: 0 25px;
			}
		}
	}
}

@media only screen and (min-width:575px) and (max-width: 767px) {
	.leave_Page {
		padding:0;

		.leave_Main {
			.leave_Type {
				padding: 0 10px;

				.card_Width {
					width: 22%;
					margin-right: 15px;

					&:nth-child(4) {
						margin-right: 0;
					}
				}
			}

			.leave_Table {
				border-radius: 0;
				border: 0 !important;
				background: $WHITE;
				margin: 0 10px !important;
				.header {
					display: none;
				}

				.data_List {
					min-height: unset;
					padding: 0 !important;
					flex-wrap: wrap;
					margin-bottom: 16px !important;
					background: $WHITE;
					border-color: $BORDER;

					li {
						padding-top: 18px;
						padding-bottom: 20px;
						width: 33.33%;

						&.date {
							position: relative;

							&:before {
								content: 'Start Date';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}

							&:nth-child(2) {
								&:before {
									content: 'End Date';
								}
							}
						}

						&.leave {
							position: relative;

							&:before {
								content: 'Leave type';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}
						}

						&.reason {
							position: relative;
							width: 66%;

							&:before {
								content: 'Reason';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}
						}

						&.status {
							position: relative;

							&:before {
								content: 'Status';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;
							}
						}
					}
				}
			}
			.user_Details{
				padding: 22px 20px !important;
			}

		}
	}
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
	.leave_Page {
		padding: 25px 12px;

		.leave_Main {
			.leave_Type {
				justify-content: center;
				padding: 0 16px;
				.card_Width {
					width: 182px;
					margin-right: 15px;

					&:nth-child(2n-2) {
						margin-right: 0;
					}
				}
			}

			.user_Details {
				flex-direction: column;
				padding: 22px 16px;
				.name_Section {
					padding-bottom: 36px;
				}

				.button_Bottom {
					button {
						width: 100%;
					}
				}
			}

			.leave_Table {
				border-radius: 0;
				border: 0 !important;
				background: $WHITE;
				margin: 0 16px !important;
				padding-top: 30px;
				.header {
					display: none;
				}

				.data_List {
					min-height: unset;
					padding: 0 !important;
					flex-wrap: wrap;
					margin-bottom: 16px !important;
					background: $WHITE;
					border-color: $BORDER;

					li {
						padding-top: 18px;
						padding-bottom: 20px;
						width: 33.33%;

						&.date {
							position: relative;

							&:before {
								content: 'Start Date';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}

							&:nth-child(2) {
								&:before {
									content: 'End Date';
								}
							}
						}

						&.leave {
							position: relative;

							&:before {
								content: 'Leave type';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}
						}

						&.reason {
							position: relative;
							width: 66%;

							&:before {
								content: 'Reason';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;

							}
						}

						&.status {
							position: relative;

							&:before {
								content: 'Status';
								position: absolute;
								top: 0;
								font-size: $FONT_16;
								font-family: $BARLOW_SEMIBOLD;
								line-height: 10px;
							}
						}
					}
				}
			}

			.apply_Leave_Section {
				.date_Section {
					flex-direction: column;

					.date {
						width: 100%;
						margin-bottom: 32px;
					}
				}

				textarea {
					margin-top: 0;
				}

				.attached_Upload {
					width: 100%;
				}

				.leave_Bottom {
					flex-direction: column;

					.submitBtn {
						width: 100%;
						margin-bottom: 24px;
					}
				}
			}
		}
	}
}