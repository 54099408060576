@import "../../assests/scss/variables.scss";
.downloadCertificate {
    .verifyContainer {
        width:100%;
    .verifyContainer_inner {
        background-image: url("../../assests/img/homebg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 55px;
        
        .centerDiv {
          display: flex;
          justify-content: center;
          align-items: center;
          
          .certificateContainer {
            padding-bottom: 60px;
            
            .date {
              .react-datepicker__input-container input {
                height: 56px;
                padding: 16px 24px;
                background: $WHITE;
              }
            }
            .verifyTitle {
              padding-bottom: 16px;
              font-family: $BARLOW_BLACK;
              font-style: normal;
              font-weight: 900;
              font-size: $FONT_60;
              line-height: 150%;
              letter-spacing: 0.1em;
              color: $TXT_WHITE;
              text-align: center;
              display: block;
            }
            .verifyPara {
              padding-bottom: 40px;
              font-family: $BARLOW_REGULAR;
              font-style: normal;
              font-weight: normal;
              font-size: $FONT_24;
              line-height: 150%;
              text-align: center;
              color: $TXT_WHITE;
              width:55%;
              margin:auto;
            }
            .form {
              .form1 {
                width:40.83%;
                margin:auto;
                .textField .form-control {
                  background: $TXT_WHITE;
                  color: $TXT_LIGHT;
                  font-size: $FONT_16;
                  padding: 16px 24px;
                  height: 56px;
                  outline: 0px;
                  width: 100%;
                  border-radius: 10px;
                  border: 1px solid $TXT_GREY;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                }
              }
              .verify_Btn {
                .submitBtn {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 16px 24px;
                  margin-top: 40px;
                  height: 56px;
                  color: $TXT_WHITE !important;
                  background: $TXT_PRIMARY !important;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
    .certificateBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .congrats {
            .congratsText {
                text-align: center;
                font-size: 4vw;
                font-family: Sacramento;
            }
            img {
                margin-top: 3px;
                margin-bottom: 40px;
            }
        }
        .downloadButton {
            margin-bottom: 228px;
            .btn-primary {
                display: unset;
                padding: 16px 24px;
                margin-top: 40px;
                height: 56px;
                color: $TXT_WHITE !important;
                background: $TXT_PRIMARY !important;
                border-radius: 10px;
                width: 46vw;
            }
        }
    }
}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .downloadCertificate {
        .certificateBox {
            .congrats {
                margin-top: 45px;
                margin-left: 18px;
                margin-right: 18px;
                .congratsText {
                    margin-bottom: 58px;
                }
                img {
                    margin-bottom: 17px;
                }
            }
            .downloadButton {
                margin-top: 48px;
                margin-bottom: 200px !important;
                .btn-primary {
                    width: 72vw;
                }
            }
            img.certificate_Img {
                width: 80%;
            }
            .info_Details .name {
                top: 42.5%;
                left: 44%;
            }
            .program {
                top: 59%;
                left: 23%;
            }
        }
    }
}



@media only screen and (min-width: 576px) and (max-width: 991px) {
    .downloadCertificate {
        .verifyContainer {
        .verifyContainer_inner {
            .centerDiv {
              .certificateContainer {
                width: 91%;
                .date {
                  .react-datepicker__input-container input {
                    padding: 14px 24px;
                    height: 48px;
                    border-radius: 6px;
                  }
                }
                .verifyTitle {
                  font-size: $FONT_48;
                  font-family: $BARLOW_BLACK;
                  padding-bottom: 8px;
                }
                .verifyPara {
                  font-size: $FONT_16;
                  font-family: $BARLOW_REGULAR;
                  padding-bottom: 24px;
                  width:100%;
                }
      
                .form {
                  .form1 {
                    width:80%;
                    .textField {
                      margin-bottom: 20px;
                      .form-control {
                        padding: 14px 24px;
                        height: 48px;
                        border-radius: 6px;
                      }
                    }
                  }
                  .verify_Btn {
                    .submitBtn {
                      padding: 14px 24px;
                      height: 48px;
                      border-radius: 6px !important;
                      margin-top: 32px;
                    }
                  }
                }
              }
            }
          }
        .certificateBox {
            .congrats {
                margin-top: 0px;
                margin-left: 18px;
                margin-right: 18px;
                .congratsText {
                    margin-bottom: 24px;
                }
                img {
                    margin-bottom: 17px;
                }
            }
            .downloadButton {
               
                margin-bottom: 200px !important;
                .btn-primary {
                    width: 74vw;
                    margin-top: 32px !important;
                }
            }
            img.certificate_Img {
                width: 80%;
            }
            .info_Details .name {
                top: 43%;
                left: 44%;
            }
            .program {
                top: 59.5%;
                left: 23%;
            }
        }
    }
}
}
@media only screen and (min-width: 300px) and (max-width: 575px) {
    .downloadCertificate {
        .verifyContainer {
        .verifyContainer_inner {
            .centerDiv {
              .certificateContainer {
                width: 91%;
                .date {
                  .react-datepicker__input-container input {
                    padding: 14px 24px;
                    height: 48px;
                    border-radius: 6px;
                  }
                }
                .verifyTitle {
                  font-size: $FONT_30;
                  font-family: $BARLOW_BLACK;
                  padding-bottom: 8px;
                }
                .verifyPara {
                  font-size: $FONT_14;
                  padding-bottom: 24px;
                  width:100%;
                }
      
                .form {
                  .form1 {
                    width:100%;
                    .textField {
                      margin-bottom: 20px;
                      .form-control {
                        padding: 14px 24px;
                        height: 48px;
                        border-radius: 6px;
                      }
                    }
                  }
                  .verify_Btn {
                    .submitBtn {
                      padding: 14px 24px;
                      height: 48px;
                      border-radius: 6px !important;
                      margin-top: 32px;
                    }
                  }
                }
              }
            }
          }

        .certificateBox {
            .congrats {
                margin-top: 0px;
                margin-left: 18px;
                margin-right: 18px;
                .congratsText {
                    margin-bottom: 24px;
                }
                img {
                    margin-bottom: 17px;
                }
            }
            .downloadButton {
               
                margin-bottom: 200px !important;
                .btn-primary {
                    width: 80vw;
                    margin-top: 24px !important;
                }
            }
            img.certificate_Img {
                width: 90%;
            }
            .info_Details .name {
                top: 43.5%;
                left: 44%;
            }
            .program {
                top: 60%;
                left: 23%;
            }
        }
    }
}
}
@media only screen and (min-width: 0px) and (max-width: 299px) {
    .downloadCertificate {
        display: none;
    }
}
