@import "../../assests/scss/variables.scss";

.blog_listingPage {
  background: $WHITE;
  .blog_Inner {
    padding: 118px 120px 200px 120px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;

    .page_Head {
      .smallText {
        font-size: $FONT_14;
        font-family: $BARLOW_SEMIBOLD;
        color: $TXT_PLACEHOLDER;
      }

      .heading {
        font-size: $FONT_32;
        font-family: $BARLOW_SEMIBOLD;
        width: 486px;
      }
    }

    .innerContent {
      font-size: $FONT_24;
      font-family: $BARLOW_SEMIBOLD;
      color: $TXT_GREY;
      margin-bottom: 58px;
    }

    .center_Section {
      margin: 0 auto;
      box-shadow: 0px 3px 24px 8px rgba(0, 0, 0, 0.07);
      margin-top: 88px;

      .margin_Section {
        padding: 80px 204px;

        .heading {
          font-size: $FONT_32;
          font-family: $BARLOW_SEMIBOLD;
          padding: 0 109px;
        }

        .heading_Ul {
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
        }

        .innerText {
          font-size: $FONT_16;
          font-family: $BARLOW_MEDIUM;
          color: $TXT_GREY;
          margin-bottom: 40px;
        }

        .ul_Li {
          .heading {
            font-size: $FONT_16;
            font-family: $BARLOW_SEMIBOLD;
            margin-bottom: 24px;
          }

          ul {
            padding-left: 15px !important;

            li {
              font-size: $FONT_16;
              font-family: $BARLOW_MEDIUM;
              padding-bottom: 16px;
              color: $TXT_GREY;
              position: relative;

              &:before {
                width: 4px;
                height: 4px;
                background: $TXT_GREY;
                position: absolute;
                content: "";
                border-radius: 50%;
                top: 11px;
                left: -14px;
              }
            }
          }
        }
      }

      .content {
        padding: 32px 83px;

        .heading {
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          margin-bottom: 16px;
        }

        .innerText {
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 83px 15px;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 83px 15px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 83px 15px;

      .page_Head {
        .heading {
          font-size: $FONT_24;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: 900px;

        .margin_Section {
          padding: 32px 62px;

          .heading {
            padding: 0 40px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 83px 15px;

      .page_Head {
        .heading {
          font-size: $FONT_24;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: 631px;

        .margin_Section {
          padding: 32px 62px;

          .heading {
            padding: 0 40px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 53px 15px;

      .page_Head {
        .heading {
          font-size: $FONT_24;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: 631px;

        .margin_Section {
          padding: 32px 62px;

          .heading {
            padding: 0 40px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 53px 15px;

      .page_Head {
        .heading {
          font-size: $FONT_24;
          width: auto;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: auto;
        margin-top: 60px;

        .margin_Section {
          padding: 32px 62px;

          .heading {
            padding: 0;
          }
        }

        .content {
          padding: 32px 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .blog_listingPage {
    .blog_Inner {
      padding: 53px 15px;

      .page_Head {
        .heading {
          font-size: $FONT_24;
          width: auto;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: auto;
        margin-top: 60px;

        .margin_Section {
          padding: 0;

          .heading {
            padding: 0;
            font-size: $FONT_20;
          }
        }

        .content {
          padding: 32px 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .blog_Inner {
    padding: 83px 15px;

    .blog_listingPage {
      .page_Head {
        .heading {
          font-size: $FONT_24;
          width: auto;
        }
      }

      .innerContent {
        font-size: $FONT_16;
        margin-bottom: 32px;
      }

      .center_Section {
        width: auto;
        margin-top: 60px;

        .margin_Section {
          padding: 0;

          .heading {
            padding: 0;
            font-size: $FONT_20;
          }
        }

        .content {
          padding: 32px 25px;
        }
      }
    }
  }

}