@import "../../assests/scss/variables.scss";

.bloglisting_Page {
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
  background: $WHITE;
  padding: 0;
  display: table;

  .center-blog-section {
    padding: 0px 120px;
  }

  .headerImg {
    padding-top: 88px;
    display: flex;
    padding-bottom: 64px;
    border-bottom: 1px solid $BORDER;
    margin-bottom: 56px;

    .content_Section {
      padding-left: 24px;

      .main_Heading {
        color: $TXT_PLACEHOLDER;
        font-family: $BARLOW_SEMIBOLD;
        font-size: $FONT_14;
        line-height: 21px;
        padding: 0;
      }

      .heading {
        color: $TXT_PLACEHOLDER;
        font-family: $BARLOW_SEMIBOLD;
        font-size: $FONT_32;
        line-height: 44px;
        padding-bottom: 11px;
        color: $SECONDARY;
      }

      .inner_Txt {
        margin-bottom: 0;
      }

      .inner_TxtSection {
        position: relative;

        .titleText {
          color: $TXT_GREY;
          font-size: $FONT_16;
          font-family: $BARLOW_MEDIUM;
          padding-bottom: 16px;
          line-height: 24px;
        }

        .read_More {
          font-size: $FONT_14;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_PRIMARY;
          padding-left: 4px;
        }
      }
    }
  }

  .card_Section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .card_Main {
      width: 32.33%;
      padding-bottom: 16px;

      .img_Section {
        width: 100%;
        position: relative;

        .blog_Img {
          width: 100%;

          &.displayIpad {
            display: none;
          }
        }

        .custom_Dropdown {
          position: absolute;
          top: 16px;
          right: 16px;

          button {
            width: 24px;
            height: 24px;
            border-radius: 50% !important;
            background-color: $SECONDARY !important;
            opacity: 0.5;
            border-color: transparent !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
              display: none;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .iconCss {
            color: $TXT_WHITE;
          }

          .dropdown-item {
            color: $TXT_BLACK;
            font-family: $BARLOW_REGULAR;
            font-size: $FONT_16;
            line-height: 24px;
            text-align: right;
            padding: 0;
            padding-bottom: 8px !important;

            &:hover {
              background: transparent;
            }

            &.redC {
              color: $RED;
            }
          }

          .dropdown-menu {
            border: none;
            padding: 8px 8px;
            padding-bottom: 0 !important;
            min-width: 94px;
            border-radius: 8px;

            &.show {
              right: 0 !important;
              left: unset !important;
            }
          }
        }

        .date_Section {
          background: $OFFLIGHTGREY;
          position: absolute;
          bottom: 16px;
          right: 16px;
          height: 37px;
          width: 109px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          .date {
            color: $TXT_WHITE;
            text-align: center;
            font-size: $FONT_16;
            font-weight: $BARLOW_SEMIBOLD;
          }
        }
      }

      .content_Section {
        border: 1px solid $BORDER;
        border-top: 0;
        border-radius: 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0 16px;
        margin-top: -22px;
        padding-bottom: 16px;

        .main_Heading {
          color: $TXT_PLACEHOLDER;
          font-family: $BARLOW_SEMIBOLD;
          font-size: $FONT_14;
          line-height: 21px;
          padding-top: 32px;
          padding-bottom: 8px;
        }

        .heading {
          color: $TXT_PLACEHOLDER;
          font-family: $BARLOW_REGULAR;
          font-size: $FONT_24;
          line-height: 26px;
          padding-bottom: 8px;
        }

        .inner_TxtSection {
          position: relative;

          .titleText {
            color: $TXT_GREY;
            font-size: $FONT_16;
            font-family: $BARLOW_MEDIUM;
            padding-bottom: 16px;
            line-height: 21px;
          }

          .read_More {
            font-size: $FONT_14;
            font-family: $BARLOW_SEMIBOLD;
            color: $TXT_PRIMARY;
            padding-left: 4px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .bloglisting_Page {
    padding: 25px 15px;

    .card_Section {
      .card_Main {
        .img_Section {
          .blog_Img {
            &.displayDesktop {
              display: none;
            }

            &.displayIpad {
              display: block;
            }
          }

          .date_Section {
            width: 107px;

            .date {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }

        .content_Section {
          .heading {
            font-size: $FONT_16;
            line-height: 20px;
          }

          .inner_TxtSection {
            .titleText {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .bloglisting_Page {
    padding: 25px 15px;

    .headerImg {
      flex-direction: column;
      padding-top: 28px;
      padding-bottom: 30px;
      margin-bottom: 30px;

      .content_Section {
        padding-left: 0;
        padding-top: 30px;
      }
    }

    .card_Section {
      .card_Main {
        width: 49%;

        .img_Section {
          .blog_Img {
            &.displayDesktop {
              display: none;
            }

            &.displayIpad {
              display: block;
            }
          }

          .date_Section {
            width: 107px;

            .date {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }

        .content_Section {
          .heading {
            font-size: $FONT_16;
            line-height: 20px;
          }

          .inner_TxtSection {
            .titleText {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .bloglisting_Page {
    padding: 25px 15px;

    .headerImg {
      flex-direction: column;
      padding-top: 28px;
      padding-bottom: 30px;
      margin-bottom: 30px;

      .content_Section {
        padding-left: 0;
        padding-top: 30px;
      }
    }

    .card_Section {
      .card_Main {
        width: 49%;

        .img_Section {
          .blog_Img {
            &.displayDesktop {
              display: none;
            }

            &.displayIpad {
              display: block;
            }
          }

          .date_Section {
            width: 107px;

            .date {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }

        .content_Section {
          .heading {
            font-size: $FONT_16;
            line-height: 20px;
          }

          .inner_TxtSection {
            .titleText {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
  .bloglisting_Page {
    padding: 25px 15px;

    .headerImg {
      flex-direction: column;
      padding-top: 28px;
      padding-bottom: 30px;
      margin-bottom: 30px;

      .content_Section {
        padding-left: 0;
        padding-top: 30px;
      }
    }

    .card_Section {
      .card_Main {
        width: 100%;

        .img_Section {
          .blog_Img {
            &.displayDesktop {
              display: none;
            }

            &.displayIpad {
              display: block;
            }
          }

          .date_Section {
            width: 107px;

            .date {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }

        .content_Section {
          .heading {
            font-size: $FONT_16;
            line-height: 20px;
          }

          .inner_TxtSection {
            .titleText {
              font-size: $FONT_14;
              font-family: $BARLOW_REGULAR;
            }
          }
        }
      }
    }
  }
}