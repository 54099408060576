@import "../../assests/scss/variables.scss";
.allCertificate {
  .allCertificate_inner {
    padding-top: 25px;
    .centerDiv {
      .certificateContainer {
        line-height: 150%;
        .allTitle {
          padding-bottom: 16px;
          font-family: $BARLOW_BLACK;
          font-style: normal;
          font-weight: 900;
          font-size: $FONT_60;
          letter-spacing: 0.1em;
          color: $TXT_PRIMARY;
          text-align: center;
          display: block;
        }
        .tab {
          font-weight: 600;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          background-color: $TABLE_BG;
          color: $SECONDARY;
          padding: 15px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
        }
        .tableData {
          .customers {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            background-color: $LIGHTBLUEBG;
            color: $TXT_BLACK;
            padding: 15px;
            font-size: $FONT_16;
            border: 1px solid $BORDER;
            font-weight: 400;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .allCertificate {
    .allCertificate_inner {
      .centerDiv {
        .certificateContainer {
          .allTitle {
            font-size: $FONT_40;
          }
          .tab {
          }
          .tableData {
            .customers {
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .allCertificate {
    .allCertificate_inner {
      .centerDiv {
        .certificateContainer {
          .allTitle {
            font-size: $FONT_36;
          }
          .tab {
            font-size: $FONT_16;
          }
          .tableData {
            .customers {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .allCertificate {
    .allCertificate_inner {
      .centerDiv {
        .certificateContainer {
          .allTitle {
            font-size: $FONT_32;
          }
          .tab {
            font-size: $FONT_16;
          }
          .tableData {
            .customers {
              font-size: $FONT_10;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .allCertificate {
    .allCertificate_inner {
      .centerDiv {
        .certificateContainer {
          .allTitle {
            font-size: $FONT_32;
          }
          .tab {
            font-size: $FONT_12;
          }
          .tableData {
            .customers {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 299px) {
  .allCertificate {
    display: none;
  }
}
