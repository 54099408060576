@import "../../assests/scss/variables.scss";

.submitBtn {
	background: $WHITE !important;
	width: 100%;
	border: none !important;
	height: 40px;
	font-size: 16px;
	font-family: $BARLOW_MEDIUM;
	border-radius: 8px !important;
	cursor: pointer;
	color: $TXT_PRIMARY !important;


	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}

	&.active {
		box-shadow: none !important;
	}

}