@import "../../assests/scss/variables.scss";

.CaseStudy_section {
  .fintech {
    margin: 118px 0px 80px 120px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 150%;
    color: #ff001d;
  }

  .fact {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #141414;
  }

  .paraDefine {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #141414;
    // margin-bottom:100px;
    margin: 40px 222px 100px 222px;
  }

  .blackBox {
    margin: 100px 120px 0px 120px;
    display: flex;
    background: #141414;
    border-radius: 20px;

    .case {
      padding: 40px 24px 40px 40px;
    }

    .blackBox_text {
      padding: 114px 40px 116px 0px;

      h2 {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 150%;
        color: #ffffff;
      }

      p {
        padding-top: 24px;
        font-family: "Barlow";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }

  .passage {
    margin: 60px 120px 80px 120px;

    .titlePassage {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      color: #141414;
    }

    .textPassage {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #6e7191;
    }
  }

  .lastTextBox {
    display: flex;
    justify-content: center;
    margin: 0px 120px 200px 120px;

    .textBox {
      width: 60%;

      .titlePassage {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: #141414;
      }

      .textPassage {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #6e7191;
        padding-right: 24px;
      }
    }

    .box {
      padding: 28px 13px 28px 13px;
      width: 40%;
      background: #f7f7fc;
      border-radius: 10px;

      .heading {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #141414;
      }

      .para {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #6e7191;
      }
    }
  }
}

.firstCard {
  display: flex;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;
  margin: 100px 222px 0px 222px;
  border-radius: 20px;

  .imgSpace {
    padding: 70px 32px 70px 102px;
    filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.2));
    border-radius: 20px;
  }

  .textSpace {
    padding: 147px 120px 147px 0px;

    .title {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;

      color: #141414;
    }

    .para {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      color: #6e7191;
    }
  }
}
.secondCard {
  display: flex;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;
  margin: 100px 222px 0px 222px;
  border-radius: 20px;

  .imgSpace {
    padding: 70px 32px 70px 102px;
    filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.2));
    border-radius: 20px;
  }

  .textSpace {
    padding: 147px 120px 147px 0px;

    .title {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;

      color: #141414;
    }

    .para {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      color: #6e7191;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .CaseStudy_section {
    .firstCard {
      display: flex;
      background-color: #f7f7f7;
      justify-content: center;
      align-items: center;
      margin: 100px 120px 0px 120px;
      border-radius: 20px;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .CaseStudy_section {
    .fintech {
      margin: 82px 13px 16px 15px;
    }

    .paraDefine {
      margin: 40px 96px 0px 96px;
    }

    .blackBox {
      margin: 80px 15px 40px 15px;
      display: flex;

      .case {
        padding: 40px 24px 40px 40px;
      }

      .blackBox_text {
        padding: 114px 40px 116px 0px;
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 15px 200px 15px;

      .textBox {
        width: 50%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 50%;
      }
    }
  }

  .firstCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
  .secondCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .CaseStudy_section {
    .fintech {
      margin: 82px 13px 16px 15px;
    }

    .paraDefine {
      margin: 40px 96px 0px 96px;
    }

    .blackBox {
      margin: 80px 15px 40px 15px;
      display: flex;

      .case {
        padding: 40px 24px 40px 40px;
      }

      .blackBox_text {
        padding: 114px 40px 116px 0px;
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 15px 200px 15px;

      .textBox {
        width: 50%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 50%;
      }
    }
  }

  .firstCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
  .secondCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .CaseStudy_section {
    .fintech {
      margin: 82px 13px 16px 15px;
    }

    .paraDefine {
      margin: 40px 96px 0px 96px;
    }

    .blackBox {
      margin: 80px 15px 40px 15px;
      display: flex;

      .case {
        padding: 40px 24px 40px 40px;
      }

      .blackBox_text {
        padding: 114px 40px 116px 0px;
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 15px 200px 15px;

      .textBox {
        width: 50%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 50%;
      }
    }
  }

  .firstCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
  .secondCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 70px 32px 70px 102px;
    }

    .textSpace {
      padding: 147px 120px 147px 0px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .CaseStudy_section {
    .fintech {
      font-weight: 600;
      font-size: 32px;
      margin: 82px 13px 16px 15px;
    }

    .fact {
      font-weight: 600;
      font-size: 16px;
    }

    .paraDefine {
      margin: 40px 96px 0px 96px;
      font-weight: 400;
      font-size: 16px;
    }

    .blackBox {
      margin: 80px 15px 40px 15px;
      display: flex;

      .case {
        padding: 16px 24px 16px 16px;
      }

      .blackBox_text {
        padding: 31px 24px 98px 0px;

        h2 {
          font-size: 16px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 15px 200px 15px;

      .textBox {
        width: 60%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 40%;
      }
    }
  }

  .firstCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 62px 63px 97px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .secondCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 62px 63px 97px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .CaseStudy_section {
    .fintech {
      font-weight: 600;
      font-size: 32px;
      margin: 82px 13px 16px 15px;
    }

    .fact {
      font-weight: 600;
      font-size: 16px;
    }

    .paraDefine {
      margin: 40px 96px 0px 96px;
      font-weight: 400;
      font-size: 16px;
    }

    .blackBox {
      margin: 80px 15px 40px 15px;
      display: flex;
      flex-wrap: wrap;

      .case {
        padding: 16px 24px 16px 16px;
      }

      .blackBox_text {
        padding: 31px 16px 98px 16px;

        h2 {
          font-size: 16px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 15px 200px 15px;

      .textBox {
        width: 60%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 40%;
      }
    }
  }

  .firstCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 62px 63px 97px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .secondCard {
    margin: 40px 13px 0px 15px !important;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 62px 63px 97px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .CaseStudy_section {
    .fintech {
      font-weight: 500;
      font-size: 24px;
      margin: 64px 16px 0px 16px;
    }

    .fact {
      font-weight: 500;
      font-size: 16px;
    }

    .paraDefine {
      margin: 40px 16px 0px 16px;
      font-weight: 400;
      font-size: 16px;
    }

    .blackBox {
      margin: 56px 16px 32px 16px;
      display: flex;
      flex-wrap: wrap;

      .case {
        padding: 16px 16px 32px 16px;

        img {
          width: 100%;
        }
      }

      .blackBox_text {
        padding: 32px 16px 24px 16px;

        h2 {
          font-weight: 700;
          font-size: 24px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 16px 176px 16px;
      flex-wrap: wrap;

      .textBox {
        width: 100%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 100%;
      }
    }
  }

  .firstCard {
    margin: 34px 16px 0px 16px !important;
    flex-wrap: wrap;
    text-align: center;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 32px 24px 40px 24px;

      .title {
        font-weight: 700;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .secondCard {
    margin: 34px 16px 0px 16px !important;
    flex-wrap: wrap;
    text-align: center;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 32px 24px 40px 24px;

      .title {
        font-weight: 700;
        font-size: 24px;
      }

      .para {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .CaseStudy_section {
    .fintech {
      font-weight: 500;
      font-size: 24px;
      margin: 64px 16px 0px 16px;
    }

    .fact {
      font-weight: 500;
      font-size: 16px;
    }

    .paraDefine {
      margin: 40px 16px 0px 16px;
      font-weight: 400;
      font-size: 16px;
    }

    .blackBox {
      margin: 56px 16px 32px 16px;
      display: flex;
      flex-wrap: wrap;

      .case {
        padding: 16px 16px 32px 16px;

        img {
          width: 100%;
        }
      }

      .blackBox_text {
        padding: 32px 16px 24px 16px;

        h2 {
          font-weight: 700;
          font-size: 24px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .passage {
      margin: 0px 15px 48px 15px;
    }

    .lastTextBox {
      margin: 0px 16px 176px 16px;
      flex-wrap: wrap;

      .textBox {
        width: 100%;
      }

      .box {
        padding: 28px 13px 28px 13px;
        width: 100%;
      }
    }
  }

  .firstCard {
    margin: 34px 16px 0px 16px !important;
    flex-wrap: wrap;
    text-align: center;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 32px 24px 0px 24px;

      .title {
        font-weight: 700;
        font-size: 16px;
        text-align: left;
      }

      .para {
        font-weight: 400;
        font-size: 16px;
        text-align: left;
      }
    }
  }
  .secondCard {
    margin: 34px 16px 0px 16px !important;
    flex-wrap: wrap;
    text-align: center;

    .imgSpace {
      padding: 40px 15px 40px 27px;
    }

    .textSpace {
      padding: 32px 24px 0px 24px;

      .title {
        font-weight: 700;
        font-size: 16px;
      }

      .para {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .CaseStudy_section {
    display: none;
  }
}


 


