@import "../../assests/scss/variables.scss";


.service_Page {
  background-image: url("../../assests/img/homebg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 964px;
  position: relative;
  .banner_Text_Img{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding: 0 70px;
    margin-bottom: 91px;
    .inline_Img{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    img{
      // width: 470px;
      &.img_Right{
        // padding-top: 70px;
        width: 500px;
      }
    }
    .banner_Text{
      width: 474px;
      padding-left: 44px;
      .titleText {
        font-size: $FONT_16;
        color: $TXT_WHITE !important;
        line-height: 24px;
        padding-bottom: 0;
        &.main_Heading{
          font-size: 60px;
          line-height: 90px;
          padding-bottom: 4px;
        }
      }
    }
    
  }
 .content_Img{
   display: flex;
   padding: 74px 120px 0 120px;
   justify-content: space-between;
   .width50{
     width: 49%;
     .main_Title{
      font-size: $FONT_32;
      font-family: $BARLOW_BOLD;
      color:$SECONDARY;
    }
    .inner_Title{
      font-size: $FONT_16;
      font-family: $BARLOW_REGULAR;
      color:$SECONDARY;
      padding-bottom: 48px;
    }
     img{
      width: 100%;
      
     }
     .skill_Section{
       display: flex;
       
       .small50{
         width: 50%;
         .titleText{
          position: relative;
           color: $SECONDARY;
           font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-left: 16px;
            line-height: 24px;
            padding-bottom: 12px;
           &:before{
             content: '';
             width: 5px;
             height: 5px;
             border-radius: 100%;
             background: $SECONDARY;
             position: absolute;
             left:0;
             top:8px;
           }
         }
       }
     }
   }
 }

  .contact_Section{
    display: flex;
    padding: 80px 120px;
    flex-direction: column;
    background: $LIGHTBLUEBG;
    margin-top: 154px;
    .content_Section {
      display: flex;
      align-self: center;
      width: 100%;
      flex-direction: row;
      padding-bottom: 85px;
      .titleText {
        font-size: $FONT_48;
        font-family: $BARLOW_MEDIUM;
        padding: 0;
        padding-right: 120px;
        align-self: center;
        &:last-child {
          width: 570px;
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $SECONDARY;
          position: relative;
          padding-left: 16px;
          padding-right: 0;
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: $SECONDARY;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .card_Section {
      form{
      background: $WHITE;
      padding: 44px 32px;
      width: 100%;
      display: table;
      .input_Section{
        display: flex;
        justify-content: space-between;
        .input{
          width: 49%;
          padding-bottom: 40px;
          position: relative;
          span{
            position: absolute;
            top: -3px;
            left: 161px;
            &.left200{
              left: 201px;
            }
            &.left300{
              left: 189px;
            }
          }
          .form-control{
            border: none;
            border-bottom: 1px solid $LIGHTBORDER;
            border-radius: 0;
            padding:0;
            padding-bottom: 12px;
          
            &:focus{
              outline: none;
              box-shadow: none;
            }
            &::-webkit-input-placeholder { /* Edge */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
            
            &::placeholder {
              color: $TXT_PLACEHOLDER;
              font-size: $FONT_16;
            }
          }
        }
        .phone_Section{
          width: 49%;
         .PhoneInput{
            border-bottom: 1px solid $LIGHTBORDER;
            padding-bottom: 12px;
            .PhoneInputInput{
              &:focus{
                outline: none;
                box-shadow: none;
              }
              &::-webkit-input-placeholder { /* Edge */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
              
              &::placeholder {
                color: $TXT_PLACEHOLDER;
                font-size: $FONT_16;
              }
            }
          .PhoneInputInput{
            border: none;
          
          }
        }
      }
      }
      .textarea{
        #formBasicEmail{
          border: none;
          border-bottom: 1px solid $LIGHTBORDER;
          border-radius: 0;
          padding:0;
          &::-webkit-input-placeholder { /* Edge */
            color: $TXT_PLACEHOLDER;
            font-size: $FONT_16;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $TXT_PLACEHOLDER;
            font-size: $FONT_16;
          }
          
          &::placeholder {
            color: $TXT_PLACEHOLDER;
            font-size: $FONT_16;
          }
        }
      }
      .submit_Btn{
        width: 154px;
        position: relative;
        float: right;
        margin-top: 40px;
        .submitBtn{
          background: $SECONDARY !important;
          height: 48px;
          color: $TXT_WHITE !important;
          font-size: $FONT_16;
          font-family: $BARLOW_BOLD;
          text-align: left;
          padding-left: 16px;
        }
        i{
          position: absolute;
          right:24px;
          color:$TXT_WHITE;
          top:12px;
          font-weight: 600;
          font-size: $FONT_24;
        }
      }
     }
    }
  }
}

@media only screen and (min-device-width: 1116px) and (max-device-width: 1270px){
.service_Page{
  .banner_Text_Img{
    padding: 0 15px;
    justify-content: space-around;
    .inline_Img{
      flex-direction: column;
      .banner_Text{
        padding-left: 0;
        display: flex;
          flex-direction: column;
        .titleText{
          text-align: center;
        }
      }
    }

    img{
    &.img_Left{
      width: 300px;;
    }
    }
  }
}
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1115px) {
  .service_Page {
    .banner_Text_Img{
      padding: 0 15px;
      justify-content: space-around;
      .inline_Img{
        flex-direction: column;
        .banner_Text{
          padding-left: 0;
          display: flex;
          flex-direction: column;
          .titleText{
            text-align: center;
            &.main_Heading{
              font-size: $FONT_48;
              line-height: 72px;
            }
          }
        }
      }
  
      img{
      &.img_Left{
        width: 237px;;
      }
      }
   
    }
    .content_Img{
      padding: 74px 15px 0 15px;
      img{
        &.img_Right{
          width: 390px;
      }
      &.img_Left{
        width: 237px;;
      }
      }
    }
    .contact_Section{
      padding: 80px 16px;
      .card_Section{
        form{
          padding:40px 16px;
        }
      }
    }
   
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .service_Page{
    background-size: auto;
    height: 773px;
    .banner_Text_Img{
      padding: 0 15px;
      justify-content:space-between;
      .inline_Img{
        flex-direction: column;
        .banner_Text{
          padding-left: 0;
          display: flex;
            flex-direction: column;
            width: 406px;
          .titleText{
            text-align: center;
            &.main_Heading{
              font-size: $FONT_48;
              line-height: 72px;
            }
          }
        }
      }
  
      img{
        &.img_Right {
          width: 398px;
        }
      &.img_Left{
        width: 237px;
      }
      }
    }
    .content_Img{
      padding:50px 15px 0 15px;
      flex-direction: column;
      &.web_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      &.marketing_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      .width50{
        width: 100%;
        .skill_Section{
          padding-bottom: 20px;
        }
      }
    }
    .contact_Section{
      padding: 80px 16px;
      margin-top: 0;
      .content_Section{
        .titleText{
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right:52px;
          &:last-child{
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 534px;
          }
        }
    }
      .card_Section{
        form{
          padding:40px 16px;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 665px) and (max-device-width: 767px) {
  .service_Page{
    background-size: auto;
    height: 600px;
    padding: 0 15px;
    .banner_Text_Img{
      margin-bottom: 0;
      flex-direction: column;
      position: relative;
      .inline_Img{
        flex-direction: column;
        position: absolute;
        top:45%;
        .banner_Text{
          padding-left: 0;
          display: flex;
            flex-direction: column;
            width: 406px;
          .titleText{
            text-align: center;
            &.main_Heading{
              font-size: $FONT_48;
              line-height: 72px;
            }
          }
        }
      }
      img{
        &.img_Right {
          width: 125px;
        }
      &.img_Left{
        width: 168px;
      }
      }
    }
    .content_Img{
      padding:50px 15px 0 15px;
      flex-direction: column;
      &.web_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      &.marketing_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      .width50{
        width: 100%;
        .skill_Section{
          padding-bottom: 20px;
        }
      }
    }
    .contact_Section{
      padding: 80px 16px;
      .content_Section{
        .titleText{
          font-size: $FONT_32;
          font-family: $BARLOW_REGULAR;
          padding-right:60px;
          &:last-child{
            font-size: $FONT_14;
            font-family: $BARLOW_REGULAR;
            width: 370px;
          }
        }
    }
      .card_Section{
        form{
          padding: 80px 0;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 561px) and (max-device-width: 664px) {
  .service_Page{
    background-size: auto;
    height: 600px;
    padding: 0 15px;
    .banner_Text_Img{
      margin-bottom: 0;
      flex-direction: column;
      position: relative;
      .inline_Img{
        flex-direction: column;
        position: absolute;
        top:45%;
        .banner_Text{
          padding-left: 0;
          display: flex;
            flex-direction: column;
            width: 406px;
          .titleText{
            text-align: center;
            &.main_Heading{
              font-size: $FONT_48;
              line-height: 72px;
            }
          }
        }
      }
      img{
        &.img_Right {
          width: 125px;
        }
      &.img_Left{
        width: 168px;
      }
      }
    }
    .content_Img{
      padding:40px 15px 0 15px;
      flex-direction: column;
      &.web_Section{
        .image{
          padding-bottom: 50px;
        }
        .skill_Section{
          padding-bottom: 0 !important;
        }
      }
      &.marketing_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      .width50{
        width: 100%;
        .main_Title{
          font-size: $FONT_24;
        }
        .inner_Title{
          padding-bottom: 24px;
        }
        .skill_Section{
          padding-bottom: 20px;
         flex-direction: column;
         .small50{
           width: 100% !important;
         }
        }
      }
    }
    .contact_Section{
    padding: 40px 16px;
    margin-top: 0;
    .content_Section{
      .titleText{
        font-size: $FONT_32;
        font-family: $BARLOW_REGULAR;
        padding-right:45px;
        &:last-child{
          font-size: $FONT_14;
          font-family: $BARLOW_REGULAR;
          width: 300px;
        }
      }
  }
    .card_Section{
      form{
        padding: 40px 16px;
      }
    }
  }

  }
}

@media only screen and (min-device-width: 320px) and (max-device-width:560px) {
  .service_Page{
    background-size: auto;
    height: 812px;
    height: 600px;
    padding: 0 15px;
    .banner_Text_Img{
      margin-bottom: 0;
      flex-direction: column;
      position: relative;
      .inline_Img{
        flex-direction: column;
        position: absolute;
        top:45%;
        .banner_Text{
          padding-left: 0;
          display: flex;
            flex-direction: column;
            width: 327px;
          .titleText{
            text-align: center;
            &.main_Heading{
              font-size: $FONT_30;
              line-height: 45px;
            }
          }
        }
      }
      img{
        &.img_Right {
          width: 125px;
        }
      &.img_Left{
        width: 168px;
      }
      }
    }
    .content_Img{
      padding:40px 15px 0 15px;
      flex-direction: column;
      &.web_Section{
        .image{
          padding-bottom: 50px;
        }
        .skill_Section{
          padding-bottom: 0 !important;
        }
      }
      &.marketing_Section{
        .image{
          padding-bottom: 50px;
        }
      }
      .width50{
        width: 100%;
        .main_Title{
          font-size: $FONT_24;
        }
        .inner_Title{
          padding-bottom: 24px;
        }
        .skill_Section{
          padding-bottom: 20px;
          flex-direction: column;
          .small50{
            width: 100% !important;
          }
        }
      }
    }
      .contact_Section{
        padding: 40px 16px;
        margin-top: 0;
        .content_Section{
          padding-bottom: 20px;
          .titleText{
            font-size: $FONT_16;
            font-family: $BARLOW_REGULAR;
            padding-right: 0;
            width: 41%;
            &:last-child{
              font-size: $FONT_12;
              font-family: $BARLOW_REGULAR;
              width: 290px;
            }
          }
      }
        .card_Section{
          form{
            padding: 40px 16px;
            .input_Section{
              flex-direction: column;
              .input{
                width: 100%;
                padding-bottom: 23px;
              }
              .phone_Section{
                width: 100%;
                margin-bottom: 23px;
              }
            }
          }
        }
      }
    }
  }
