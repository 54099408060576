@import "../../assests/scss/variables.scss";

.textField {
	padding: 0;
	width: 100%;
	display: block;
	position: relative;
	margin-bottom: 24px;

	.inputBox {
		position: relative;

		.icon-css {
			color:blue;
			position: absolute;
			top: 22px;
			right: 15px;
			font-size: $FONT_24;
			background: transparent;
			font-style: inherit;
			font-family: icomoon !important;
			line-height: normal !important;

		}

		.errorMsg {
			display: block;
			font-size: $FONT_14;
			text-align: left;
			padding: 0;
			margin: 0;
			color: $TXT_WHITE;
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.3s ease-out;
			// font-family: $LATO_REGULAR;
		}

		.errorMsg.show {
			color: red;
			padding: 8px 0 0;
			max-height: 50px;
			transition: max-height 0.3s ease-in;
			display: flex;
			align-items: flex-start;

			p {
				margin: 0px;
				padding: 0px 0 0 0;
				font-size: $FONT_14;
				color: red;
				font-family: $POPPINS_MEDIUM;
			}


		}

	}

	.form-control {
		background: $WHITE;
		color: $TXT_LIGHT;
		font-size: $FONT_16;
		padding: 11px 12px;
		height: 60px;
		outline: 0px;
		width: 100%;
		border-radius: 10px;
		border: 1px solid $TXT_GREY;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;

		&::-webkit-input-placeholder {
			color: $TXT_PLACEHOLDER;
			font-family: $POPPINS_MEDIUM;

		}

		&::-moz-placeholder {
			color: $TXT_PLACEHOLDER;
			font-family: $POPPINS_MEDIUM;
		}

		&:-ms-input-placeholder {
			color: $TXT_PLACEHOLDER;
			font-family: $POPPINS_MEDIUM;
		}

		&:-moz-placeholder {
			color: $TXT_PLACEHOLDER;
			font-family: $POPPINS_MEDIUM;
		}

		&:focus {
			outline: none;
			box-shadow: none;
			border-color: $TXT_GREY;
		}

		&.error {
			border-color: red;
		}

		&:disabled {
			background:blue!important;
			border: 1px solid red;
			color:blue!important;
			opacity: 1 !important;
		}
	}

	.form-group {
		margin-bottom: 0px;
	}
}