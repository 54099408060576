@import "../../assests/scss/variables.scss";

.package_Seo_Container {
  background-image: url("../../assests/img/homebg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 964px;
  position: relative;

  .banner_Text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;

    .packages {
      font-size: $FONT_120;
      color: $TXT_WHITE;
      font-family: $BARLOW_BOLD;
    }

    .text {
      font-size: $FONT_24;
      font-family: $BARLOW_REGULAR;
      width: 46%;
      text-align: center;
      color: $TXT_WHITE;
    }
  }

  .icon-downs {
    color: $TXT_WHITE;
    position: absolute;
    bottom: 40px;
    font-size: $FONT_24;
  }

  img {
    &.size {
      width: 72px;
    }
  }

  .tab_container {
    margin: 80px 120px;

    .nav {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
    }

    .nav-tabs {
      border-bottom: 1px solid $DARKBORDER;
    }

    .tab_Main_Section {
      .nav-item {
        .nav-link {
          position: relative;
          padding: 0;
          line-height: 24px;
          padding-bottom: 12px;
          border: 0;
          color: $TXT_SECONDARY;
          font-family: $BARLOW_REGULAR;
          font-size: $FONT_16;

          &.active {
            color: red;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background: $PRIMARY;
              bottom: 0-1px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.seo_packageData {
  display: flex;
  margin-bottom: 150px;

  .silverData {
    margin-right: 24px;
    width: 33.33%;

    .seo_Header {
      background: $SILVER_BG;
    }
  }

  .goldData {
    margin-right: 24px;
    width: 33.33%;

    .seo_Header {
      background: $GOLD_BG;
    }
  }

  .platinumData {
    width: 33.33%;

    .seo_Header {
      background: $PLATINUM_BG;
    }
  }

  .seo_Section {
    background: $LIGHTWHITE;
    border: 1px solid $DIMBORDER;
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 40px;
    width: 100%;

    .seo_Header {
      border-radius: 20px 20px 0px 0px;

      .header {
        display: flex;
        justify-content: center;
        padding: 13px;
        font-size: $FONT_16;
        font-family: $BARLOW_SEMIBOLD;
        color: $TXT_SECONDARY;
      }
    }

    .seo_Row {
      margin-top: 40px;

      .seo_Row_data {
        padding: 0px 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .seo_Row_header {
          font-size: $FONT_16;
          font-family: $BARLOW_SEMIBOLD;
          color: $TXT_SECONDARY;
          text-align: center;
        }

        .seo_Row_text {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          color: $TXT_SECONDARY;
          text-align: center;
        }
      }

      .seo_border {
        border: 1px solid $BORDER;
        margin-top: 16px;
      }

      .smo_Row_data {
        margin-left: 140px;
        display: flex;
        align-items: baseline;

        .seo_Row_text {
          font-size: $FONT_16;
          font-family: $BARLOW_REGULAR;
          color: $TXT_SECONDARY;
          margin-left: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .package_Seo_Container {
    .banner_Text {
      .text {
        width: 69%;
      }
    }

    .tab_container {
      margin: 72px 88px;

      .seo_packageData {
        margin-bottom: 112px;

        .seo_Section {
          .seo_Row {
            .seo_Row_data {
              padding: 0px 56px;
            }

            .smo_Row_data {
              margin-left: 56px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .package_Seo_Container {
    .banner_Text {
      .text {
        width: 79%;
      }
    }

    .tab_container {
      margin: 72px 40px;

      .seo_packageData {
        margin-bottom: 112px;

        .silverData {
          margin-right: 16px;
        }

        .goldData {
          margin-right: 16px;
        }

        .seo_Section {
          .seo_Row {
            .seo_Row_data {
              padding: 0px 32px;
            }

            .smo_Row_data {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .package_Seo_Container {
    .banner_Text {
      .text {
        width: 81%;
      }
    }

    .tab_container {
      margin: 72px 24px;

      .seo_packageData {
        margin-bottom: 112px;

        .silverData {
          margin-right: 16px;
        }

        .goldData {
          margin-right: 16px;
        }

        .seo_Section {
          .seo_Row {
            .seo_Row_data {
              padding: 0px 32px;
            }

            .smo_Row_data {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .package_Seo_Container {
    .banner_Text {
      .packages {
        font-size: $FONT_48;
      }

      .text {
        width: 78%;
        font-size: $FONT_20;
      }
    }

    .tab_container {
      margin: 72px 24px;

      .tab_Main_Section {
        .nav-item {
          .nav-link {
            font-size: $FONT_14;
          }
        }
      }

      .seo_packageData {
        margin-bottom: 112px;

        .silverData {
          margin-right: 16px;
        }

        .goldData {
          margin-right: 16px;
        }

        .seo_Section {
          .seo_Row {
            margin-top: 32px;

            .seo_Row_data {
              padding: 0px 16px;

              .seo_Row_header {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }

              .seo_Row_text {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }
            }

            .smo_Row_data {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .package_Seo_Container {
    .banner_Text {
      .packages {
        font-size: $FONT_48;
      }

      .text {
        width: 78%;
        font-size: $FONT_20;
      }
    }

    .tab_container {
      margin: 72px 24px;

      .tab_Main_Section {
        .nav-item {
          .nav-link {
            font-size: $FONT_14;
          }
        }
      }

      .seo_packageData {
        margin-bottom: 112px;

        .silverData {
          margin-right: 16px;
        }

        .goldData {
          margin-right: 16px;
        }

        .seo_Section {
          .seo_Row {
            margin-top: 32px;

            .seo_Row_data {
              padding: 0px 16px;

              .seo_Row_header {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }

              .seo_Row_text {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }
            }

            .smo_Row_data {
              margin-left: 8px;

              .seo_Row_text {
                font-size: $FONT_14;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .package_Seo_Container {
    .banner_Text {
      .packages {
        font-size: $FONT_48;
      }

      .text {
        width: 80%;
        font-size: $FONT_20;
      }
    }

    .tab_container {
      margin: 16px 8px;

      .tab_Main_Section {
        .nav-item {
          .nav-link {
            font-size: $FONT_14;
          }
        }
      }

      .seo_packageData {
        margin-bottom: 112px;

        .silverData {
          margin-right: 16px;
        }

        .goldData {
          margin-right: 16px;
        }

        .seo_Section {
          .seo_Row {
            margin-top: 32px;

            .seo_Row_data {
              padding: 0px 16px;

              .seo_Row_header {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }

              .seo_Row_text {
                font-size: $FONT_14;
                padding-bottom: 16px;
              }
            }

            .smo_Row_data {
              margin-left: 8px;

              .seo_Row_text {
                font-size: $FONT_14;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .package_Seo_Container {
    .banner_Text {
      .packages {
        font-size: $FONT_30;
      }

      .text {
        width: 77%;
        font-size: $FONT_14;
      }
    }

    .tab_container {
      margin: 8px 8px;

      .tab_Main_Section {
        .nav-item {
          .nav-link {
            font-size: $FONT_14;
          }
        }
      }

      .seo_packageData {
        margin-bottom: 112px;
        display: block;

        .silverData {
          width: 100%;
        }

        .goldData {
          margin-top: 64px;
          width: 100%;
        }

        .platinumData {
          margin-top: 64px;
          width: 100%;
        }

        .seo_Section {
          .seo_Row {
            margin-top: 32px;

            .seo_Row_data {
              padding: 0px 72px;

              .seo_Row_header {
                font-size: $FONT_16;
                padding-bottom: 16px;
              }

              .seo_Row_text {
                font-size: $FONT_16;
                padding-bottom: 16px;
              }
            }

            .smo_Row_data {
              margin-left: 96px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .package_Seo_Container {
    .banner_Text {
      .packages {
        font-size: $FONT_30;
      }

      .text {
        width: 77%;
        font-size: $FONT_14;
      }
    }

    .tab_container {
      margin: 8px 8px;

      .tab_Main_Section {
        display: inline-block;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        width: 100%;

        .nav-item {
          width: 70px;
          display: inline-block;

          &:nth-child(3){
            width: 200px;
          }
          &:nth-child(4){
            width: 130px;
          }
          .nav-link {
            font-size: $FONT_14;
          }
        }
      }

      .seo_packageData {
        margin-bottom: 112px;
        display: block;

        .silverData {
          width: 100%;
        }

        .goldData {
          margin-top: 64px;
          width: 100%;
        }

        .platinumData {
          margin-top: 64px;
          width: 100%;
        }

        .seo_Section {
          .seo_Row {
            margin-top: 32px;

            .seo_Row_data {
              padding: 0px 40px;

              .seo_Row_header {
                font-size: $FONT_16;
                padding-bottom: 16px;
              }

              .seo_Row_text {
                font-size: $FONT_16;
                padding-bottom: 16px;
              }
            }

            .smo_Row_data {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}